import {
  IconChevronDown,
  IconCirclePlus,
  IconDotsVertical,
  IconMail,
  IconPhone,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddAgent from "../../../components/SuperAdmin/AddAgent";
import ViewAgent from "../../../components/SuperAdmin/ViewAgent";
import { fetchAgent, fetchAllAgent } from "../actions";

const Subscribers = () => {
  const {
    agents,
    allAgents,
    agentsCurrentPage,
    agentsTotalPages,
    loading,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const sortedAgents = [...allAgents].sort(
    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  );

  const filteredAgents = sortedAgents.filter((agent) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      agent?.displayName?.toLowerCase().includes(searchLower) || // Name
      agent?.businessPhone?.toLowerCase().includes(searchLower) || // Phone
      agent?.businessEmail?.toLowerCase().includes(searchLower) || // Email
      agent?.role?.toLowerCase().includes(searchLower) || // Type/Role
      agent?.mls?.toLowerCase().includes(searchLower) // MLS
    );
  });
  const totalAgents = filteredAgents?.length || 0;
  const agentPerPage = 10;
  const totalPages = Math.ceil(totalAgents / agentPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedAgents = filteredAgents?.slice(
    (currentPage - 1) * agentPerPage,
    currentPage * agentPerPage
  );

  const handlePageChangeAgents = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // dispatch(fetchAgent());
    dispatch(fetchAllAgent());
  }, [dispatch]);

  console.log(agents);
  const agent = [
    {
      id: 1,
      firstName: "John",
      lastName: "Doe",
      phone: "1234567890",
      email: "john@gmail.com",
      type: "Solo",
      mls: "999999999",
    },
    {
      id: 2,
      firstName: "Jane",
      lastName: "Doe",
      phone: "1234567890",
      email: "inupa@gmail.com",
      type: "Solo",
      mls: "999999999",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  const toggleDropdown = (id) => {
    // Toggle dropdown open/close based on the agent ID
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const openVIewAgentsModal = async (agent) => {
    setIsViewModalOpen(true);
    setSelectedAgent(agent);
  };

  const closeAgentViewModal = () => {
    // setSelectedAgent(null);
    setIsViewModalOpen(false);
  };

  const openAgentsAddModal = () => {
    setIsModalOpen(true);
  };

  const closeAgentAddModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= agentsTotalPages) {
      // setCurrentPage(newPage);  // Update the current page state
      // if (activeTab === "All") {
      //   dispatch(fetchListings(newPage)); // Fetch listings for the new page
      // }
      dispatch(fetchAgent(newPage)); // Fetch listings for the new page based on status
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (agentsTotalPages <= 5) {
      // If there are 5 or fewer pages, show all page numbers
      for (let i = 1; i <= agentsTotalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`pagination-number ${
              agentsCurrentPage === i ? "active" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show first, last, and up to 2 pages around the current page
      if (agentsCurrentPage > 2) {
        pageNumbers.push(
          <button
            key={1}
            className={`pagination-number`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
      }

      if (agentsCurrentPage > 3) {
        pageNumbers.push(<span key="dots1">...</span>);
      }

      const startPage = Math.max(2, agentsCurrentPage - 1);
      const endPage = Math.min(agentsTotalPages - 1, agentsCurrentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`pagination-number ${
              agentsCurrentPage === i ? "active" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (agentsCurrentPage < agentsTotalPages - 2) {
        pageNumbers.push(<span key="dots2">...</span>);
      }

      if (agentsCurrentPage < agentsTotalPages - 1) {
        pageNumbers.push(
          <button
            key={agentsTotalPages}
            className={`pagination-number`}
            onClick={() => handlePageChange(agentsTotalPages)}
          >
            {agentsTotalPages}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  // Real-time filtering based on searchTerm

  return (
    <div className="mb-3">
      <AddAgent isOpen={isModalOpen} closeModel={closeAgentAddModal} />
      <ViewAgent
        isOpen={isViewModalOpen}
        closeModel={closeAgentViewModal}
        agent={selectedAgent}
      />
      <div className="page-wrapper">
        {/* <div className="page-header m-3"> */}
        {/* <div className="row align-items-center gap-2"> */}
        {/* <div className="col-auto bg-white rounded-4 border"> */}
        {/* <div className="border-0"> */}
        {/* {tabs.map((tab) => (
              <button
                key={tab}
                className={`my-1 text-capitalize ${
                  activeTab === tab
                    ? "btn btn-outline-primary btn-pill bg-blue-lt"
                    : "bg-white border-0 mx-2"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))} */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="col-auto ms-auto"> */}
        {/* <div className="btn-list"> */}
        {/* <button
                  className="btn btn-pill btn-primary"
                  onClick={openAgentsAddModal}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Add New Agent</div>
                    <IconCirclePlus size={18} />
                  </div>
                </button> */}
        {/* <button className="btn btn-outline-primary btn-pill">
              <div className="d-flex align-items-center gap-2">
                <div className="ms-2">Import</div>
                <IconChevronDown size={18} />
              </div>
            </button> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto rounded-4">
              {/* <div className="btn-list">
                <button className="btn btn-outline-primary btn-pill">
                  <div className="d-flex align-items-center gap-2">
                    <div className="">Select</div>
                  </div>
                </button>
                <button className="btn btn-outline-primary btn-pill">
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Sort</div>
                    <IconChevronDown size={18} />
                  </div>
                </button>
              </div> */}
            </div>
            <div className="col-auto ms-auto">
              <div className="input-icon">
                <span className="input-icon-addon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control form-control-rounded border-0 bg-gray-600"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update searchTerm state on input change
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="table-responsive page-header mx-3 bg-white border"
          style={{ borderRadius: "12px" }}
        >
          {/* Display loading spinner or empty message */}
          {loading ? (
            <div className="d-flex justify-content-center align-items-center py-5">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (searchTerm ? filteredAgents : paginatedAgents).length === 0 ? (
            <div className="d-flex justify-content-center align-items-center py-5">
              <p className="text-muted">No agents found.</p>
            </div>
          ) : (
            <>
              {/* Table Content */}
              <table
                className="pb-3 table-vcenter table-nowrap overflow-auto"
                style={{ borderRadius: "12px", overflow: "auto" }}
              >
                <thead className="mb-2 p-2">
                  <tr>
                    <th className="p-3">Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Type</th>
                    <th>MLS Number</th>
                    <th className="p-3">Action</th>
                  </tr>
                </thead>
                <tbody
                  className="table-content-body bg-white"
                  style={{ borderRadius: "0 0 12px 12px", overflow: "hidden" }}
                >
                  {(searchTerm ? filteredAgents : paginatedAgents).map(
                    (agent, index) => (
                      <tr key={index} className="mb-2">
                        <td
                          className="p-3 cursor-pointer"
                          onClick={() => openVIewAgentsModal(agent)}
                        >
                          <div className="d-flex align-items-center">
                            <div className="avatar bg-gray-300 rounded-4 me-3">
                              <span className="fs-4 fw-bold text-dark">
                                {agent?.displayName?.charAt(0).toUpperCase() ||
                                  "N/A"}
                              </span>
                            </div>
                            <div>
                              <div className="fw-semibold">
                                {agent?.displayName || "N/A"}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="p-2 cursor-pointer">
                          <div className="d-flex align-items-center">
                            <IconPhone size={18} className="me-2" />
                            {agent?.businessPhone || "N/A"}
                          </div>
                        </td>
                        <td className="p-2 cursor-pointer">
                          <div className="d-flex align-items-center">
                            <IconMail size={18} className="me-2" />
                            {agent?.businessEmail}
                          </div>
                        </td>
                        <td>{agent?.role || "N/A"}</td>
                        <td>{agent?.mls || "N/A"}</td>
                        <td className="p-2 position-relative">
                          <button
                            className="btn btn-ghost btn-circle border-0"
                            onClick={() => toggleDropdown(agent.id)}
                          >
                            <IconDotsVertical size={18} />
                          </button>
                          {openDropdownId === agent.id && (
                            <div
                              className="position-absolute bg-white shadow-sm rounded-3 border"
                              style={{
                                top: "0",
                                right: "20px", // Align with button
                                zIndex: 10,
                              }}
                            >
                              <span className="p-2 d-block text-black border-bottom">
                                Edit
                              </span>
                              <span className="p-2 d-block text-black border-bottom">
                                Duplicate
                              </span>
                              <span className="p-2 d-block text-black">
                                Delete
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              {/* Pagination */}
              <div className="d-flex align-item-center justify-content-center mt-4">
                <nav>
                  <ul className="pagination gap-2">
                    <button
                      className="pagination-arrow text-primary me-2"
                      disabled={currentPage === 1}
                      onClick={() => handlePageChangeAgents(currentPage - 1)}
                    >
                      Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                      (page) => (
                        <li
                          key={page}
                          className={`page-item rounded-5 me-1 ${
                            currentPage === page ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link rounded-5"
                            onClick={() => handlePageChangeAgents(page)}
                          >
                            {page}
                          </button>
                        </li>
                      )
                    )}
                    <button
                      className="pagination-arrow text-primary"
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChangeAgents(currentPage + 1)}
                    >
                      Next
                    </button>
                  </ul>
                </nav>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscribers;
