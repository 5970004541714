import { IconCalendar } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  eachDayOfInterval,
  format,
  subWeeks,
} from "date-fns";
import { fetchAgent, fetchAllAgent } from "../actions";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const groupListingsByDate = (agents, startDate, endDate) => {
  // Generate all dates between startDate and endDate
  const allDates = eachDayOfInterval({ start: startDate, end: endDate }).map(
    (date) => date.toLocaleDateString()
  );

  // Create an object to store the count of agents for each date
  const agentsCountByDate = allDates.reduce((acc, date) => {
    acc[date] = 0; // Initialize every date with a count of 0
    return acc;
  }, {});

  // Filter agents within the date range and count them by date
  agents.forEach((listing) => {
    if (!listing.createdOn) return;
    const createdOn = new Date(listing.createdOn).toLocaleDateString();
    if (agentsCountByDate[createdOn] !== undefined) {
      agentsCountByDate[createdOn] += 1;
    }
  });

  // Return the dates and counts for the chart
  const dates = Object.keys(agentsCountByDate);
  const counts = Object.values(agentsCountByDate);

  return { dates, counts };
};

// Chart options for responsiveness and layout
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false, // Allow the chart to adjust its height based on its container
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const SuperAdmin = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(subWeeks(new Date(), 2)); // 2 weeks ago
  const [endDate, setEndDate] = useState(new Date()); // Today
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [soloCount, setSoloCount] = useState(false);
  const [brokerageCount, setBrokerageCount] = useState(false);
  const { allAgents, agentsTotal } = useSelector(
    (state) => state.dashboard
  );
  const [trafficData, setTrafficData] = useState({
    labels: [],
    datasets: [
      {
        label: "User Traffic",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        fill: true,
      },
    ],
  });

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  console.log(allAgents);

  useEffect(() => {
    // Update the chart data when listings, startDate, or endDate change
    if (allAgents.length > 0) {
      const { dates, counts } = groupListingsByDate(
        allAgents,
        startDate,
        endDate
      );

      // Update the trafficData state for the line chart
      setTrafficData({
        labels: dates,
        datasets: [
          {
            label: "User Traffic",
            data: counts,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });
    }

    const solo = allAgents.filter((agent) => agent.userType === "solo");
    const solo_count = solo.length;
    setSoloCount(solo_count);

    const brokerage = allAgents.filter(
      (agent) => agent.userType === "brokerage"
    );
    const brokerage_count = brokerage.length;
    setBrokerageCount(brokerage_count);
  }, [allAgents, startDate, endDate]);

  useEffect(() => {
    dispatch(fetchAllAgent());
    dispatch(fetchAgent());
  }, [dispatch]);
  return (
    <div className="container">
      {/* Date Range Picker */}
      <div
        className="date-picker-container mb-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {/* Custom date picker toggle button */}
        <div
          className="date-picker-toggle"
          onClick={() => setShowDatePicker(!showDatePicker)} // Toggle visibility on click
          style={{
            display: "inline-flex",
            alignItems: "center",
            border: "1px solid #0057FF",
            padding: "8px 15px",
            borderRadius: "30px",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          <IconCalendar size={20} style={{ marginRight: "10px" }} />
          <span className="text-black">
            {startDate
              ? format(startDate, "dd, MMM yyyy")
              : "Select start date"}{" "}
            - {endDate ? format(endDate, "dd, MMM yyyy") : "Select end date"}
          </span>
        </div>

        {/* Conditionally render DatePicker */}
        {showDatePicker && (
          <div
            style={{ position: "absolute", zIndex: 1000, marginTop: "40px" }}
          >
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
        )}
      </div>
      {/* Traffic Chart */}
      <div
        className="card rounded-4 mb-4 p-4 shadow-sm"
        style={{ height: "400px" }}
      >
        <h4 className="text-center mb-4">User Traffic</h4>
        <div style={{ height: "100%" }}>
          <Line data={trafficData} options={chartOptions} />
        </div>
      </div>

      {/* Summary Stats */}
      <div className="row mb-4">
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">Total Users</h4>
              <h1 className="card-title-analytics h1">
                <strong>{agentsTotal}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                <IconTrendingUp className="me-1" />
                8.5%
              </span> */}
                Total Users up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">
                Total Solo users
              </h4>
              <h1 className="card-title-analytics h1">
                <strong>{soloCount}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                <IconTrendingUp className="me-1" />
                8.5%
              </span> */}
                Solo users up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">
                Total Brokerage users
              </h4>
              <h1 className="card-title-analytics h1">
                <strong>{brokerageCount}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                <IconTrendingUp className="me-1" />
                8.5%
              </span> */}
                Brokerage users up to date
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
