import {
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILURE,
  FETCH_LISTINGS_REQUEST,
  FETCH_LISTINGS_SUCCESS,
  FETCH_LISTINGS_FAILURE,
  FETCH_ALL_LISTINGS_REQUEST,
  FETCH_ALL_LISTINGS_SUCCESS,
  FETCH_ALL_LISTINGS_FAILURE,
  FETCH_LISTING_BY_ID_REQUEST,
  FETCH_LISTING_BY_ID_SUCCESS,
  FETCH_LISTING_BY_ID_FAILURE,
  FETCH_LISTING_BY_STATUS_REQUEST,
  FETCH_LISTING_BY_STATUS_SUCCESS,
  FETCH_LISTING_BY_STATUS_FAILURE,
  CREATE_LISTING_REQUEST,
  CREATE_LISTING_SUCCESS,
  CREATE_LISTING_FAILURE,
  UPDATE_LISTING_REQUEST,
  UPDATE_LISTING_SUCCESS,
  UPDATE_LISTING_FAILURE,
  DELETE_LISTING_REQUEST,
  DELETE_LISTING_SUCCESS,
  DELETE_LISTING_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  ADD_LEADS_REQUEST,
  ADD_LEADS_SUCCESS,
  ADD_LEADS_FAILURE,
  FETCH_LEADS_REQUEST,
  FETCH_LEADS_SUCCESS,
  FETCH_LEADS_FAILURE,
  FETCH_LEAD_BY_ID_FAILURE,
  FETCH_LEAD_BY_ID_REQUEST,
  FETCH_LEAD_BY_ID_SUCCESS,
  FETCH_ALL_LEADS_FAILURE,
  FETCH_ALL_LEADS_SUCCESS,
  FETCH_ALL_LEADS_REQUEST,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILURE,
  DELETE_LEAD_REQUEST,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILURE,
  CREATE_NEWS_REQUEST,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAILURE,
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_FAILURE,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAILURE,
  DELETE_NEWS_FAILURE,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  FETCH_AGENT_FAILURE,
  FETCH_AGENT_REQUEST,
  FETCH_AGENT_SUCCESS,
  FETCH_SHOWCASE_FAILURE,
  FETCH_SHOWCASE_REQUEST,
  FETCH_SHOWCASE_SUCCESS,
  DELETE_SHOWCASE_FAILURE,
  DELETE_SHOWCASE_REQUEST,
  DELETE_SHOWCASE_SUCCESS,
  FETCH_IDX_PROPERTIES_FAILURE,
  FETCH_IDX_PROPERTIES_REQUEST,
  FETCH_IDX_PROPERTIES_SUCCESS,
  FETCH_ALL_AGENT_FAILURE,
  FETCH_ALL_AGENT_SUCCESS,
  FETCH_ALL_AGENT_REQUEST,
  FETCH_ALL_SHOWCASE_FAILURE,
  FETCH_ALL_SHOWCASE_REQUEST,
  FETCH_ALL_SHOWCASE_SUCCESS,
  FETCH__ALL_NEWS_FAILURE,
  FETCH__ALL_NEWS_REQUEST,
  FETCH__ALL_NEWS_SUCCESS,
  FETCH_VOW_PROPERTIES_REQUEST,
  FETCH_VOW_PROPERTIES_FAILURE,
  FETCH_VOW_PROPERTIES_SUCCESS,
} from "../../config/actionTypes";

const initialState = {
  allNews: [],
  news: [],
  newsCurrentPage: 1,
  newsTotalPages: 1,
  newsTotal: null,

  leads: [],
  allLeads: [],
  lead: null,
  leadsCurrentPage: 1,
  leadsTotalPages: 1,
  leadsTotal: null,

  MLS: [],
  MLSloading: false,

  agents: [],
  allAgents: [],
  agentsCurrentPage: 1,
  agentsTotalPages: 1,
  agentsTotal: null,

  showcases: [],
  allShowcase: [],
  showcaseCurrentPage: 1,
  showcaseTotalPages: 1,
  showcaseTotal: null,

  listings: [],
  allListings: [],
  uploadedImages: [],
  listingsByStatus: [],
  error: null,
  listing: null,
  fetchedListing: {},
  loading: false,
  currentPage: 1,
  totalPages: 1,
  total: null,
};

// reducers/authReducer.js
const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case ONBOARDING_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: action.payload.rows,
        loading: false,
        currentPage: action.payload.page,
        totalPages: action.payload.totalPages,
        total: action.payload.total,
      };
    case FETCH_LISTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        totalPages: action.payload.totalPages,
      };
    case FETCH_ALL_LISTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ALL_LISTINGS_SUCCESS:
      return {
        ...state,
        allListings: action.payload,
        loading: false,
      };
    case FETCH_ALL_LISTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_LISTING_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LISTING_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedListing: action.payload,
      };
    case FETCH_LISTING_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_LISTING_BY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_LISTING_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        listings: action.payload.rows,
        currentPage: action.payload.page,
        totalPages: action.payload.totalPages,
      };
    case FETCH_LISTING_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        listing: action.payload,
      };
    case CREATE_LISTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        listing: action.payload,
        error: null,
      };
    case UPDATE_LISTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case DELETE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        listings: state.listings.filter(
          (listing) => listing.id !== action.payload
        ),
      };

    case DELETE_LISTING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadedImages: [...state.uploadedImages, action.payload],
      };
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_LEADS_REQUEST:
      return { ...state, loading: true };
    case ADD_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.payload,
      };
    case ADD_LEADS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_LEADS_REQUEST:
      return { ...state, loading: true };
    case FETCH_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: action.payload.rows,
        leadsCurrentPage: action.payload.page,
        leadsTotalPages: action.payload.totalPages,
        leadsTotal: action.payload.total,
      };
    case FETCH_LEADS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_LEAD_BY_ID_REQUEST:
      return { ...state, loading: true };
    case FETCH_LEAD_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.payload,
      };
    case FETCH_LEAD_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_LEAD_REQUEST:
      return { ...state, loading: true };
    case UPDATE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        lead: action.payload,
      };
    case UPDATE_LEAD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_LEAD_REQUEST:
      return { ...state, loading: true };
    case DELETE_LEAD_SUCCESS:
      return {
        ...state,
        loading: false,
        leads: state.leads.filter((lead) => lead.id !== action.payload),
      };
    case DELETE_LEAD_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CREATE_NEWS_REQUEST:
      return { ...state, loading: true };
    case CREATE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_NEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_NEWS_REQUEST:
      return { ...state, loading: true };
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload.rows,
        newsCurrentPage: action.payload.page,
        newsTotalPages: action.payload.totalPages,
        newsTotal: action.payload.total,
      };
    case FETCH_NEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_NEWS_REQUEST:
      return { ...state, loading: true };
    case UPDATE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_NEWS_REQUEST:
      return { ...state, loading: true };
    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: state.news.filter((news) => news.id !== action.payload),
      };
    case DELETE_NEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_AGENT_REQUEST:
      return { ...state, loading: true };
    case FETCH_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        agents: action.payload.rows,
        agentsCurrentPage: action.payload.page,
        agentsTotalPages: action.payload.totalPages,
        agentsTotal: action.payload.total,
      };
    case FETCH_AGENT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_SHOWCASE_REQUEST:
      return { ...state, loading: true };
    case FETCH_SHOWCASE_SUCCESS:
      return {
        ...state,
        loading: false,
        showcases: action.payload.rows,
        showcaseCurrentPage: action.payload.page,
        showcaseTotalPages: action.payload.totalPages,
        showcaseTotal: action.payload.total,
      };
    case FETCH_SHOWCASE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_SHOWCASE_REQUEST:
      return { ...state, loading: true };
    case DELETE_SHOWCASE_SUCCESS:
      return {
        ...state,
        loading: false,
        showcases: state.showcases.filter(
          (showcase) => showcase.id !== action.payload
        ),
      };
    case DELETE_SHOWCASE_FAILURE:
      return { ...state, MLSloading: false, error: action.payload };
    case FETCH_IDX_PROPERTIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_IDX_PROPERTIES_SUCCESS:
      return {
        ...state,
        MLSloading: false,
        MLS: action.payload.value,
      };
    case FETCH_IDX_PROPERTIES_FAILURE:
      return { ...state, MLSloading: false, error: action.payload };
    case FETCH_VOW_PROPERTIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_VOW_PROPERTIES_SUCCESS:
      return {
        ...state,
        MLSloading: false,
        MLS: action.payload.value,
      };
    case FETCH_VOW_PROPERTIES_FAILURE:
      return { ...state, MLSloading: false, error: action.payload };

    case FETCH_ALL_AGENT_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_AGENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allAgents: action.payload,
      };
    case FETCH_ALL_AGENT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_ALL_LEADS_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_LEADS_SUCCESS:
      return {
        ...state,
        loading: false,
        allLeads: action.payload,
      };
    case FETCH_ALL_LEADS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_ALL_SHOWCASE_REQUEST:
      return { ...state, loading: true };
    case FETCH_ALL_SHOWCASE_SUCCESS:
      return {
        ...state,
        loading: false,
        allShowcase: action.payload,
      };
    case FETCH_ALL_SHOWCASE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH__ALL_NEWS_REQUEST:
      return { ...state, loading: true };
    case FETCH__ALL_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        allNews: action.payload,
      };
    case FETCH__ALL_NEWS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default dashboardReducer;
