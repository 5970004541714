// americaCanadaTimezones.js

export const americaCanadaTimezones = [
    // Canada Timezones
    "Canada/Newfoundland",
    "Canada/Atlantic",
    "Canada/Eastern",
    "Canada/Central",
    "Canada/Mountain",
    "Canada/Pacific",
    
    // US Timezones
    "America/New_York",      // Eastern Time (ET)
    "America/Chicago",       // Central Time (CT)
    "America/Denver",        // Mountain Time (MT)
    "America/Los_Angeles",   // Pacific Time (PT)
    "America/Anchorage",     // Alaska Time (AKT)
    "America/Phoenix",       // Arizona (Mountain Time, no DST)
    "America/Detroit",       // Eastern Time (ET)
    "America/Indiana/Indianapolis", // Eastern Time (ET)
    
    // US & Canada Territories
    "America/Halifax",       // Atlantic Time (AT)
    "America/Regina",        // Central Time (Saskatchewan, no DST)
    "America/St_Johns",      // Newfoundland Time (NT)
    
    // Other regions in the Americas
    "America/Adak",          // Hawaii-Aleutian Time (HAT)
    "America/Puerto_Rico",   // Atlantic Standard Time (AST, no DST)
    "America/Tijuana",       // Pacific Time (Baja California)
    "America/Vancouver",     // Pacific Time (British Columbia)
    "America/Winnipeg",      // Central Time (Manitoba)
    "America/Moncton",       // Atlantic Time (New Brunswick)
    "America/Toronto",       // Eastern Time (Ontario)
    "America/Edmonton",      // Mountain Time (Alberta)
    "America/Whitehorse",    // Pacific Time (Yukon)
    "America/Yellowknife",   // Mountain Time (Northwest Territories)
    "America/Iqaluit",       // Eastern Time (Nunavut)
    "America/Cancun",        // Eastern Time (Quintana Roo, Mexico)
    "America/Mexico_City",   // Central Time (Mexico)
    "America/Guatemala",     // Central Time (Guatemala)
    "America/Montevideo",    // Uruguay Time (UYT)
    "America/Sao_Paulo",     // Brazil Time (BRT)
    "America/Argentina/Buenos_Aires", // Argentina Time (ART)
];
  
  
  