import { IconExclamationCircle, IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { updateProfile } from "../../modules/auth/actions";
const EditAgentProfile = ({ isOpen, closeModel, profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: profile.name || "",
    displayName: profile.displayName || "",
    email: profile.email || "",
    businessEmail: profile.businessEmail || "",
    mlsNumber: profile.mlsNumber || "",
    phone: profile.phone || "",
    businessPhone: profile.businessPhone || "",
    links: profile.links || [],
  });

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    const phoneKey =
      profile.userType === "brokerage" ? "phone" : "businessPhone";
    setFormData({ ...formData, [phoneKey]: value });
  };

  const handleAddLink = () => {
    setFormData((prevState) => ({
      ...prevState,
      links: [...prevState.links, ""], // Add an empty string for the new link
    }));
  };

  const handleRemoveLink = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      links: prevState.links.filter((_, i) => i !== index),
    }));
  };

  const handleLinkChange = (index, value) => {
    setFormData((prevState) => {
      const updatedLinks = [...prevState.links];
      updatedLinks[index] = value; // Modify the specific link
      return { ...prevState, links: updatedLinks };
    });
  };

  const handleProfileUpdate = async () => {
    const isProfileChanged = Object.keys(formData).some(
      (key) => formData[key] !== profile[key]
    );

    // If profile hasn't changed, don't proceed with the update
    if (!isProfileChanged) {
      toast.info("No changes detected in profile", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    setIsSubmitting(true);
    try {
      // Call API here with the updated formData
      const response = await dispatch(updateProfile(profile.id, formData));
      console.log("Profile Update Response: ", response);

      if (response.id) {
        toast.success("Profile updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      console.log("Profile Update Response: ", response);
      console.log("Profile Updated with Data: ", formData);

      // After successful update, you can switch back to view mode if needed
      //   setEditMode(false);
      setIsSubmitting(false);
      closeModel();
    } catch (error) {
      console.error("Error updating profile: ", error);
      // toast.error("Failed to update profile", {
      //   position: "bottom-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   transition: Bounce,
      // });
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      {" "}
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close mt-2 me-2"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              <div className="d-flex align-items-center gap-3 mb-2">
                <span
                  className="avatar avatar-xl bg-gray rounded-circle"
                  style={{
                    backgroundImage: `url(${
                      profile.profileUrl || "https://via.placeholder.com/150"
                    })`,
                    position: "relative",
                    width: "120px",
                    height: "120px",
                    backgroundSize: "cover",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <div className="ml-3">
                  {/* Agent Name */}
                  <h3 className="mb-0">
                    {profile.displayName ? profile.displayName : profile.name}
                  </h3>
                </div>
              </div>
              <h4 className="text-primary">Basic Information</h4>
              {profile.userType === "brokerage" ? (
                <div className="form-group mb-3 w-100">
                  <label>
                    Name{" "}
                    <IconExclamationCircle className="text-muted" size={15} />
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-rounded"
                    name="name"
                    defaultValue={profile.name}
                    //   readOnly={!editMode}
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                <div className="form-group mb-3 w-100">
                  <label>
                    Name{" "}
                    <IconExclamationCircle className="text-muted" size={15} />
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-rounded"
                    name="name"
                    defaultValue={profile.displayName}
                    //   readOnly={!editMode}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {profile.userType === "brokerage" ? (
                <div className="form-group mb-3 w-100">
                  <label>
                    Email{" "}
                    <IconExclamationCircle className="text-muted" size={15} />
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-rounded"
                    name="email"
                    defaultValue={profile.email}
                    //   readOnly={!editMode}
                    onChange={handleInputChange}
                  />
                </div>
              ) : (
                <div className="form-group mb-3 w-100">
                  <label>
                    Email{" "}
                    <IconExclamationCircle className="text-muted" size={15} />
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-rounded"
                    name="businessEmail"
                    defaultValue={profile.businessEmail}
                    //   readOnly={!editMode}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              <div className="form-group mb-3 w-100">
                <label>
                  MLS <IconExclamationCircle className="text-muted" size={15} />
                </label>
                <input
                  type="text"
                  className="form-control form-control-rounded"
                  name="mlsNumber"
                  defaultValue={profile.mlsNumber}
                  //   readOnly={!editMode}
                  onChange={handleInputChange}
                />
              </div>
              {profile.userType === "brokerage" ? (
                <div className={`form-group mb-3 w-100 phone-input`}>
                  <label>Phone Number</label>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={profile.phone}
                    onChange={handlePhoneChange}
                  />
                </div>
              ) : (
                <div className={`form-group mb-3 w-100 phone-input`}>
                  <label>Phone Number</label>
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={profile.businessPhone}
                    onChange={handlePhoneChange}
                  />
                </div>
              )}
              <div>
                <h4 className="text-primary">Social Links</h4>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-pill mt-2"
                  onClick={handleAddLink}
                >
                  Add Link
                </button>
                {formData.links.length > 0 ? (
                  formData.links.map((link, index) => (
                    <div key={index} className="d-flex gap-2 mb-2">
                      <input
                        type="text"
                        value={link}
                        className="form-control form-control-rounded"
                        onChange={(e) =>
                          handleLinkChange(index, e.target.value)
                        }
                      />
                      <button
                        type="button"
                        className="btn p-0 border-0 bg-transparent"
                        onClick={() => handleRemoveLink(index)}
                      >
                        <IconX size={24} className="text-danger" />
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-center mt-2">No social links added yet</p>
                )}
              </div>

              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-primary btn-pill"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={handleProfileUpdate}
                >
                  {isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAgentProfile;
