import React from 'react';
import listingImage from "../../../assets/images/login-cover.png"; // Placeholder for default image
import '../../../assets/styles/smallListingCard.scss'; // Adding custom CSS file for styling
import { IconPlus } from '@tabler/icons-react';

const SmallListingCard = ({ cards, onClick,addCardIdToArray }) => {
  return (
    <div className="row gap-1">
      {cards.map((card, index) => (
        <div
          key={index}
          className="custom-card shadow-sm col-12 col-sm-6 col-md-4 col-lg-3 mx-auto m-1"  // Responsive column classes
         // Event handling for clicking the card
        >
          {/* Image Section */}
          <div className="image-section cursor-pointer">
            <img
              src={card.images && card.images[0] && card.images[0].imageUrl ? card.images[0].imageUrl : listingImage}
              alt={card.title}
              className="property-image"
            />
          </div>

          {/* Details Section */}
          <div className="details-section">
            <div className="price-section">
              <div className="price-text">{card.currency} {card.price.toLocaleString()}</div>
            </div>

            <div className="title-section">
              {card.title}
              <div onClick={() => addCardIdToArray(card.id)}><IconPlus size={20} className='mb-1 cursor-pointer' /></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SmallListingCard;
