import {
  IconChevronLeft,
  IconChevronRight,
  IconCirclePlus,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AddAgent = ({ isOpen, closeModel }) => {
  const [loading, setLoading] = useState(false);
  const [intialModalData, setIntialModalData] = useState(isOpen);
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    userType: "",
    mlsNumber: "",
    displayName: "",
    businessEmail: "",
    businessPhone: "",
    businessAddress: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    links: [{ value: "" }],
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      setIsFirstModalOpen(true); // Open the first modal when `isOpen` is true
    } else {
      setIsFirstModalOpen(false);
      setIsSecondModalOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    // Control the body scroll based on any modal visibility.
    // setIsFirstModalOpen(false);
    // setIsFirstModalOpen(isOpen);
    if (isFirstModalOpen || isSecondModalOpen) {
      document.body.classList.add("modal-open");
      
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isFirstModalOpen, isSecondModalOpen]);

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
    if (error) setError("");
  };

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
    setIsSecondModalOpen(false);
  };

  const openSecondModal = () => {
  
    setError(""); // Clear any previous error
    setIsFirstModalOpen(false); // Close first modal
    setIsSecondModalOpen(true); // Open second modal
  };

  

  const handleLinkChange = (index, event) => {
    const newLinks = modalData.links.map((link, i) =>
      i === index ? { value: event.target.value } : link
    );
    setModalData({ ...modalData, links: newLinks });
  };

  const handleAddLink = () => {
    setModalData({ ...modalData, links: [...modalData.links, { value: "" }] });
  };

  const handleRemoveLink = (index) => {
    setModalData({
      ...modalData,
      links: modalData.links.filter((_, i) => i !== index),
    });
  };

  const closeModals = () => {
    isOpen = false;
    setIsFirstModalOpen(false);
    setIsSecondModalOpen(false);
    // dispatch(skipOnboarding());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Add your submission logic here...
    setLoading(false);
    closeModel(); // Close the modal after submission
  };

  return (
    <div>
      {isFirstModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close mb-5 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
                <h5 className="modal-title mt-5 text-black">
                  Add Your Details
                </h5>
              </div>
              {error && (
                <div className="text-danger mt-3 text-center">{error}</div>
              )}
              <div className="modal-body m-4 text-black">
                <form>
                  <div className="mb-3">
                    <label>
                      <strong>Select Agent Type</strong>
                    </label>
                    <select
                      className="form-select rounded-5"
                      name="userType"
                      value={modalData.userType}
                      onChange={handleChange}
                    >
                      <option value="">Select Agent Type</option>
                      <option value="solo">Solo</option>
                      <option value="brokerage">Brokerage</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label>
                      <strong>MLS Number</strong>
                    </label>
                    <input
                      type="text"
                      name="mlsNumber"
                      placeholder="MLS Number"
                      className="form-control form-control-rounded"
                      value={modalData.mlsNumber}
                      onChange={handleChange}
                    />
                  </div>
                  {modalData.userType === "solo" && (
                    <>
                      {/* Solo Agent Fields */}
                      <div className="mb-3">
                        <label>
                          <strong>Display Name</strong>
                        </label>
                        <input
                          type="text"
                          name="displayName"
                          placeholder="Display Name"
                          className="form-control form-control-rounded"
                          value={modalData.displayName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label>
                          <strong>Business Email</strong>
                        </label>
                        <input
                          type="email"
                          name="businessEmail"
                          placeholder="Business Email"
                          className="form-control form-control-rounded"
                          value={modalData.businessEmail}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label>
                          <strong>Business Phone</strong>
                        </label>
                        <PhoneInput
                          international
                          defaultCountry="US"
                          value={modalData.businessPhone}
                          onChange={(value) =>
                            setModalData({
                              ...modalData,
                              businessPhone: value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-3">
                        <label>
                          <strong>Business Address</strong>
                        </label>
                        <input
                          type="text"
                          name="businessAddress"
                          placeholder="Business Address"
                          className="form-control form-control-rounded"
                          value={modalData.businessAddress}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                  {/* Brokerage Fields */}
                  {modalData.userType === "brokerage" && (
                    <>
                      <div className="mb-3">
                        <label>
                          <strong>Name</strong>
                        </label>
                        <input
                          type="text"
                          name="name"
                          placeholder="Name"
                          className="form-control form-control-rounded"
                          value={modalData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label>
                          <strong>Email</strong>
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="form-control form-control-rounded"
                          value={modalData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                </form>
              </div>
              <div className="d-flex justify-content-end me-4 gap-3">
                <button className="btn-next" onClick={openSecondModal}>
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Next</div>
                    <IconChevronRight size={18} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSecondModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div
                  className="h5 d-flex align-items-center cursor-pointer text-black"
                  onClick={openFirstModal}
                >
                  <IconChevronLeft />
                  Back
                </div>
                <button
                  type="button"
                  className="btn-close mb-5 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
                <h5 className="modal-title mt-5 text-black">
                  Add Social Details
                </h5>
              </div>
              <div className="modal-body m-4">
                {modalData.links.map((link, index) => (
                  <div className="d-flex align-items-center gap-2 mb-3" key={index}>
                    <input
                      type="text"
                      className="form-control form-control-rounded"
                      value={link.value}
                      onChange={(e) => handleLinkChange(index, e)}
                      placeholder="Add Social Link"
                    />
                    {index > 0 && (
                      <button
                        className="btn-trash"
                        onClick={() => handleRemoveLink(index)}
                      >
                        <IconTrash size={18} />
                      </button>
                    )}
                  </div>
                ))}
                <button className="btn-next" onClick={handleAddLink}>
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Add</div>
                    <IconCirclePlus size={17} />
                  </div>
                </button>
              </div>
              <div className="d-flex justify-content-end me-4 gap-3">
                <button className="btn-next" onClick={handleSubmit} disabled={loading}>
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Save</div>
                    <IconChevronRight size={18} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      
    </div>
  );
};

export default AddAgent;
