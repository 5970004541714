import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { deleteNewsApi, downloadImageApi, fetchNews, updateNewsApi, uploadImageApi } from '../../modules/dashboard/actions';
import { Bounce, toast } from 'react-toastify';
import { getDownloadUrl } from '../../config/config';

const UpdateNews = ({ isOpen, closeModel, news }) => {
    const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    heading: news?.heading || "",
    content: news?.content || "",
    imageUrl: news?.imageUrl || "",
  });
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (news) {
      // Ensure modalData gets updated when 'news' prop changes
      setModalData({
        heading: news.heading || "",
        content: news.content || "",
        imageUrl: news.imageUrl || "",
      });
    }
  }, [news]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        // Upload the image and get the image URL
        const uploadResponse = await dispatch(uploadImageApi(file)); 
        const filePath = new URL(uploadResponse).pathname.substring(1);
        const fileName = uploadResponse.substring(
          uploadResponse.lastIndexOf("/") + 1
        );
        const downloadUrl = getDownloadUrl(fileName);
        if (filePath) {
          const response = await dispatch(downloadImageApi(filePath)); // Pass file path to API
          // const data = await response.json();
         // Set the downloaded image URL
          console.log(response.filePath);
        }
        const imageUrl = downloadUrl; // Assuming the API returns the URL in the payload
        setModalData({ ...modalData, imageUrl });
      } catch (uploadError) {
        setError("Failed to upload image");
      }
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteClick = () => {
    setIsConfirmModalOpen(true); // Open confirmation modal
  };

  const handleDeleteConfirm = async () => {
    setIsSubmitting(true);
    const response = await dispatch(deleteNewsApi(news.id));
    if (response) {
      toast.success("News is deleted", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setIsConfirmModalOpen(false);
    closeModel();
    setIsSubmitting(false);
  };

  const handleDeleteCancel = () => {
    setIsConfirmModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!modalData.heading || !modalData.content || !modalData.imageUrl) {
      setError("Please fill in all fields and upload an image");
      return;
    }
    setIsSubmitting(true);
    try {
      // Submit the form with modalData
      const response = await dispatch(updateNewsApi(news.id,modalData));
      dispatch(fetchNews());
      if (response) {
        toast.success("News is updated", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
      closeModel(); // Close the modal after successful submission
    } catch (submitError) {
      setError("Failed to submit news");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen || isConfirmModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen, isConfirmModalOpen]);


  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close mt-2 me-2"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              <h1 className="text-center mb-4">Update News</h1>

              {error && <div className="text-danger mt-3 text-center">{error}</div>}

              <div className="modal-body text-black">
                <form>
                  <div className="mb-3 ">
                    <label className="form-label"><strong>Upload Cover Image</strong></label>
                    <div className="border p-2 rounded-4 cursor-pointer text-center" onClick={handleFileClick}>
                      <p className="text-muted mt-2">
                        Drag & Drop or <span className="text-primary">Choose file</span> to upload
                      </p>
                      <div className="text-center">
                          <img 
                            src={modalData.imageUrl} 
                            alt="Uploaded Preview" 
                            className="img-fluid rounded-4" 
                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                          />
                          {/* <button 
                            type="button" 
                            className="btn btn-danger mt-2" 
                            onClick={handleRemoveImage}
                          >
                            Remove Image
                          </button> */}
                        </div>
                    </div>

                    <input
                      type="file"
                      name="imageUrl"
                      accept="image/*"
                      className="d-none"
                      ref={fileInputRef} 
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label><strong>Heading</strong></label>
                    <input
                      type="text"
                      name="heading"
                      placeholder="Heading"
                      className="form-control form-control-rounded"
                      defaultValue={modalData.heading}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label><strong>Description</strong></label>
                    <textarea
                      name="content"
                      placeholder="Description"
                      className="form-control rounded-4 mt-2"
                      rows="3"
                      defaultValue={modalData.content}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                </form>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn  btn-danger btn-pill"
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-primary btn-pill"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfirmModalOpen && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            <div className="">
              <div className="modal-header">
                <h5 className="modal-title">Delete News</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleDeleteCancel}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this news?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleDeleteCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UpdateNews