import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_LOGOUT,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  EMAIL_VERIFICATION_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  IS_SKIPPED_SUCCESS,
  IS_SKIPPED_FAILURE,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNUP_REQUEST,
  CLEAR_PROFILE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_LISTING_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  STRIPE_PAYMENT_FAILURE,
  STRIPE_PAYMENT_REQUEST,
  STRIPE_PAYMENT_SUCCESS,
  STRIPE_PAYMENT_CHECKOUT_FAILURE,
  STRIPE_PAYMENT_CHECKOUT_REQUEST,
  STRIPE_PAYMENT_CHECKOUT_SUCCESS,
} from "../../config/actionTypes";
import AxiosInstance from "../../config/axiosInstance";

// Action creators
export const signupRequest = () => ({
  type: SIGNUP_REQUEST,
});

export const signupSuccess = (data) => ({
  type: SIGNUP_SUCCESS,
  payload: data,
});

export const signupFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});

export const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const userForgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});

export const userForgotPasswordSuccess = (payload) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const userEmailVerificationFailure = (error) => ({
  type: EMAIL_VERIFICATION_FAILURE,
  payload: error,
});

export const userEmailVerificationSuccess = (payload) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload,
});

export const userRestPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const userRestPasswordSuccess = (payload) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const getProfileSuccess = (profile) => ({
  type: GET_PROFILE_SUCCESS,
  payload: profile,
});

export const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  payload: error,
});

export const skipOnboardingSuccess = (data) => ({
  type: IS_SKIPPED_SUCCESS,
  payload: data,
});

export const skipOnboardingFailure = (error) => ({
  type: IS_SKIPPED_FAILURE,
  payload: error,
});

export const clearProfile = () => ({
  type: CLEAR_PROFILE,
});

export const updateProfileRequest = () => ({
  type: UPDATE_PROFILE_REQUEST,
});

export const updateProfileSuccess = (profile) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: profile,
});

export const updateProfileFailure = (error) => ({
  type: UPDATE_LISTING_FAILURE,
  payload: error,
});

export const updatePasswordRequest = () => ({
  type: UPDATE_PASSWORD_REQUEST,
});

export const updatePasswordSuccess = (payload) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload,
});

export const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: error,
});

export const stripePaymentRequest = () => ({
  type: STRIPE_PAYMENT_REQUEST,
});

export const stripePaymentSuccess = (payment) => ({
  type: STRIPE_PAYMENT_SUCCESS,
  payload: payment,
});

export const stripePaymentFailure = (error) => ({
  type: STRIPE_PAYMENT_FAILURE,
  payload: error,
});

export const stripePaymentCheckoutRequest = () => ({
  type: STRIPE_PAYMENT_CHECKOUT_REQUEST,
});

export const stripePaymentCheckoutSuccess = (payment) => ({
  type: STRIPE_PAYMENT_CHECKOUT_SUCCESS,
  payload: payment,
});

export const stripePaymentCheckoutFailure = (error) => ({
  type: STRIPE_PAYMENT_CHECKOUT_FAILURE,
  payload: error,
});


export const login = (credentials) => async (dispatch) => {
  try {
    const response = await AxiosInstance.post("/users/login", credentials);
    const { token, refreshToken } = response.data;

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);

    dispatch(loginSuccess({ token, refreshToken }));
    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Login failed';
    dispatch(loginFailure(errorMessage));
    throw error;
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  dispatch(userLogout());
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const response = await AxiosInstance.post("/users/reset-password", {
      email,
    });
    if (response.status === 200 && response.data === "OTP send Succesfully") {
      dispatch(userForgotPasswordSuccess(response.data));
    } else {
      dispatch(userForgotPasswordFailure("Unexpected response from server"));
    }
  } catch (error) {
    dispatch(
      userForgotPasswordFailure(error.response?.data?.message || "Bad Request")
    );
  }
};

export const emailVerification = (data) => async (dispatch) => {
  try {
    const response = await AxiosInstance.post("/users/verify-otp", data);
    if (response.status === 200) {
      const { token, refreshToken } = response.data;

      dispatch(userEmailVerificationSuccess({ token, refreshToken }));
    } else {
      dispatch(userEmailVerificationFailure("Unexpected response from server"));
    }
  } catch (error) {
    dispatch(
      userEmailVerificationFailure(
        error.response?.data?.message || "Bad Request"
      )
    );
    throw error;
  }
};

export const restPassword = (data) => async (dispatch) => {
  try {
    const { password, token } = data;
    const response = await AxiosInstance.post(
      "/users/new-password",
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(userEmailVerificationSuccess(response.data));
    } else {
      dispatch(userEmailVerificationFailure("Unexpected response from server"));
    }
  } catch (error) {
    dispatch(
      userEmailVerificationFailure(
        error.response?.data?.message || "Bad Request"
      )
    );
    throw error;
  }
};

export const getProfile = () => async (dispatch) => {
  dispatch(clearProfile());
  try {
    const response = await AxiosInstance.get("/users/me");
    dispatch(getProfileSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Failed to get profile';
    dispatch(getProfileFailure(errorMessage));
    throw error;
  }
};

export const skipOnboarding = () => async (dispatch) => {
  try {
    const data = {isSkipped:true}
    const response = await AxiosInstance.put("/users/is-skipped",data);
    dispatch(skipOnboardingSuccess(response.data));
    dispatch(getProfile());
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'Failed to Skip onboarding progress.';
    dispatch(skipOnboardingFailure(errorMessage));
    throw error;
  }
}

export const signup = (formData) => async (dispatch) => {
  dispatch(signupRequest());
  try {
    const response = await AxiosInstance.post('users/signup', formData);
    if (response.status === 200) {
      dispatch(signupSuccess(response.data));
      // toast.success('Signup successful!');
    } else {
      dispatch(signupFailure('Unexpected response from server'));
      // toast.error('Unexpected response from server');
    }
    return response.data;
  } catch (error) {
    dispatch(signupFailure(error.response?.data?.message || 'Bad Request'));
    throw error;
    // toast.error(error.response?.data?.message || 'Bad Request');
  }

};

export const updateProfile = (id,formData) => async (dispatch) => {
  dispatch(updateProfileRequest());
  try {
    const response = await AxiosInstance.patch(`users/${id}`, formData);
    dispatch(updateProfileSuccess(response.data));
    if (response.status === 200) {
      // toast.success('Profile updated successfully!');
    } else {
      dispatch(updateProfileFailure('Unexpected response from server'));
      // toast.error('Unexpected response from server');
    }
    return response.data;
  } catch (error) {
    dispatch(updateProfileFailure(error.response?.data?.message || 'Bad Request'));
    throw error;
    // toast.error(error.response?.data?.message || 'Bad Request');
  }

};

export const updatePassword = (formData) => async (dispatch) => {
  dispatch(updatePasswordRequest());
  try {
    const response = await AxiosInstance.put(`users/change-password`, formData);
    dispatch(updatePasswordSuccess(response.data));
    if (response.status === 200) {
      // toast.success('Password updated successfully!');
    } else {
      dispatch(updatePasswordFailure('Unexpected response from server'));
      // toast.error('Unexpected response from server');
    }
    return response.data;
  } catch (error) {
    dispatch(updatePasswordFailure(error.response?.data?.message || 'Bad Request'));
    throw error;
    // toast.error(error.response?.data?.message || 'Bad Request');
  }

}

export const stripePaymentApi = (data) => async (dispatch) => {
  dispatch(stripePaymentRequest());
  try {
    const response = await AxiosInstance.post("/payments/create/checkout-session", data);
    dispatch(stripePaymentSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to make payment.";
    dispatch(stripePaymentFailure(errorMessage));
  }
};

export const stripePaymentCheckoutApi = (data) => async (dispatch) => {
  dispatch(stripePaymentCheckoutRequest());
  try {
    const response = await AxiosInstance.post("/payments/checkout/success", data);
    dispatch(stripePaymentCheckoutSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to make payment.";
    dispatch(stripePaymentCheckoutFailure(errorMessage));
  }
};


