import {
  IconCheck,
  IconCirclePlus,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { roomNames } from "../RoomNames";

const Rooms = ({ formData, setFormData }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [roomData, setRoomData] = useState({
    level: "",
    name: "",
    width: "",
    length: "",
    other: "",
    areaType: "SQ FT",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const toggleForm = () => {
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    setRoomData({
      level: "",
      name: "",
      width: "",
      length: "",
      info: "",
      areaType: "",
    });
    setEditingIndex(null);
  };

  const updateRoom = (index, updatedRoom) => {
    const updatedRooms = [...formData.rooms];
    updatedRooms[index] = updatedRoom;
    setFormData({
      ...formData,
      rooms: updatedRooms,
    });
  };

  const deleteRoom = (index) => {
    const updatedRooms = formData.rooms.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      rooms: updatedRooms,
    });
  };

  const addRoom = (room) => {
    setFormData({
      ...formData,
      rooms: [...formData.rooms, room],
    });
  };

  const handleRoomInputChange = (e) => {
    const { name, value } = e.target;
    setRoomData({ ...roomData, [name]: value });
  };

  const handleAddRoom = () => {
    if (editingIndex !== null) {
      updateRoom(editingIndex, roomData);
    } else {
      addRoom(roomData);
    }
    closeForm();
  };

  const handleEditRoom = (index) => {
    const roomToEdit = formData.rooms[index];
    setRoomData(roomToEdit);
    setEditingIndex(index);
    toggleForm();
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Rooms</h2>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={toggleForm}
            disabled={isFormOpen}
          >
            Add
            <IconCirclePlus className="ms-1" />
          </button>
        </div>
      </div>
      <div className="video-player mt-3">
        {isFormOpen && (
          <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
            <div className="flex-grow-1">
              <div className="d-flex flex-column flex-lg-row gap-2">
                <div className="mb-2 w-100 w-lg-25">
                  <label className="form-label">
                    Room Level
                  </label>
                  <select
                    className="form-select rounded-5"
                    name="level"
                    value={roomData.level}
                    onChange={handleRoomInputChange}
                  >
                    <option value="" className="form-select">
                      Select Level
                    </option>
                    <option value="Above" className="form-select">
                      Above
                    </option>
                    <option value="Main F" className="form-select">
                      Main F
                    </option>
                    <option value="Main" className="form-select">
                      Main
                    </option>
                    <option value="Bsmt" className="form-select">
                      Bsmt
                    </option>
                  </select>
                </div>
                <div className="mb-2 w-100 w-lg-25">
                  <label className="form-label">
                    Room Name
                  </label>
                  <select
                    className="form-select rounded-5"
                    name="name"
                    value={roomData.name}
                    onChange={handleRoomInputChange}
                  >
                    <option value="" className="form-select">
                      Select Room name
                    </option>
                    {roomNames.map((room) => (
                      <option key={room} value={room} className="form-select">
                        {room}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 w-100 w-lg-25">
                  <label>
                    Width
                  </label>
                  <div className="d-flex">
                    <input
                      type="number"
                      name="width"
                      placeholder="Width"
                      className="form-control round-left"
                      value={roomData.width}
                      onChange={handleRoomInputChange}
                      style={styles.inputArea}
                    />
                    <select
                      className="form-select"
                      name="areaType"
                      value={roomData.areaType}
                      onChange={handleRoomInputChange}
                      style={styles.select}
                    >
                      <option value="SQ FT">SQ FT</option>
                      <option value="Acres">Acres</option>
                      <option value="Hectares">Hectares</option>
                      <option value="Square Meters">Square Meters</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3 w-100 w-lg-25">
                  <label>
                    Length
                  </label>
                  <div className="d-flex">
                    <input
                      type="number"
                      name="length"
                      placeholder="Length"
                      className="form-control round-left"
                      value={roomData.length}
                      onChange={handleRoomInputChange}
                      //   style={styles.inputArea}
                    />
                    <select
                      className="form-select"
                      name="areaType"
                      value={roomData.areaType}
                      onChange={handleRoomInputChange}
                      style={styles.select}
                    >
                      <option value="SQ FT">SQ FT</option>
                      <option value="Acres">Acres</option>
                      <option value="Hectares">Hectares</option>
                      <option value="Square Meters">Square Meters</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Other Info
                </label>
                <textarea
                  type="text"
                  placeholder="Other Info"
                  name="info"
                  value={roomData.info}
                  className="form-control rounded-4"
                  onChange={handleRoomInputChange}
                />
              </div>
            </div>
            <div className="d-flex flex-md-column flex-row justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={closeForm}
                style={{ width: "40px", height: "40px" }}
              >
                <IconX size={17} />
              </button>
              <button
                type="button"
                className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleAddRoom}
                style={{ width: "40px", height: "40px" }}
              >
                <IconCheck size={17} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        {formData.rooms.length > 0
          ? formData.rooms.map((room, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4"
              >
                <div className="d-flex flex-wrap align-items-center gap-2">
                  <div>
                    <IconGripVertical />
                  </div>
                  <div>
                    <p className="h3">{room.name}</p>
                    <div className="d-flex gap-2">
                      <p className="mb-0 border-end pe-2">
                        <strong>Level: </strong>
                        {room.level} 
                      </p>
                      <p className="mb-0 border-end pe-2">
                        <strong>Width: </strong>
                        {room.width} {room.areaType}
                      </p>
                      <p className="mb-0 border-end pe-2">
                        <strong>length: </strong>
                        {room.length} {room.areaType}
                      </p>
                      <p className="mb-0">
                        <strong>Other Info: </strong>
                        {room.info}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => handleEditRoom(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconPencilMinus size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => deleteRoom(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconTrash size={17} />
                  </button>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "70%",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default Rooms;
