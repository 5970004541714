import {
  IconCalendarMonth,
  IconCheck,
  IconCirclePlus,
  IconClipboard,
  IconClock,
  IconExternalLink,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";
import { americaCanadaTimezones } from "../TimeZones";

const OpenHouse = ({ formData, setFormData }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openHouseData, setOpenHouseData] = useState({
    name: "",
    date: "",
    startsAt: "",
    endsAt: "",
    timezone: "",
    note: "",
    link: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const toggleForm = () => {
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    setOpenHouseData({
      name: "",
      date: "",
      startsAt: "",
      endsAt: "",
      timezone: "",
      note: "",
      link: "",
    });
    setEditingIndex(null);
  };

  const updateOpenHouseData = (index, updatedLink) => {
    const updatedOpenHouse = [...formData.openHouses];
    updatedOpenHouse[index] = updatedLink;
    setFormData({
      ...formData,
      openHouses: updatedOpenHouse,
    });
  };

  const deleteLink = (index) => {
    const updatedOpenHouse = formData.openHouses.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      openHouses: updatedOpenHouse,
    });
  };

  const addLink = (link) => {
    setFormData({
      ...formData,
      openHouses: [...formData.openHouses, link],
    });
  };

  const handleOpenHouseInputChange = (e) => {
    const { name, value } = e.target;
    setOpenHouseData({ ...openHouseData, [name]: value });
  };

  const handleAddOpenHouse = () => {
    if (editingIndex !== null) {
      updateOpenHouseData(editingIndex, openHouseData);
    } else {
      addLink(openHouseData);
    }
    closeForm();
  };

  const handleEditOpenHouse = (index) => {
    const openHouseToEdit = formData.openHouses[index];
    setOpenHouseData(openHouseToEdit);
    setEditingIndex(index);
    toggleForm();
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Open House</h2>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={toggleForm}
            disabled={isFormOpen}
          >
            Add
            <IconCirclePlus className="ms-1" />
          </button>
        </div>
      </div>
      <div className="video-player mt-3">
        {isFormOpen && (
          <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
            <div className="flex-grow-1">
              <div className="mb-2">
                <label className="form-label">
                  Name 
                </label>
                <input
                  type="text"
                  placeholder="Open House"
                  className="form-control rounded-5"
                  name="name"
                  value={openHouseData.name}
                  onChange={handleOpenHouseInputChange}
                />
              </div>
              <div className="d-flex flex-column flex-lg-row gap-2">
                <div className="mb-2 w-100 w-md-25">
                  <label className="form-label">
                    Name 
                  </label>
                  <input
                    type="date"
                    className="form-control rounded-5"
                    name="date"
                    value={openHouseData.date}
                    onChange={handleOpenHouseInputChange}
                  />
                </div>
                <div className="mb-2 w-100 w-md-50">
                  <label className="form-label">
                    Tome Zone 
                  </label>
                  <select
                    className="form-select rounded-5"
                    name="timezone"
                    value={openHouseData.timezone}
                    onChange={handleOpenHouseInputChange}
                  >
                    <option value="" className="form-select">
                      Select
                    </option>
                    {americaCanadaTimezones.map((timezone) => (
                      <option
                        key={timezone}
                        value={timezone}
                        className="form-select"
                      >
                        {timezone}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-2 w-100 w-md-25">
                  <label className="form-label">
                    Starts At
                  </label>
                  <input
                    type="time"
                    className="form-control rounded-5"
                    name="startsAt"
                    value={openHouseData.startsAt}
                    onChange={handleOpenHouseInputChange}
                  />
                </div>
                <div className="mb-2 w-100 w-lg-25">
                  <label className="form-label">
                    Ends At
                  </label>
                  <input
                    type="time"
                    className="form-control rounded-5"
                    name="endsAt"
                    value={openHouseData.endsAt}
                    onChange={handleOpenHouseInputChange}
                  />
                </div>
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Public Note
                </label>
                <textarea
                  type="text"
                  placeholder="Public Note"
                  name="note"
                  value={openHouseData.note}
                  className="form-control rounded-4"
                  onChange={handleOpenHouseInputChange}
                />
              </div>
              <div className="mb-3">
                <label>
                  Live Stream Link
                </label>
                <div className="d-flex w-100 align-items-center gap-2">
                  <div class="input-icon mb-3 w-100">
                    <input
                      type="text"
                      name="link"
                      placeholder="Paste Link Here"
                      className="form-control form-control-rounded"
                      value={openHouseData.link}
                      onChange={handleOpenHouseInputChange}
                    />
                    <span class="input-icon-addon">
                      <IconClipboard />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-md-column flex-row justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={closeForm}
                style={{ width: "40px", height: "40px" }}
              >
                <IconX size={17} />
              </button>
              <button
                type="button"
                className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleAddOpenHouse}
                style={{ width: "40px", height: "40px" }}
              >
                <IconCheck size={17} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        {formData.openHouses.length > 0
          ? formData.openHouses.map((open, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <IconGripVertical />
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <p className="mb-0 border-end pe-2">
                      <IconCalendarMonth className="me-2 mb-1" />
                      Public{open.date}
                    </p>
                    <p className="mb-0 border-end pe-2">
                      <IconClock className="me-2 mb-1" />
                      
                        Public{open.startsAt} to {open.endsAt}
                      
                    </p>
                    <p className="mb-0 border-end pe-2">
                      Public Note: {open.note}
                    </p>
                    <p className="mb-0">
                      <IconExternalLink className="me-2 mb-1" />
                      {open.link}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-md-column flex-row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => handleEditOpenHouse(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconPencilMinus size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => deleteLink(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconTrash size={17} />
                  </button>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

export default OpenHouse;
