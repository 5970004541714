import {
  IconCircleCheck,
  IconThumbUp,
  IconTrendingUp,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllLeads, fetchLeads } from "../../modules/dashboard/actions";
import { format, isWithinInterval, subDays } from "date-fns";

// const leadsBySourceData = {
//   labels: ["Listing forms", "Others"],
//   datasets: [
//     {
//       data: [80, 20],
//       backgroundColor: ["#00BFA6", "#E5E7EB"],
//       borderWidth: 1,
//     },
//   ],
// };

// const leadsByLabelData = {
//   labels: ["Unseen", "Lead", "Others"],
//   datasets: [
//     {
//       label: "Leads By Label",
//       data: [0.8, 0.6, 0.3],
//       backgroundColor: ["#3B82F6", "#A5B4FC", "#E5E7EB"],
//       borderWidth: 1,
//     },
//   ],
// };

// const dailyActivityData = {
//   labels: ["04", "05", "06", "07", "08", "09"],
//   datasets: [
//     {
//       label: "Daily Activity",
//       data: [12, 8, 5, 7, 12, 6],
//       backgroundColor: "#3B82F6",
//     },
//   ],
// };

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false, // Allow charts to scale dynamically
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const LeadsAnalytics = () => {
  const dispatch = useDispatch();
  const [totalLeads, setTotalLeads] = useState(0); // Store total leads count
  const [potentialLeadsCount, setPotentialLeadsCount] = useState(0); // Store potential leads count

  const { leadsTotal,leadsTotalPages,leadsCurrentPage,leads } = useSelector(
    (state) => state.dashboard
  );

  const [leadsBySourceData, setLeadsBySourceData] = useState({
    labels: ["Potential Leads", "Other Leads"],
    datasets: [
      {
        data: [0, 0], // Initial values (to be updated dynamically)
        backgroundColor: ["#00BFA6", "#E5E7EB"],
        borderWidth: 1,
      },
    ],
  });
  
  const [dailyActivityData, setDailyActivityData] = useState({
    labels: [], // Labels for each day (to be updated dynamically)
    datasets: [
      {
        label: "Daily Activity",
        data: [], // Initial values (to be updated dynamically)
        backgroundColor: "#3B82F6",
        borderWidth: 1,
      },
    ],
  });

  const [leadsByLabelData, setLeadsByLabelData] = useState({
    labels: ["Unseen", "Others"], // Labels for each type of lead
    datasets: [
      {
        label: "Leads By Label",
        data: [0, 0], // Initial values (to be updated dynamically)
        backgroundColor: ["#3B82F6", "#A5B4FC"],
        borderWidth: 1,
      },
    ],
  });
  // Fetch leads across all pages
  // const fetchAllLeads = async () => {
  //   let allLeads = [];
  //   for (let page = 1; page <= leadsTotalPages; page++) {
  //     await dispatch(fetchLeads(page)); // Fetch leads for each page
  //     allLeads = allLeads.concat(leads); // Accumulate leads from each page
  //   }
  //   return allLeads;
  // };

     // Count potential leads and daily activity for last 7 days
  const countPotentialLeadsAndDailyActivity = async () => {
    const allLeads = await dispatch(fetchAllLeads());

    // Filter leads created in the last 7 days
    const today = new Date();
    const last7Days = subDays(today, 7);

    const leadsInLast7Days = allLeads.filter((lead) =>
      isWithinInterval(new Date(lead.createdAt), {
        start: last7Days,
        end: today,
      })
    );

    // Count potential leads
    const potentialLeads = allLeads.filter((lead) => lead.isPotential);
    const potentialCount = potentialLeads.length;
    const totalLeadsCount = allLeads.length;

    setPotentialLeadsCount(potentialCount); // Set potential leads count
    setTotalLeads(totalLeadsCount); // Set total leads count

    // Update doughnut chart data
    setLeadsBySourceData({
      labels: ["Potential Leads", "Other Leads"],
      datasets: [
        {
          data: [potentialCount, totalLeadsCount - potentialCount],
          backgroundColor: ["#00BFA6", "#E5E7EB"],
          borderWidth: 1,
        },
      ],
    });

    // Group leads by day and count leads created on each day
    const leadsCountByDay = {};
    for (let i = 0; i <= 7; i++) {
      const day = format(subDays(today, i), "yyyy-MM-dd");
      leadsCountByDay[day] = 0;
    }

    leadsInLast7Days.forEach((lead) => {
      const createdDate = format(new Date(lead.createdAt), "yyyy-MM-dd");
      if (leadsCountByDay[createdDate] !== undefined) {
        leadsCountByDay[createdDate] += 1;
      }
    });

    // Set daily activity data for bar chart
    setDailyActivityData({
      labels: Object.keys(leadsCountByDay).reverse(), // Reverse to show oldest day first
      datasets: [
        {
          label: "Leads Created",
          data: Object.values(leadsCountByDay).reverse(), // Reverse to match label order
          backgroundColor: "#3B82F6",
        },
      ],
    });

     // Count leads by label (Unseen and Others)
     const unseenLeadsCount = allLeads.filter((lead) => lead.label === "Unseen").length;
     const otherLeadsCount = allLeads.filter((lead) => lead.label !== "Unseen").length;
 
     // Set data for the bar chart showing leads by label
     setLeadsByLabelData({
       labels: ["Unseen", "Others"],
       datasets: [
         {
           label: "Leads By Label",
           data: [unseenLeadsCount, otherLeadsCount],
           backgroundColor: ["#3B82F6", "#A5B4FC"],
         },
       ],
     });
  };

  useEffect(() => {
    if (leadsCurrentPage) {
      countPotentialLeadsAndDailyActivity();
    }
  }, [leadsCurrentPage, leadsTotalPages, dispatch, leads]);

  return (
    <div className="container p-2">
      <div className="row mb-4">
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">Total Leads</h4>
              <h1 className="card-title-analytics h1">
                <strong>{totalLeads}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                  <IconTrendingUp className="me-1" />
                  8.5%
                </span> */}
                Total Leads up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">
                Potential Leads
              </h4>
              <h1 className="card-title-analytics h1">
                <strong>{potentialLeadsCount}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                  <IconThumbUp className="me-1" />
                </span> */}
                Potential Leads up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">Manual</h4>
              <h1 className="card-title-analytics h1">
                <strong>{totalLeads - potentialLeadsCount}</strong>
              </h1>
              <div className="card-text d-flex gap-2 text-cyan">
                {/* <span className="text-danger">
                  <IconCircleCheck className="me-1" />
                </span> */}
                Manual up to date
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 mb-4">
          <div className="card rounded-4 p-4 shadow-sm h-100">
            <h4 className="text-center">Leads By Source</h4>
            <div className="chart-container" style={{ position: "relative", height: "100%" }}>
              <Doughnut data={leadsBySourceData} options={chartOptions} />
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card rounded-4 p-4 shadow-sm h-100">
            <h4 className="text-center">Leads By Label</h4>
            <div className="chart-container" style={{ position: "relative", height: "100%" }}>
              <Bar data={leadsByLabelData} options={chartOptions} />
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="card rounded-4 p-4 shadow-sm h-100">
            <h4 className="text-center">Daily Activity</h4>
            <div className="chart-container" style={{ position: "relative", height: "100%" }}>
              <Bar data={dailyActivityData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadsAnalytics;
