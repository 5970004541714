import React, { useEffect, useMemo, useState } from "react";
import SelectedListingCard from "./../SelectedListingCard";

import SmallListingCard from "../ListingCodeGenerator/SmallListingCard";
import ListingSearch from "../ListingCodeGenerator/ListingSearch";
import AddShowcase from "./addShowcase";
import { useDispatch } from "react-redux";
import { createShowcaseApi } from "../../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";
import MlsSmallListingCard from "../ListingCodeGenerator/MLSSmallListingCard";
import MLSAddShowcase from "./MLsAddShowcase";

const ShowCase = ({ isOpen, closeModal, exportedListings, mls }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [scriptLink, setScriptLink] = useState("");
  const [selectedListing, setSelected] = useState(null);
  const [listingIndividualArray, setListingIndividualArray] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedMLsCards, setMLsCards] = useState([]);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("Listing Set"); // Default to Listing Set
  const [activeFilter, setActiveFilter] = useState(""); // Default to Active filter
  const [activePropertyType, setActivePropertyType] = useState("");
  const [selectedSort, setSelectedSort] = useState("All Listings");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMLSStatus, setSelectedMLSStatus] = useState("");
  const [filterOption, setFilterOptions] = useState("Default");
  const handleFilterOptions = (e) => {
    setFilterOptions(e.target.value);
  };
  // const [customSort, setCustomSort] = useState(false);
  // const [showFilters, setShowFilters] = useState(true);
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isPropertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
  console.log(selectedStatus);
  const [isPropertyMlsDropdownOpen, setPropertyMlsDropdownOpen] =
    useState(false);
  const [isMLsDropdownOpen, setMLsDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [listingType, setListingType] = useState("");
  const [isListingTypeDropdownOpen, setListingTypeDropdownOpen] =
    useState(false);
  const [activeMLSFilter, setMLSActiveFilter] = useState("");
  const [activeMlsPropertyType, setMlsActivePropertyType] = useState("");

  const handleListingTypeTypeChange = (type) => {
    setListingType(type); // Set the selected property type filter
    setListingTypeDropdownOpen(false); // Close the dropdown after selection
  };
  const toggleListingTypeDropdown = () => {
    setListingTypeDropdownOpen(!isListingTypeDropdownOpen);
  };

  const individualListing = async (agent) => {
    // handleTabChange("Individual Listing");
    if (activeTab === "Individual Listing") {
      setSelected(agent); // Update selectedListing with the new single listing

      setListingIndividualArray([agent]);
    }
  };

  const removeIndividualListing = (listingId) => {
    setListingIndividualArray((prevArray) =>
      prevArray.filter((listing) => listing.id !== listingId)
    );
    if (selectedListing && selectedListing.id === listingId) {
      setSelected(null); // Clear selectedListing if it's the one being removed
    }
  };

  console.log("Selected Listing: ", selectedListing);
  console.log("Listing Individual Array: ", listingIndividualArray);

  // Function to add card ID to the array
  const addCardIdToArray = (cardId) => {
    setSelectedCards((prev) => {
      if (!prev.includes(cardId)) {
        return [...prev, cardId]; // Add the card ID if it's not already in the array
      }
      return prev; // Return the same array if cardId is already present
    });
    console.log(selectedCards);
  };

  const addMLsCardIdToArray = (cardId) => {
    setMLsCards((prev) => {
      if (!prev.includes(cardId)) {
        return [...prev, cardId]; // Add the card ID if it's not already in the array
      }
      return prev; // Return the same array if cardId is already present
    });
    console.log(selectedMLsCards);
  };

  const listingSetListings = exportedListings.filter((listing) =>
    selectedCards.includes(listing.id)
  );
  const mlsListingSetListings = mls.filter((listing) =>
    selectedMLsCards.includes(listing.ListingKey)
  );

  console.log("Listing Set Listings: ", listingSetListings);

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen, selectedListing]);

  // useEffect(() => {
  //   // Update formData's listingIds whenever sortedCards change
  //   const listingSetListings = exportedListings.filter((listing) =>
  //     selectedCards.includes(listing.id)
  //   );

  //   const filteredCards = listingSetListings.filter((card) => {
  //     if (selectedStatus) {
  //       return (
  //         card.status &&
  //         card.status.toLowerCase() === selectedStatus.toLowerCase()
  //       );
  //     }
  //     return true;
  //   });

  //   // Set sortedCards here
  //   const sortedCards = filteredCards.sort((a, b) => {
  //     if (selectedSort === "Price (Low to High)") {
  //       return a.price - b.price;
  //     } else if (selectedSort === "Price (High to Low)") {
  //       return b.price - a.price;
  //     } else if (selectedSort === "Listed Date (Recent First)") {
  //       return new Date(b.listedDate) - new Date(a.listedDate);
  //     } else if (selectedSort === "Listed Date (Oldest First)") {
  //       return new Date(a.listedDate) - new Date(b.listedDate);
  //     }
  //     return 0; // Default, no sorting
  //   });

  //   console.log(sortedCards)

  //   const listingIds =
  //     listingType === "MLS" ? mlsListingSetListings : sortedCards;
  //   const type = listingType === "MLS" ? "MLS" : "Manual";

  //   setFormData((prevData) => ({
  //     ...prevData,
  //     listingIds: listingIds, // Update listingIds with the sorted cards
  //     propertyType: type,
  //   }));
  // }, [
  //   listingType,
  //   selectedCards,
  //   selectedSort,
  //   selectedStatus,
  //   exportedListings,
  //   mlsListingSetListings,
  // ]); // Add dependencies

  const filteredListings = exportedListings.filter((listing) => {
    const statusMatch = activeFilter ? listing.status === activeFilter : true;
    const propertyMatch = activePropertyType
      ? listing.propertyType === activePropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const filteredMLSListings = mls.filter((listing) => {
    const statusMatch = activeMLSFilter
      ? listing.MlsStatus === activeMLSFilter
      : true;
    const propertyMatch = activeMlsPropertyType
      ? listing.PropertyType === activeMlsPropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const filteredCards = useMemo(() => {
    const listingSetListings = exportedListings.filter((listing) =>
      selectedCards.includes(listing.id)
    );

    return listingSetListings.filter((card) => {
      if (selectedStatus) {
        return (
          card.status &&
          card.status.toLowerCase() === selectedStatus.toLowerCase()
        );
      }
      return true;
    });
  }, [exportedListings, selectedCards, selectedStatus]);

  const filteredMlsCards = useMemo(() => {
    const listingSetListings = mls.filter((listing) =>
      selectedMLsCards.includes(listing.ListingKey)
    );

    return listingSetListings.filter((card) => {
      if (selectedMLSStatus) {
        return (
          card.MlsStatus &&
          card.MlsStatus.toLowerCase() === selectedMLSStatus.toLowerCase()
        );
      }
      return true;
    });
  }, [mls, selectedMLsCards, selectedMLSStatus]);

  const sortedCards = useMemo(() => {
    return filteredCards.sort((a, b) => {
      if (selectedSort === "Price (Low to High)") return a.price - b.price;
      if (selectedSort === "Price (High to Low)") return b.price - a.price;
      if (selectedSort === "Listed Date (Recent First)") {
        return new Date(b.listedDate) - new Date(a.listedDate);
      }
      if (selectedSort === "Listed Date (Oldest First)") {
        return new Date(a.listedDate) - new Date(b.listedDate);
      }
      return 0; // Default, no sorting
    });
  }, [filteredCards, selectedSort]);

  const sortedMlsCards = useMemo(() => {
    return filteredMlsCards.sort((a, b) => {
      if (selectedSort === "Price (Low to High)")
        return a.ListPrice - b.ListPrice;
      if (selectedSort === "Price (High to Low)")
        return b.ListPrice - a.ListPrice;
      if (selectedSort === "Listed Date (Recent First)") {
        return (
          new Date(b.MediaChangeTimestamp) - new Date(a.MediaChangeTimestamp)
        );
      }
      if (selectedSort === "Listed Date (Oldest First)") {
        return (
          new Date(a.MediaChangeTimestamp) - new Date(b.MediaChangeTimestamp)
        );
      }
      return 0; // Default, no sorting
    });
  }, [filteredMlsCards, selectedSort]);

  useEffect(() => {
    const listingIds = listingType === "MLS" ? sortedMlsCards : sortedCards;
    const type = listingType === "MLS" ? "MLS" : "Manual";

    setFormData((prevData) => {
      if (
        JSON.stringify(prevData.listingIds) === JSON.stringify(listingIds) &&
        prevData.propertyType === type
      ) {
        return prevData; // Avoid unnecessary updates
      }
      return {
        ...prevData,
        listingIds,
        propertyType: type,
      };
    });
  }, [listingType, sortedCards, sortedMlsCards]);

  console.log("Sorted Cards: ", sortedCards);
  console.log("status", filteredMLSListings);
  console.log("filtered", filterOption);

  const [formData, setFormData] = useState({
    propertyType: listingType === "MLS" ? "MLS" : "Manual",
    title: "",
    type: "",
    listingIds: listingType === "MLS" ? sortedMlsCards : sortedCards,
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  console.log("Form Data: ", formData);

  const removeCard = (cardId) => {
    setSelectedCards((prevCards) => prevCards.filter((id) => id !== cardId));
  };

  const removeMlsCard = (cardId) => {
    setMLsCards((prevCards) =>
      prevCards.filter((ListingKey) => ListingKey !== cardId)
    );
  };

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const handleFilter = (e) => {
    setSelectedStatus(e.target.value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleMLSDropdown = () => {
    setMLsDropdownOpen(!isMLsDropdownOpen);
  };

  const togglePropertyDropdown = () => {
    setPropertyDropdownOpen(!isPropertyDropdownOpen);
  };

  const toggleMlsPropertyDropdown = () => {
    setPropertyMlsDropdownOpen(!isPropertyMlsDropdownOpen);
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    setDropdownOpen(false);
  };

  const handleMLSFilterChange = (filter) => {
    setMLSActiveFilter(filter);
    setMLsDropdownOpen(false);
  };

  const handlePropertyTypeChange = (type) => {
    setActivePropertyType(type); // Set the selected property type filter
    setPropertyDropdownOpen(false); // Close the dropdown after selection
  };
  const handleMlsPropertyTypeChange = (type) => {
    setMlsActivePropertyType(type); // Set the selected property type filter
    setPropertyMlsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleFilterMLS = (e) => {
    setSelectedMLSStatus(e.target.value);
  };

  useEffect(() => {
    console.log("Selected Cards Updated: ", selectedCards);
  }, [selectedCards]); // This runs every time `selectedCards` changes
  useEffect(() => {
    console.log("Selected Cards Updated: ", selectedMLsCards);
  }, [selectedMLsCards]); // This runs every time `selectedCards` changes

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const response = await dispatch(createShowcaseApi(formData));

    if (response.id) {
      toast.success("Showcase is created", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModal();
      setError("");
      setFormData({
        name: "",
        title: "",
        type: "",
        listingIds: sortedCards,
      });
      setSelectedCards([]); // Clear selected cards after creating showcase
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div
            className="modal-dialog custom-modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div>
                <button
                  className="btn btn-outline-black btn-pill"
                  onClick={toggleListingTypeDropdown}
                  style={{ width: "150px" }}
                >
                  {listingType ? `Type: ${listingType}` : "Type: Manual"}
                </button>

                {/* Dropdown Menu */}
                {isListingTypeDropdownOpen && (
                  <div className="dropdown-menu show">
                    <button
                      className="dropdown-item"
                      onClick={() => handleListingTypeTypeChange("Manual")}
                    >
                      Manual
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => handleListingTypeTypeChange("MLS")}
                    >
                      MLS
                    </button>
                  </div>
                )}
              </div>
              <div className="modal-header">
                <h5 className="modal-title mt-3 mb-3">Add Showcase</h5>
                <button
                  type="button"
                  className="btn-close mb-5 mt-2 me-4"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                {activeTab !== "Listing Search" ? (
                  <div className="row">
                    <div className="col-md-6 bg-gray-400 p-1 mb-3  rounded-4 border">
                      <div className="d-flex flex-column flex-lg-row gap-3 mb-3 p-2 rounded-4">
                        {listingType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleDropdown}
                            >
                              {activeFilter
                                ? `Filter: ${activeFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("active")}
                                >
                                  Active
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("sold")}
                                >
                                  Sold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("on hold")}
                                >
                                  On hold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("pending")}
                                >
                                  Pending
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("deleted")}
                                >
                                  Deleted
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("terminated")
                                  }
                                >
                                  Terminated
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("expired")}
                                >
                                  Expired
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("cancelled")
                                  }
                                >
                                  Cancelled
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("unknown")}
                                >
                                  Unknown
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("wait approval")
                                  }
                                >
                                  Wait approval
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMLSDropdown}
                            >
                              {activeMLSFilter
                                ? `Filter: ${activeMLSFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isMLsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("New")}
                                >
                                  New
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMLSFilterChange("Price Change")
                                  }
                                >
                                  Price Change
                                </button>
                                {/* <button
                                className="dropdown-item"
                                onClick={() => handleFilterChange("on hold")}
                              >
                                On hold
                              </button>
                             */}
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={togglePropertyDropdown}
                            >
                              {activePropertyType
                                ? `Type: ${activePropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("condo")
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("freehold")
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handlePropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMlsPropertyDropdown}
                            >
                              {activeMlsPropertyType
                                ? `Type: ${activeMlsPropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyMlsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange("Commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange(
                                      "Residential Condo & Other"
                                    )
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange(
                                      "Residential Freehold"
                                    )
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMlsPropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-auto ms-auto">
                        {/* <div className="input-icon mb-3">
                          <span className="input-icon-addon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <circle cx="10" cy="10" r="7" />
                              <line x1="21" y1="21" x2="15" y2="15" />
                            </svg>
                          </span>
                          <input
                            type="text"
                            className="form-control form-control-rounded border-0 bg-gray-600"
                            placeholder="Search"
                          />
                        </div> */}
                      </div>
                      <div className="">
                        {listingType !== "MLS" && (
                          <SmallListingCard
                            cards={filteredListings}
                            onClick={individualListing}
                            addCardIdToArray={addCardIdToArray}
                          />
                        )}
                        {listingType === "MLS" && (
                          <div className="row gap-1">
                            {filteredMLSListings.map((listing) => (
                              <MlsSmallListingCard
                                card={listing}
                                onClick={individualListing}
                                addCardIdToArray={addMLsCardIdToArray}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      {listingType !== "MLS" && (
                        <AddShowcase
                          formData={formData}
                          handleChange={handleChange}
                          cards={listingSetListings}
                          sort={handleSortChange}
                          filter={handleFilter}
                          selectedSort={selectedSort}
                          selectedFilter={selectedStatus}
                          removeCard={removeCard}
                          selectedFilterOption={filterOption}
                          setfilterOptions={handleFilterOptions}
                        />
                      )}
                      {listingType === "MLS" && (
                        <MLSAddShowcase
                          formData={formData}
                          handleChange={handleChange}
                          cards={mlsListingSetListings}
                          sort={handleSortChange}
                          filter={handleFilterMLS}
                          selectedSort={selectedSort}
                          selectedFilter={selectedMLSStatus}
                          removeCard={removeMlsCard}
                          selectedFilterOption={filterOption}
                          setfilterOptions={handleFilterOptions}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <ListingSearch />
                )}
              </div>
              <div className="d-flex justify-content-end mt-3 me-4 gap-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-pill"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowCase;
