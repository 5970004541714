import React, { useEffect, useMemo, useState } from "react";
import SelectedListingCard from "../SelectedListingCard";
import listingImage from "../../../assets/images/login-cover.png";
import {
  IconArrowUpRight,
  IconCheck,
  IconClipboard,
  IconPlus,
  IconX,
} from "@tabler/icons-react";

import ListingSearch from "../ListingCodeGenerator/ListingSearch";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { updateShowcaseApi } from "../../../modules/dashboard/actions";
import UpdateShowcaseForm from "./updateShowcase";
import MlsUpdateShowcaseForm from "./mlsUpdateShowcase";
import MLSShowcaseCard from "./mlsSmallCard";

const UpdateShowCase = ({
  isOpen,
  closeModal,
  exportedListings,
  selectedShowcase,
  mls,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  console.log("Selected Showcase: ", selectedShowcase);
  const [selectedListing, setSelected] = useState(null);
  const [listingIndividualArray, setListingIndividualArray] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("Listing Set"); // Default to Listing Set
  const [activeFilter, setActiveFilter] = useState(""); // Default to Active filter
  const [activePropertyType, setActivePropertyType] = useState("");
  const [selectedSort, setSelectedSort] = useState("All Listings");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedMLSStatus, setSelectedMLSStatus] = useState("");
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [scriptLink, setScriptLink] = useState("");
  const profile = useSelector((state) => state.auth.profile);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isPropertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [filterOption, setFilterOptions] = useState("Default");
  const [activeMLSFilter, setMLSActiveFilter] = useState("");
  const [activeMlsPropertyType, setMlsActivePropertyType] = useState("");
  const [isPropertyMlsDropdownOpen, setPropertyMlsDropdownOpen] =
    useState(false);
  const [isMLsDropdownOpen, setMLsDropdownOpen] = useState(false);

  const handleFilterOptions = (e) => {
    setFilterOptions(e.target.value);
  };

  console.log("Selected Cards1: ", selectedCards);

  const listingSetListings = exportedListings.filter((listing) =>
    selectedCards.includes(listing.id)
  );

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen, selectedListing]);

  const filteredListings = exportedListings.filter((listing) => {
    const statusMatch = activeFilter ? listing.status === activeFilter : true;
    const propertyMatch = activePropertyType
      ? listing.propertyType === activePropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const filteredMLSListings = mls.filter((listing) => {
    const statusMatch = activeMLSFilter
      ? listing.MlsStatus === activeMLSFilter
      : true;
    const propertyMatch = activeMlsPropertyType
      ? listing.PropertyType === activeMlsPropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const [formData, setFormData] = useState({
    name: "",
    propertyType: "",
    title: "",
    type: "",
    listingIds: [],
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const addCardIdToArray = (cardId, card) => {
    // Check if the card is already in selectedCards by comparing IDs
    const isCardAlreadySelected = selectedCards.some(
      (selectedCard) => selectedCard.id === cardId
    );
  
    if (!isCardAlreadySelected) {
      // Update selectedCards state with the new card object
      setSelectedCards((prevSelected) => [...prevSelected, card]);
  
      // Update formData state with the new cardId
      setFormData((prevFormData) => ({
        ...prevFormData,
        listingIds: [...prevFormData.listingIds, card], // Add the entire card object
      }));
    }
  };
  

  const addMlsCardIdToArray = (cardId, card) => {
    const isCardAlreadySelected = selectedCards.some(
      (selectedCard) => selectedCard.ListingKey === cardId
    );
    if (!isCardAlreadySelected) {
      // Update selectedCards state with the new card object
      setSelectedCards((prevSelected) => [...prevSelected, card]);

      // Update formData state with the new cardId
      setFormData((prevFormData) => ({
        ...prevFormData,
        listingIds: [...prevFormData.listingIds, card],
      }));
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFormData({
        name: selectedShowcase.name || "",
        propertyType: selectedShowcase.propertyType || "",
        title: selectedShowcase.title || "",
        type: selectedShowcase.type || "",
        listingIds: selectedShowcase.listingIds || [],
      });
      setSelectedCards(selectedShowcase.listingIds || []);
    }
  }, [isOpen, selectedShowcase]);

  const filteredCards = useMemo(() => {
    return selectedCards.filter((card) => {
      if (selectedStatus) {
        return (
          card.status &&
          card.status.toLowerCase() === selectedStatus.toLowerCase()
        );
      }
      return true; // Default, return all
    });
  }, [selectedCards, selectedStatus]);
  
  const filteredMlsCards = useMemo(() => {
    return selectedCards.filter((card) => {
      if (selectedMLSStatus) {
        return (
          card.MlsStatus &&
          card.MlsStatus.toLowerCase() === selectedMLSStatus.toLowerCase()
        );
      }
      return true; // Default, return all
    });
  }, [selectedCards, selectedMLSStatus]);

  // Sorting
  const sortedCards = useMemo(() => {
    return filteredCards.sort((a, b) => {
      if (selectedSort === "Price (Low to High)") {
        return a.price - b.price;
      } else if (selectedSort === "Price (High to Low)") {
        return b.price - a.price;
      } else if (selectedSort === "Listed Date (Recent First)") {
        return new Date(b.listedDate) - new Date(a.listedDate);
      } else if (selectedSort === "Listed Date (Oldest First)") {
        return new Date(a.listedDate) - new Date(b.listedDate);
      }
      return 0; // Default, no sorting
    });
  }, [filteredCards, selectedSort]);

  const sortedMlsCards = useMemo(() => {
    return filteredMlsCards.sort((a, b) => {
      if (selectedSort === "Price (Low to High)")
        return a.ListPrice - b.ListPrice;
      if (selectedSort === "Price (High to Low)")
        return b.ListPrice - a.ListPrice;
      if (selectedSort === "Listed Date (Recent First)") {
        return (
          new Date(b.MediaChangeTimestamp) - new Date(a.MediaChangeTimestamp)
        );
      }
      if (selectedSort === "Listed Date (Oldest First)") {
        return (
          new Date(a.MediaChangeTimestamp) - new Date(b.MediaChangeTimestamp)
        );
      }
      return 0; // Default, no sorting
    });
  }, [filteredMlsCards, selectedSort]);

  const listingIds = useMemo(() => sortedCards, [sortedCards]);
  useEffect(() => {
    if(formData.propertyType !== "MLS"){
      if (sortedCards.length > 0) {
        const isDifferent =
          formData.listingIds.length !== sortedCards.length ||
          formData.listingIds.some(
            (card, index) => card.id !== sortedCards[index].id
          );
  
        if (isDifferent) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            listingIds: sortedCards,
          }));
        }
      }
    } else {
      if (sortedMlsCards.length > 0) {
        const isDifferent =
          formData.listingIds.length !== sortedMlsCards.length ||
          formData.listingIds.some(
            (card, index) => card.id !== sortedMlsCards[index].id
          );
  
        if (isDifferent) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            listingIds: sortedMlsCards,
          }));
        }
      }
    }
  }, [sortedCards,formData.listingIds,sortedMlsCards,selectedStatus,selectedSort,selectedMLSStatus]);

  console.log("Selected Cards: ", formData.listingIds);
  console.log("form", formData);

  const removeCard = (cardId) => {
    // Update selectedCards by filtering out the card with the given cardId
    setSelectedCards((prevSelected) =>
      prevSelected.filter((selectedCard) => selectedCard.id !== cardId)
    );
  
    // Update formData.listingIds by filtering out the card with the given cardId
    setFormData((prevFormData) => ({
      ...prevFormData,
      listingIds: prevFormData.listingIds.filter(
        (listing) => listing.id !== cardId
      ),
    }));
  };
  const removeMLSCard = (cardId) => {
    setSelectedCards((prevSelected) =>
      prevSelected.filter((selectedCard) => selectedCard.ListingKey !== cardId)
    );
  
    // Update formData.listingIds by filtering out the card with the given cardId
    setFormData((prevFormData) => ({
      ...prevFormData,
      listingIds: prevFormData.listingIds.filter(
        (listing) => listing.ListingKey !== cardId
      ),
    }));
  };

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const handleFilter = (e) => {
    setSelectedStatus(e.target.value);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const togglePropertyDropdown = () => {
    setPropertyDropdownOpen(!isPropertyDropdownOpen);
  };

  const toggleMLSDropdown = () => {
    setMLsDropdownOpen(!isMLsDropdownOpen);
  };

  const toggleMlsPropertyDropdown = () => {
    setPropertyMlsDropdownOpen(!isPropertyMlsDropdownOpen);
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    setDropdownOpen(false);
  };

  const handlePropertyTypeChange = (type) => {
    setActivePropertyType(type); // Set the selected property type filter
    setPropertyDropdownOpen(false); // Close the dropdown after selection
  };

  const handleMLSFilterChange = (filter) => {
    setMLSActiveFilter(filter);
    setMLsDropdownOpen(false);
  };

  const handleMlsPropertyTypeChange = (type) => {
    setMlsActivePropertyType(type); // Set the selected property type filter
    setPropertyMlsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleFilterMLS = (e) => {
    setSelectedMLSStatus(e.target.value);
  };

  useEffect(() => {
    console.log("Selected Cards Updated: ", selectedCards);
  }, [selectedCards]);

  const handleGenerateLink = () => {
    const baseUrl = window.location.origin;
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let generatedLink = "";
    if (formData.propertyType !== "MLS") {
      listingIds = formData.listingIds.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId =
        formData.listingIds.length > 0 ? formData.listingIds[0].userId : "";
      generatedLink = `${baseUrl}/listing-code/${listingIds}?userId=${userId}&filter=${filterOption}&title=${formData.title}`;
    } else {
      listingIds = formData.listingIds
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      userId =
        formData.listingIds.length > 0 ? formData.listingIds[0].userId : "";
      generatedLink = `${baseUrl}/listing-code-mls/${listingIds}?userId=${profile.id}&filter=${filterOption}&title=${formData.title}&mls=${profile.mlsNumber}&type=${profile.userType}`;
    }
    setGeneratedLink(generatedLink);
    setIsModalLinkOpen(true);
  };

  const handleGenerateScriptLink = () => {
    handleGenerateLink();
    const baseUrl = window.location.origin; // Gets the base URL, such as http://localhost:3000
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let scriptUrl = "";

    if (formData.propertyType !== "MLS") {
      listingIds = formData.listingIds.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId =
        formData.listingIds.length > 0 ? formData.listingIds[0].userId : "";
      scriptUrl = `${baseUrl}/listing-code/${listingIds}?userId=${userId}&filter=${filterOption}&title=${formData.title}`;
    } else {
      listingIds = formData.listingIds
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      userId =
        formData.listingIds.length > 0 ? formData.listingIds[0].userId : "";
      scriptUrl = `${baseUrl}/listing-code-mls/${listingIds}?userId=${profile.id}&filter=${filterOption}&title=${formData.title}&mls=${profile.mlsNumber}&type=${profile.userType}`;
    }

    // Generate the dynamic script to fetch and display listings from the correct route
    const scriptTag = `<script>(function() {
      const scriptUrl = "${scriptUrl}";
      const container = document.createElement('div');
      container.id = 'listing-container';
      container.style.height = "100vh";
      document.body.appendChild(container);

      const iframe = document.createElement('iframe');
      iframe.src = scriptUrl;
      iframe.width = "100%";
      iframe.style.height = "100vh";
      iframe.style.border = "none";
      container.appendChild(iframe);
    })();
  </script>`;

    setScriptLink(scriptTag);
    setIsModalLinkOpen(true);
  };

  // Function to display the script code in the modal
  const renderEmbedScriptCode = () => {
    return scriptLink;
  };

  const handlePreview = () => {
    const baseUrl = window.location.origin;
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let generatedLink = "";
    if (formData.propertyType !== "MLS") {
      listingIds = formData.listingIds.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId =
        formData.listingIds.length > 0 ? formData.listingIds[0].userId : "";
      generatedLink = `${baseUrl}/listing-code/${listingIds}?userId=${userId}&filter=${filterOption}&title=${formData.title}`;
    } else {
      listingIds = formData.listingIds
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      generatedLink = `${baseUrl}/listing-code-mls/${listingIds}?userId=${profile.id}&filter=${filterOption}&title=${formData.title}&mls=${profile.mlsNumber}&type=${profile.userType}`;
    }
    setGeneratedLink(generatedLink);
    if (generatedLink) {
      window.open(generatedLink, "_blank"); // Opens the link in a new tab
    }
  };

  const closeModalLink = () => {
    setIsModalLinkOpen(false);
  };

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(renderEmbedScriptCode()).then(
        () => {
          console.log("Link copied successfully using Clipboard API");
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error(
            "Failed to copy using Clipboard API, using fallback: ",
            err
          );
          handleCopyLinkFallback(); // Use fallback if Clipboard API fails
        }
      );
    } else {
      console.log("Clipboard API not available, using fallback");
      handleCopyLinkFallback(); // Fallback for unsupported browsers
    }
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = renderEmbedScriptCode();
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy"); // Older API for copying
      console.log("Link copied using fallback method");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Unable to copy text", err);
    }
    document.body.removeChild(textArea);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const response = await dispatch(
      updateShowcaseApi(selectedShowcase.id, formData)
    );

    if (response.id) {
      toast.success("Showcase is updated", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModal();
      setError("");
      setFormData({
        name: "",
        title: "",
        type: "",
        listingIds: sortedCards,
      });
      setSelectedCards([]); // Clear selected cards after creating showcase
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };

  console.log("Sorted Cards: ", sortedCards);
  console.log("status", filteredCards);
  console.log("filtered", filterOption);

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div
            className="modal-dialog custom-modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title mt-3 mb-3">
                  Edit {selectedShowcase.title}
                </h5>
                <button
                  type="button"
                  className="btn-close mb-5 mt-2 me-4"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>

              <div className="modal-body">
                {activeTab !== "Listing Search" ? (
                  <div className="row">
                    <div className="col-md-6 bg-gray-400 p-1 mb-3  rounded-4 border">
                      <div className="d-flex flex-column flex-lg-row gap-3 mb-3 p-2 rounded-4">
                      {formData.propertyType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleDropdown}
                            >
                              {activeFilter
                                ? `Filter: ${activeFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("active")}
                                >
                                  Active
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("sold")}
                                >
                                  Sold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("on hold")}
                                >
                                  On hold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("pending")}
                                >
                                  Pending
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("deleted")}
                                >
                                  Deleted
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("terminated")
                                  }
                                >
                                  Terminated
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("expired")}
                                >
                                  Expired
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("cancelled")
                                  }
                                >
                                  Cancelled
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("unknown")}
                                >
                                  Unknown
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("wait approval")
                                  }
                                >
                                  Wait approval
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {formData.propertyType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMLSDropdown}
                            >
                              {activeMLSFilter
                                ? `Filter: ${activeMLSFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isMLsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("New")}
                                >
                                  New
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMLSFilterChange("Price Change")
                                  }
                                >
                                  Price Change
                                </button>
                                {/* <button
                                className="dropdown-item"
                                onClick={() => handleFilterChange("on hold")}
                              >
                                On hold
                              </button>
                             */}
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {formData.propertyType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={togglePropertyDropdown}
                            >
                              {activePropertyType
                                ? `Type: ${activePropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("condo")
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("freehold")
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handlePropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {formData.propertyType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMlsPropertyDropdown}
                            >
                              {activeMlsPropertyType
                                ? `Type: ${activeMlsPropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyMlsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange("Commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange(
                                      "Residential Condo & Other"
                                    )
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange(
                                      "Residential Freehold"
                                    )
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMlsPropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-auto ms-auto"></div>
                      <div className="">
                        <div className="row gap-1">
                          {formData.propertyType === "MLS" ? (
                            <>
                              {filteredMLSListings.map((card) => (
                                <MLSShowcaseCard
                                card={card}
                                // onClick={individualListing}
                                addCardIdToArray={addMlsCardIdToArray}
                              />
                              ))}
                            </>
                          ) : (
                            <>
                              {filteredListings.map((card, index) => (
                                <div
                                  key={index}
                                  className="custom-card shadow-sm col-12 col-sm-6 col-md-4 col-lg-3 mx-auto m-1" // Responsive column classes
                                  // Event handling for clicking the card
                                >
                                  {/* Image Section */}
                                  <div className="image-section">
                                    <img
                                      src={
                                        card.images &&
                                        card.images[0] &&
                                        card.images[0].imageUrl
                                          ? card.images[0].imageUrl
                                          : listingImage
                                      }
                                      alt={card.title}
                                      className="property-image"
                                    />
                                  </div>

                                  {/* Details Section */}
                                  <div className="details-section">
                                    <div className="price-section">
                                      <div className="price-text">
                                        {card.currency}{" "}
                                        {card.price.toLocaleString()}
                                      </div>
                                    </div>

                                    <div className="title-section">
                                      {card.title}
                                      <div
                                        onClick={() =>
                                          addCardIdToArray(card.id, card)
                                        }
                                      >
                                        <IconPlus
                                          size={20}
                                          className="mb-1 cursor-pointer"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {formData.propertyType !== "MLS" && (
                        <UpdateShowcaseForm
                          formData={formData}
                          handleChange={handleChange}
                          cards={sortedCards}
                          sort={handleSortChange}
                          filter={handleFilter}
                          selectedSort={selectedSort}
                          selectedFilter={selectedStatus}
                          removeCard={removeCard}
                          selectedFilterOption={filterOption}
                          setfilterOptions={handleFilterOptions}
                        />
                      )}
                      {formData.propertyType === "MLS" && (
                        <MlsUpdateShowcaseForm
                          formData={formData}
                          handleChange={handleChange}
                          cards={sortedMlsCards}
                          sort={handleSortChange}
                          filter={handleFilterMLS}
                          selectedSort={selectedSort}
                          selectedFilter={selectedMLSStatus}
                          removeCard={removeMLSCard}
                          selectedFilterOption={filterOption}
                          setfilterOptions={handleFilterOptions}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <ListingSearch />
                )}
              </div>
              <div className="d-flex justify-content-end mt-3 me-4 gap-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-pill"
                  onClick={handleGenerateScriptLink}
                  disabled={
                    activeTab !== "Listing Search" &&
                    selectedCards.length === 0 &&
                    selectedListing === null
                  }
                >
                  Get Short Code
                </button>
                <button
                  className="btn btn-outline-primary btn-pill ms-2"
                  onClick={handlePreview}
                  disabled={
                    activeTab !== "Listing Search" &&
                    selectedCards.length === 0 &&
                    selectedListing === null
                  }
                >
                  Preview <IconArrowUpRight className="" />
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-pill"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalLinkOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Showcase Link</h3>
                <IconX
                  onClick={closeModalLink}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <textarea
                    // type="text"
                    className="form-control rounded-4 flex-grow-1 textarea-input pe-5"
                    value={renderEmbedScriptCode()}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-4"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateShowCase;
