import {

  IconClipboard,
} from "@tabler/icons-react";
import React, { useCallback, useEffect, useState } from "react";

const BuildingForm = ({ formData, handleChange, onSidebarClick }) => {
  const [bedValue, setBedValue] = useState(formData.buildingInfo.bedroom > 0 ? formData.buildingInfo.bedroom : 0);
  const [bathValue, setBathValue] = useState(formData.buildingInfo.bathroom > 0 ? formData.buildingInfo.bathroom : 0);

  const [yearError, setYearError] = useState("");

  const currentYear = new Date().getFullYear();

  const incrementBed = () => {
    setBedValue((prevValue) => prevValue + 1);
  };

  const decrementBed = () => {
    setBedValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const incrementBath = () => {
    setBathValue((prevValue) => prevValue + 1);
  };

  const decrementBath = () => {
    setBathValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const memoizedHandleChange = useCallback(
    (event) => {
      handleChange(event);
    },
    [handleChange]
  );
  
  const validateYear = (value) => {
    const year = parseInt(value, 10);
    if (year <= currentYear) {
      setYearError("");
      handleChange({ target: { name: "year", value } });
    } else {
      setYearError(`Year must not be greater than ${currentYear}.`);
      handleChange({ target: { name: "year", value } });
    }
  };

  useEffect(() => {
    // Only update if the value is different to avoid unnecessary renders
    if (formData.buildingInfo.bedroom !== bedValue) {
      memoizedHandleChange({ target: { name: "bedroom", value: bedValue } });
    }
  }, [bedValue, memoizedHandleChange, formData.buildingInfo.bedroom]);

  useEffect(() => {
    // Only update if the value is different to avoid unnecessary renders
    if (formData.buildingInfo.bathroom !== bathValue) {
      memoizedHandleChange({ target: { name: "bathroom", value: bathValue } });
    }
  }, [bathValue, memoizedHandleChange, formData.buildingInfo.bathroom]);

  return (
    <>
      <h2 className="text-primary">Building Info</h2>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
      <div className="mb-3 w-100">
          <label>
            Year Built
          </label>
          <input
            type="number"
            name="year"
            placeholder="Year Built"
            className="form-control form-control-rounded"
            defaultValue={formData.buildingInfo.year}
            onChange={(e) => validateYear(e.target.value)}
          />
          {yearError && <small className="text-danger">{yearError}</small>}
        </div>
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2">
              {" "}
              Bedrooms
            </label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBed}
                disabled={bedValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bedValue}
                name="bedroom"
                onChange={(e) => {
                  const newValue = Math.max(0, parseInt(e.target.value, 10));
                  bedValue(isNaN(newValue) ? 0 : newValue);
                }}
                style={styles.input}
              />
              <button style={styles.button} onClick={incrementBed}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2">
              {" "}
              Bathrooms
            </label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBath}
                disabled={bathValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bathValue}
                name="bathroom"
                onChange={(e) => {
                  const newValue = Math.max(0, parseInt(e.target.value, 10));
                  bathValue(isNaN(newValue) ? 0 : newValue);
                }}
                style={styles.input}
              />
              <button style={styles.button} onClick={incrementBath}>
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row  justify-content-between gap-3">
        <div className="mb-3 w-100">
          <label>
            Living Area
          </label>
          <div className="d-flex">
            <input
              type="number"
              name="livingArea"
              placeholder="Living Area"
              className="form-control round-left"
              defaultValue={formData.buildingInfo.livingArea}
              onChange={handleChange}
              style={styles.inputArea}
            />
            <select
              className="form-select"
              name="areaType"
              defaultValue={formData.buildingInfo.areaType}
              onChange={handleChange}
              style={styles.select}
            >
              <option value="SQ FT" selected>SQ FT</option>
              <option value="Acres">Acres</option>
              <option value="Hectares">Hectares</option>
              <option value="Square Meters">Square Meters</option>
            </select>
          </div>
        </div>
        <div className="mb-3 w-100">
          <label>
            Lot Area
          </label>
          <div className="d-flex">
            <input
              type="number"
              name="lotArea"
              placeholder="Lot Area"
              className="form-control round-left"
              value={formData.buildingInfo.lotArea}
              onChange={handleChange}
              style={styles.inputArea}
            />
            <select
              className="form-select"
              name="areaType"
              value={formData.buildingInfo.areaType}
              onChange={handleChange}
              style={styles.select}
            >
              <option value="SQ FT">SQ FT</option>
              <option value="Acres">Acres</option>
              <option value="Hectares">Hectares</option>
              <option value="Square Meters">Square Meters</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <label>
          External Virtual Tour
        </label>
        <div class="input-icon mb-3">
          <input
            type="text"
            name="externalUrl"
            placeholder="Paste link Here"
            className="form-control form-control-rounded"
            defaultValue={formData.buildingInfo.externalUrl}
            onChange={handleChange}
          />
          <span class="input-icon-addon">
            <IconClipboard />
          </span>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default BuildingForm;
