import {
  IconMail,
  IconPhone,
  IconUser,
} from "@tabler/icons-react";
import React from "react";

const Basic = ({lead}) => {
  return (
    <>
      <div className="d-flex gap-2">
        <IconUser size={16} />
        <strong>
          <p>{lead.firstName && lead.firstName} {lead.lastName && lead.lastName}</p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconPhone size={16} />
        <strong>
          <p>{lead.phone && lead.phone}</p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconMail size={16} />
        <strong>
          <p>{lead.email && lead.email}</p>
        </strong>
      </div>
      {/* <div className="d-flex gap-2">
        <IconHome size={16} />
        <strong>
          <p>No 19, 2/5, Wellawatte, Colombo 6, Sri Lanka</p>
        </strong>
      </div> */}
      {/* <div className="d-flex gap-2">
        <IconCalendar size={16} />
        <strong>
          <p>Joined: 08.06.2024</p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconCalendar size={16} />
        <strong>
          <p>09.06.1997</p>
        </strong>
      </div>
      <div className="d-flex gap-2">
        <IconGlobe size={16} />
        <strong>
          <p>www.chirag.com</p>
        </strong>
      </div> */}
    </>
  );
};

export default Basic;
