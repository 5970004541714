import BasicInfo from "./AddListing/BasicInfo";
import Videos from "./AddListing/Videos";
import Images from "./AddListing/Images";
import BuildingForm from "./AddListing/BuildingForm";
import Address from "./AddListing/Address";
import Features from "./AddListing/Features";
import Rooms from "./AddListing/Rooms";
import Bathrooms from "./AddListing/Bathrooms";
import Settings from "./AddListing/Settings";
import Section from "./AddListing//Section";
import SEO from "./AddListing/SEO";
import Document from "./AddListing/Document";
import FloorPlans from "./AddListing/FloorPlans";
import ExternalLinks from "./AddListing/ExternalLinks";
import OpenHouse from "./AddListing/openHouse";

const FormContent = ({
  formData,
  handleChange,
  handleChangeBuildingInfo,
  handleChangeAddress,
  handleChangeSEO,
  handleChangeSettings,
  setFormData,
  sectionsRef
}) => {
  // const [error, setError] = useState("");
 
  return (
    <div className="me-3 me-lg-4 d-flex flex-column gap-3">
      <section
        id="basic-info"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[0] = el)}
      >
        <BasicInfo
          formData={formData}
          currency={formData.currency}
          handleChange={handleChange}
          // onSidebarClick={onSidebarClick}
        />
      </section>

      {/* video section */}
      <section id="videos" className="bg-gray-400 p-3 rounded-4 border section" ref={(el) => (sectionsRef.current[1] = el)}>
        <Videos formData={formData} setFormData={setFormData} />
      </section>

      <section id="images" className="bg-gray-400 p-3 rounded-4 border section" ref={(el) => (sectionsRef.current[2] = el)}>
        <Images formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="building-info"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[3] = el)}
      >
        <BuildingForm
          formData={formData}
          handleChange={handleChangeBuildingInfo}
        />
      </section>

      <section
        id="address"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[4] = el)}
      >
        <Address formData={formData} handleChange={handleChangeAddress} />
      </section>

      <section
        id="features"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[5] = el)}
      >
        <Features formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="bedroom-details"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[6] = el)}
      >
        <Rooms formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="bathroom-details"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[7] = el)}
      >
        <Bathrooms formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="document"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[8] = el)}
      >
        <Document formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="floor-plans"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[9] = el)}
      >
        <FloorPlans formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="external-links"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[10] = el)}
      >
        <ExternalLinks formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="open-house"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[11] = el)}
      >
        <OpenHouse formData={formData} setFormData={setFormData} />
      </section>

      <section id="seo" className="bg-gray-400 p-3 rounded-4 border section" ref={(el) => (sectionsRef.current[12] = el)}>
        <SEO formData={formData} handleChange={handleChangeSEO} />
      </section>
      <section
        id="section"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[13] = el)}
      >
        <Section formData={formData} setFormData={setFormData} />
      </section>
      <section
        id="setting"
        className="bg-gray-400 p-3 rounded-4 border section"
        ref={(el) => (sectionsRef.current[14] = el)}
      >
        <Settings formData={formData} handleChange={handleChangeSettings} />
      </section>
    </div>
  );
};

export default FormContent;
