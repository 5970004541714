import React, { useState } from "react";
import ListIDXSearch from "./ListingIDXSearch";
import MapSearch from "./MapSearch";
import CompactSearchForm from "./CompactSearchForm";
import "../../../assets/styles/listingSearch.scss";
import MLSListIDXSearch from "./MLSlistingIDXSearch";
import MLSCompactSearchForm from "./MLSCompactSearchForm";

const ListingSearch = ({
  exportedListings,
  setListingSearchActiveTab,
  onFilter,
  onCustomFilterChange,
  type,
  activeTab
}) => {
  console.log("SAs", exportedListings);
  // State to track the active tab
  // const [activeTab, setActiveTab] = useState("List IDX Search");
  const [listingType, setListingType] = useState("");
  const handleTabChange = (tab) => {
    // setActiveTab(tab);
    setListingSearchActiveTab(tab);
  };

  console.log(type);
  const [formData, setFormData] = useState({
    location: "",
    minPrice: "",
    maxPrice: "",
    currency: "",
    propertyType: "",
    propertyCategory: "",
    status: "",
    year: "",
    approxArea: "",
    areaType: "SQ FT",
    buildingInfo: {
      year: "",
      bathroom: 0,
      bedroom: 0,
      livingArea: "",
      lotArea: "",
      areaType: "SQ FT",
      externalUrl: "",
    },
    address: {
      number: "",
      streetNumber: "",
      streetName: "",
      streetType: "",
      streetDirection: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle tab change
  // const handleTabChange = (tab) => {
  //   setActiveTab(tab);
  // };
  console.log("formData", exportedListings);
  return (
    <div className="container">
      {/* Tab Links */}
      <div className="tab-links d-flex justify-content-center mb-4">
        {/* {type !== "MLS" && (
          <> */}
            <button
              className={`btn btn-ghost ${
                activeTab === "List IDX Search" ? "active" : "text-black"
              }`}
              onClick={() => handleTabChange("List IDX Search")}
            >
              Map Search
            </button>
    
            {/* <button
        //   className={`btn btn-ghost ${activeTab === "Map Search" ? "active" : "text-black"}`}
        //   onClick={() => handleTabChange("Map Search")}
        // >
        //   Map Search
        // </button> */}
            <button
              className={`btn btn-ghost ${
                activeTab === "Compact Search Form" ? "active" : "text-black"
              }`}
              onClick={() => handleTabChange("Compact Search Form")}
            >
              Compact Search Form
            </button>
          {/* </>
        )} */}
      </div>

      

      <div className="tab-content">
        {activeTab === "List IDX Search" && type !== "MLS" && (
          <ListIDXSearch
            exportedListings={exportedListings}
            formData={formData}
            handleChange={handleChange}
            onFilter={onFilter}
            setFormData={setFormData}
          />
        )}
        {activeTab === "List IDX Search" && type === "MLS" && (
          <MLSListIDXSearch
            exportedListings={exportedListings}
            formData={formData}
            handleChange={handleChange}
            onFilter={onFilter}
            setFormData={setFormData}
          />
        )}
        {/* {activeTab === "Map Search" && <MapSearch />} */}
        {activeTab === "Compact Search Form" && type !== "MLS" && (
          <CompactSearchForm
            exportedListings={exportedListings}
            onCustomFilterChange={onCustomFilterChange}
          />
        )}
        {activeTab === "Compact Search Form" && type === "MLS" && (
          <MLSCompactSearchForm
            exportedListings={exportedListings}
            onCustomFilterChange={onCustomFilterChange}
          />
        )}
      </div>
    </div>
  );
};

export default ListingSearch;
