import {
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILURE,
  FETCH_LISTINGS_REQUEST,
  FETCH_LISTINGS_SUCCESS,
  FETCH_LISTINGS_FAILURE,
  FETCH_ALL_LISTINGS_REQUEST,
  FETCH_ALL_LISTINGS_SUCCESS,
  FETCH_ALL_LISTINGS_FAILURE,
  FETCH_LISTING_BY_ID_REQUEST,
  FETCH_LISTING_BY_ID_SUCCESS,
  FETCH_LISTING_BY_ID_FAILURE,
  FETCH_LISTING_BY_STATUS_REQUEST,
  FETCH_LISTING_BY_STATUS_SUCCESS,
  FETCH_LISTING_BY_STATUS_FAILURE,
  CREATE_LISTING_REQUEST,
  CREATE_LISTING_SUCCESS,
  CREATE_LISTING_FAILURE,
  UPDATE_LISTING_REQUEST,
  UPDATE_LISTING_SUCCESS,
  UPDATE_LISTING_FAILURE,
  DELETE_LISTING_REQUEST,
  DELETE_LISTING_SUCCESS,
  DELETE_LISTING_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  ADD_LEADS_REQUEST,
  ADD_LEADS_SUCCESS,
  ADD_LEADS_FAILURE,
  FETCH_LEADS_REQUEST,
  FETCH_LEADS_SUCCESS,
  FETCH_LEADS_FAILURE,
  FETCH_LEAD_BY_ID_FAILURE,
  FETCH_LEAD_BY_ID_REQUEST,
  FETCH_LEAD_BY_ID_SUCCESS,
  UPDATE_LEAD_REQUEST,
  UPDATE_LEAD_SUCCESS,
  UPDATE_LEAD_FAILURE,
  DELETE_LEAD_REQUEST,
  DELETE_LEAD_SUCCESS,
  DELETE_LEAD_FAILURE,
  CREATE_NEWS_REQUEST,
  CREATE_NEWS_SUCCESS,
  CREATE_NEWS_FAILURE,
  FETCH_NEWS_FAILURE,
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_SUCCESS,
  UPDATE_NEWS_REQUEST,
  UPDATE_NEWS_SUCCESS,
  UPDATE_NEWS_FAILURE,
  DELETE_NEWS_FAILURE,
  DELETE_NEWS_REQUEST,
  DELETE_NEWS_SUCCESS,
  FETCH_AGENT_FAILURE,
  FETCH_AGENT_REQUEST,
  FETCH_AGENT_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  FETCH_NEWS_BY_ID_REQUEST,
  FETCH_NEWS_BY_ID_SUCCESS,
  FETCH_NEWS_BY_ID_FAILURE,
  DOWNLOAD_IMAGE_FAILURE,
  DOWNLOAD_IMAGE_REQUEST,
  DOWNLOAD_IMAGE_SUCCESS,
  FETCH_ALL_LEADS_FAILURE,
  FETCH_ALL_LEADS_REQUEST,
  FETCH_ALL_LEADS_SUCCESS,
  CREATE_SHOWCASE_FAILURE,
  CREATE_SHOWCASE_REQUEST,
  CREATE_SHOWCASE_SUCCESS,
  FETCH_SHOWCASE_FAILURE,
  FETCH_SHOWCASE_REQUEST,
  FETCH_SHOWCASE_SUCCESS,
  DELETE_SHOWCASE_FAILURE,
  DELETE_SHOWCASE_REQUEST,
  DELETE_SHOWCASE_SUCCESS,
  UPDATE_SHOWCASE_FAILURE,
  UPDATE_SHOWCASE_REQUEST,
  UPDATE_SHOWCASE_SUCCESS,
  FETCH_IDX_PROPERTIES_REQUEST,
  FETCH_IDX_PROPERTIES_SUCCESS,
  FETCH_IDX_PROPERTIES_FAILURE,
  MLS_MEDIA_FAILURE,
  MLS_MEDIA_SUCCESS,
  MLS_MEDIA_REQUEST,
  FETCH_ALL_AGENT_FAILURE,
  FETCH_ALL_AGENT_REQUEST,
  FETCH_ALL_AGENT_SUCCESS,
  FETCH_ALL_SHOWCASE_REQUEST,
  FETCH_ALL_SHOWCASE_FAILURE,
  FETCH_ALL_SHOWCASE_SUCCESS,
  FETCH__ALL_NEWS_SUCCESS,
  FETCH__ALL_NEWS_FAILURE,
  FETCH__ALL_NEWS_REQUEST,
  FETCH_VOW_PROPERTIES_FAILURE,
  FETCH_VOW_PROPERTIES_REQUEST,
  FETCH_VOW_PROPERTIES_SUCCESS,
  FETCH_USER_BY_ID_FAILURE,
  FETCH_USER_BY_ID_REQUEST,
  FETCH_USER_BY_ID_SUCCESS,
} from "../../config/actionTypes";
import AxiosInstance from "../../config/axiosInstance";
import { getProfile } from "../auth/actions";

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});

export const fetchListingsSuccess = (listings) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: listings,
});

export const fetchListingsFailure = (error) => ({
  type: FETCH_LISTINGS_FAILURE,
  payload: error,
});

// Action creators
export const onboardingSuccess = (payload) => ({
  type: ONBOARDING_SUCCESS,
  payload,
});

export const onboardingFailure = (error) => ({
  type: ONBOARDING_FAILURE,
  payload: error,
});

//FETCH LISTING BY ID
export const fetchListingByIdRequest = () => ({
  type: FETCH_LISTING_BY_ID_REQUEST,
});

export const fetchListingByIdSuccess = (listing) => ({
  type: FETCH_LISTING_BY_ID_SUCCESS,
  payload: listing,
});

export const fetchListingByIdFailure = (error) => ({
  type: FETCH_LISTING_BY_ID_FAILURE,
  payload: error,
});

export const fetchAllListingsRequest = () => ({
  type: FETCH_ALL_LISTINGS_REQUEST,
});

export const fetchAllListingsSuccess = (listings) => ({
  type: FETCH_ALL_LISTINGS_SUCCESS,
  payload: listings,
});

export const fetchAllListingsFailure = (error) => ({
  type: FETCH_ALL_LISTINGS_FAILURE,
  payload: error,
});

//FETCH LISTING BY STATUS
export const fetchListingByStatusRequest = () => ({
  type: FETCH_LISTING_BY_STATUS_REQUEST,
});

export const fetchListingByStatusSuccess = (listing) => ({
  type: FETCH_LISTING_BY_STATUS_SUCCESS,
  payload: listing,
});

export const fetchListingByStatusFailure = (error) => ({
  type: FETCH_LISTING_BY_STATUS_FAILURE,
  payload: error,
});

//CREATE LISTING
export const createListingRequest = () => ({
  type: CREATE_LISTING_REQUEST,
});

export const createListingSuccess = (listing) => ({
  type: CREATE_LISTING_SUCCESS,
  payload: listing,
});

export const createListingFailure = (error) => ({
  type: CREATE_LISTING_FAILURE,
  payload: error,
});

//UPDATE LISTING
export const updateListingRequest = () => ({
  type: UPDATE_LISTING_REQUEST,
});

export const updateListingSuccess = (listing) => ({
  type: UPDATE_LISTING_SUCCESS,
  payload: listing,
});

export const updateListingFailure = (error) => ({
  type: UPDATE_LISTING_FAILURE,
  payload: error,
});

//DELETE LISTINGS
export const deleteListingRequest = () => ({
  type: DELETE_LISTING_REQUEST,
});

export const deleteListingSuccess = (listingId) => ({
  type: DELETE_LISTING_SUCCESS,
  payload: listingId,
});

export const deleteListingFailure = (error) => ({
  type: DELETE_LISTING_FAILURE,
  payload: error,
});

// Upload Image Actions
export const uploadImageRequest = () => ({
  type: UPLOAD_IMAGE_REQUEST,
});

export const uploadImageSuccess = (imageUrl) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: imageUrl,
});

export const uploadImageFailure = (error) => ({
  type: UPLOAD_IMAGE_FAILURE,
  payload: error,
});

export const downloadImageRequest = () => ({
  type: DOWNLOAD_IMAGE_REQUEST,
});

export const downloadImageSuccess = (imageUrl) => ({
  type: DOWNLOAD_IMAGE_SUCCESS,
  payload: imageUrl,
});

export const downloadImageFailure = (error) => ({
  type: DOWNLOAD_IMAGE_FAILURE,
  payload: error,
});

export const addLeadsRequest = () => ({
  type: ADD_LEADS_REQUEST,
});

export const addLeadsSuccess = (leads) => ({
  type: ADD_LEADS_SUCCESS,
  payload: leads,
});

export const addLeadsFailure = (error) => ({
  type: ADD_LEADS_FAILURE,
  payload: error,
});

export const fetchLeadsRequest = () => ({
  type: FETCH_LEADS_REQUEST,
});

export const fetchLeadsSuccess = (leads) => ({
  type: FETCH_LEADS_SUCCESS,
  payload: leads,
});

export const fetchLeadsFailure = (error) => ({
  type: FETCH_LEADS_FAILURE,
  payload: error,
});

export const fetchLeadByIdRequest = () => ({
  type: FETCH_LEAD_BY_ID_REQUEST,
});

export const fetchLeadByIdSuccess = (lead) => ({
  type: FETCH_LEAD_BY_ID_SUCCESS,
  payload: lead,
});

export const fetchLeadByIdFailure = (error) => ({
  type: FETCH_LEAD_BY_ID_FAILURE,
  payload: error,
});

export const updateLeadRequest = () => ({
  type: UPDATE_LEAD_REQUEST,
});

export const updateLeadSuccess = (lead) => ({
  type: UPDATE_LEAD_SUCCESS,
  payload: lead,
});

export const updateLeadFailure = (error) => ({
  type: UPDATE_LEAD_FAILURE,
  payload: error,
});

export const deleteLeadRequest = () => ({
  type: DELETE_LEAD_REQUEST,
});

export const deleteLeadSuccess = (leadId) => ({
  type: DELETE_LEAD_SUCCESS,
  payload: leadId,
});

export const deleteLeadFailure = (error) => ({
  type: DELETE_LEAD_FAILURE,
  payload: error,
});

export const createNewsRequest = () => ({
  type: CREATE_NEWS_REQUEST,
});

export const createNewsSuccess = (news) => ({
  type: CREATE_NEWS_SUCCESS,
  payload: news,
});

export const createNewsFailure = (error) => ({
  type: CREATE_NEWS_FAILURE,
  payload: error,
});

export const fetchNewsRequest = () => ({
  type: FETCH_NEWS_REQUEST,
});

export const fetchNewsSuccess = (news) => ({
  type: FETCH_NEWS_SUCCESS,
  payload: news,
});

export const fetchNewsFailure = (error) => ({
  type: FETCH_NEWS_FAILURE,
  payload: error,
});

export const updateNewsRequest = () => ({
  type: UPDATE_NEWS_REQUEST,
});

export const updateNewsSuccess = (news) => ({
  type: UPDATE_NEWS_SUCCESS,
  payload: news,
});

export const updateNewsFailure = (error) => ({
  type: UPDATE_NEWS_FAILURE,
  payload: error,
});

export const deleteNewsRequest = () => ({
  type: DELETE_NEWS_REQUEST,
});

export const deleteNewsSuccess = (newsId) => ({
  type: DELETE_NEWS_SUCCESS,
  payload: newsId,
});

export const deleteNewsFailure = (error) => ({
  type: DELETE_NEWS_FAILURE,
  payload: error,
});

export const fetchAgentRequest = () => ({
  type: FETCH_AGENT_REQUEST,
});

export const fetchAgentSuccess = (agents) => ({
  type: FETCH_AGENT_SUCCESS,
  payload: agents,
});

export const fetchAgentFailure = (error) => ({
  type: FETCH_AGENT_FAILURE,
  payload: error,
});

export const createAppointmentRequest = () => ({
  type: CREATE_APPOINTMENT_REQUEST,
});

export const createAppointmentSuccess = (appointment) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  payload: appointment,
});

export const createAppointmentFailure = (error) => ({
  type: CREATE_APPOINTMENT_FAILURE,
  payload: error,
});

export const fetchNewsByIdRequest = () => ({
  type: FETCH_NEWS_BY_ID_REQUEST,
});

export const fetchNewsByIdSuccess = (news) => ({
  type: FETCH_NEWS_BY_ID_SUCCESS,
  // payload: news,
});

export const fetchNewsByIdFailure = (error) => ({
  type: FETCH_NEWS_BY_ID_FAILURE,
  payload: error,
});

export const fetchAllLeadsRequest = () => ({
  type: FETCH_ALL_LEADS_REQUEST,
});

export const fetchAllLeadsSuccess = (leads) => ({
  type: FETCH_ALL_LEADS_SUCCESS,
  payload: leads,
});

export const fetchAllLeadsFailure = (error) => ({
  type: FETCH_ALL_LEADS_FAILURE,
  payload: error,
});

export const createShowcaseRequest = () => ({
  type: CREATE_SHOWCASE_REQUEST,
});

export const createShowcaseSuccess = (showcase) => ({
  type: CREATE_SHOWCASE_SUCCESS,
  payload: showcase,
});

export const createShowcaseFailure = (error) => ({
  type: CREATE_SHOWCASE_FAILURE,
  payload: error,
});

export const fetchShowcaseRequest = () => ({
  type: FETCH_SHOWCASE_REQUEST,
});

export const fetchShowcaseSuccess = (showcase) => ({
  type: FETCH_SHOWCASE_SUCCESS,
  payload: showcase,
});

export const fetchShowcaseFailure = (error) => ({
  type: FETCH_SHOWCASE_FAILURE,
  payload: error,
});

export const deleteShowcaseRequest = () => ({
  type: DELETE_SHOWCASE_REQUEST,
});

export const deleteShowcaseSuccess = (showcaseId) => ({
  type: DELETE_SHOWCASE_SUCCESS,
  payload: showcaseId,
});

export const deleteShowcaseFailure = (error) => ({
  type: DELETE_SHOWCASE_FAILURE,
  payload: error,
});

export const updateShowcaseRequest = () => ({
  type: UPDATE_SHOWCASE_REQUEST,
});

export const updateShowcaseSuccess = (showcase) => ({
  type: UPDATE_SHOWCASE_SUCCESS,
  payload: showcase,
});

export const updateShowcaseFailure = (error) => ({
  type: UPDATE_SHOWCASE_FAILURE,
  payload: error,
});

export const fetchIdxPropertiesRequest = () => ({
  type: FETCH_IDX_PROPERTIES_REQUEST,
});

export const fetchIdxPropertiesSuccess = (properties) => ({
  type: FETCH_IDX_PROPERTIES_SUCCESS,
  payload: properties,
});

export const fetchIdxPropertiesFailure = (error) => ({
  type: FETCH_IDX_PROPERTIES_FAILURE,
  payload: error,
});

export const fetchVowPropertiesRequest = () => ({
  type: FETCH_VOW_PROPERTIES_REQUEST,
});

export const fetchVowPropertiesSuccess = (properties) => ({
  type: FETCH_VOW_PROPERTIES_SUCCESS,
  payload: properties,
});

export const fetchVowPropertiesFailure = (error) => ({
  type: FETCH_VOW_PROPERTIES_FAILURE,
  payload: error,
});

export const fetchMlsMediaRequest = () => ({
  type: MLS_MEDIA_REQUEST,
});

export const fetchMlsMediaSuccess = (media) => ({
  type: MLS_MEDIA_SUCCESS,
  payload: media,
});

export const fetchMlsMediaFailure = (error) => ({
  type: MLS_MEDIA_FAILURE,
  payload: error,
});

export const fetchAllAgentRequest = () => ({
  type: FETCH_ALL_AGENT_REQUEST,
});

export const fetchAllAgentSuccess = (agents) => ({
  type: FETCH_ALL_AGENT_SUCCESS,
  payload: agents,
});

export const fetchAllAgentFailure = (error) => ({
  type: FETCH_ALL_AGENT_FAILURE,
  payload: error,
});

export const fetchAllShowcaseRequest = () => ({
  type: FETCH_ALL_SHOWCASE_REQUEST,
});

export const fetchAllShowcaseSuccess = (showcases) => ({
  type: FETCH_ALL_SHOWCASE_SUCCESS,
  payload: showcases,
});

export const fetchAllShowcaseFailure = (error) => ({
  type: FETCH_ALL_SHOWCASE_FAILURE,
  payload: error,
});

export const fetchAllNews = () => ({
  type: FETCH__ALL_NEWS_REQUEST,
});

export const fetchAllNewsSuccess = (news) => ({
  type: FETCH__ALL_NEWS_SUCCESS,
  payload: news,
});


export const fetchAllNewsFailure = (error) => ({
  type: FETCH__ALL_NEWS_FAILURE,
  payload: error,
});

export const fetchUserByIdRequest = () => ({
  type: FETCH_USER_BY_ID_REQUEST,
});

export const fetchUserByIdSuccess = (user) => ({
  type: FETCH_USER_BY_ID_SUCCESS,
  payload: user,
});

export const fetchUserByIdFailure = (error) => ({
  type: FETCH_USER_BY_ID_FAILURE,
  payload: error,
});

export const submitOnboarding = (data) => async (dispatch) => {
  try {
    const response = await AxiosInstance.put("/users/on-board", data);
    dispatch(onboardingSuccess(response.data));
    dispatch(getProfile());
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      "Failed to complete onboarding progress.";
    dispatch(onboardingFailure(errorMessage));
    throw error;
  }
};

export const fetchListings =
  (page = 1, userId) =>
  async (dispatch) => {
    dispatch(fetchListingsRequest());
    try {
      const response = await AxiosInstance.get(
        `/listings?page=${page}&userId=${userId}`
      ); // API endpoint to get listings
      dispatch(fetchListingsSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch listings.";
      dispatch(fetchListingsFailure(errorMessage));
    }
  };

export const fetchAllListings = (userId) => async (dispatch) => {
  dispatch(fetchAllListingsRequest());
  try {
    const response = await AxiosInstance.get(`/listings/all?userId=${userId}`); // API endpoint to get listings
    dispatch(fetchAllListingsSuccess(response.data));
    return response.data.rows;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch all listings.";
    dispatch(fetchAllListingsFailure(errorMessage));
  }
};
export const fetchListingsByStatus =
  (page = 1, status, userId) =>
  async (dispatch) => {
    dispatch(fetchListingByStatusRequest());
    try {
      const response = await AxiosInstance.get(
        `/listings?page=${page}&userId=${userId}`,
        {
          params: {
            query: JSON.stringify({ status }), // Pass the query object as a JSON string
          },
        }
      ); // API endpoint to get listings
      dispatch(fetchListingByStatusSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch listings.";
      dispatch(fetchListingByStatusFailure(errorMessage));
    }
  };

export const fetchListingById = (data) => async (dispatch) => {
  dispatch(fetchListingByIdRequest());
  try {
    const response = await AxiosInstance.get(`/listings/getById`, {
      params: { id: data.id, userId: data.userId },
    }); // API endpoint to get listings
    // console.log('Fetched listing:', response.data); // Log the data to see the structure
    dispatch(fetchListingByIdSuccess(response.data)); // Make sure the correct data is dispatched
    return response.data; // Return the data
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch listing by ID.";
    console.error("Error fetching listing by ID:", errorMessage); // Log the error message
    dispatch(fetchListingByIdFailure(errorMessage));
  }
};

export const fetchAllLeads = () => async (dispatch) => {
  dispatch(fetchAllLeadsRequest());
  try {
    const response = await AxiosInstance.get(`/leads/all`); // API endpoint to get listings
    dispatch(fetchAllLeadsSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch all leads.";
    dispatch(fetchAllLeadsFailure(errorMessage));
  }
};

export const createListingApi = (listingData) => async (dispatch) => {
  dispatch(createListingRequest());
  try {
    const response = await AxiosInstance.post("/listings/create", listingData);
    dispatch(createListingSuccess(response));
    return response;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to create listings.";
    dispatch(createListingFailure(errorMessage));
  }
};

export const updateListingApi =
  (listingId, listingData) => async (dispatch) => {
    dispatch(updateListingRequest());
    try {
      const response = await AxiosInstance.put(
        `/listings/update/${listingId}`,
        listingData
      );
      dispatch(updateListingSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update the listing.";
      dispatch(updateListingFailure(errorMessage));
      throw error;
    }
  };

export const deleteListingApi = (listingId) => async (dispatch) => {
  dispatch(deleteListingRequest());
  try {
    const response = await AxiosInstance.delete(`/listings/${listingId}`);
    dispatch(deleteListingSuccess(listingId));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to delete the listing.";
    dispatch(deleteListingFailure(errorMessage));
  }
};

export const uploadImageApi = (file) => async (dispatch) => {
  dispatch(uploadImageRequest());
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await AxiosInstance.post("/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    dispatch(uploadImageSuccess(response.data.url));
    return response.data.url;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to upload image.";
    dispatch(uploadImageFailure(errorMessage));
  }
};

export const downloadImageApi = (imageUrl) => async (dispatch) => {
  dispatch(downloadImageRequest());
  try {
    const response = await AxiosInstance.get(
      `/fileHandler/download?remoteFileName=${imageUrl}`
    );
    dispatch(downloadImageSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to download image.";
    dispatch(downloadImageFailure(errorMessage));
  }
};

export const addLeadsApi = (lead) => async (dispatch) => {
  dispatch(addLeadsRequest());
  try {
    const response = await AxiosInstance.post("/leads", lead);
    dispatch(addLeadsSuccess(response));
    return response;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to create leads.";
    dispatch(addLeadsFailure(errorMessage));
  }
};

export const fetchLeads =
  (page = 1) =>
  async (dispatch) => {
    dispatch(fetchLeadsRequest());
    try {
      const response = await AxiosInstance.get(`/leads?page=${page}`);
      dispatch(fetchLeadsSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch leads.";
      dispatch(fetchLeadsFailure(errorMessage));
    }
  };

export const fetchLeadById = (id) => async (dispatch) => {
  dispatch(fetchLeadByIdRequest());
  try {
    const response = await AxiosInstance.get(`/leads/${id}`);
    dispatch(fetchLeadByIdSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch lead by ID.";
    dispatch(fetchLeadByIdFailure(errorMessage));
  }
};

export const updateLeadApi = (leadId, leadData) => async (dispatch) => {
  dispatch(updateLeadRequest());
  try {
    const response = await AxiosInstance.put(`/leads/${leadId}`, leadData);
    dispatch(updateLeadSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to update the lead.";
    dispatch(updateLeadFailure(errorMessage));
    throw error;
  }
};

export const deleteLeadApi = (leadId) => async (dispatch) => {
  dispatch(deleteLeadRequest());
  try {
    const response = await AxiosInstance.delete(`/leads/${leadId}`);
    dispatch(deleteLeadSuccess(leadId));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to delete the lead.";
    dispatch(deleteLeadFailure(errorMessage));
  }
};

export const createNewsApi = (news) => async (dispatch) => {
  dispatch(createNewsRequest());
  try {
    const response = await AxiosInstance.post("/news", news);
    dispatch(createNewsSuccess(response));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to create news.";
    dispatch(createNewsFailure(errorMessage));
  }
};

export const fetchNews =
  (page = 1) =>
  async (dispatch) => {
    dispatch(fetchNewsRequest());
    try {
      const response = await AxiosInstance.get(`/news?page=${page}`);
      dispatch(fetchNewsSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch news.";
      dispatch(fetchNewsFailure(errorMessage));
    }
  };

export const fetchAllNewsApi = () => async (dispatch) => {
  dispatch(fetchAllNews());
  try {
    const response = await AxiosInstance.get(`/news/all`);
    dispatch(fetchAllNewsSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch news.";
    dispatch(fetchAllNewsFailure(errorMessage));
  }
};

export const updateNewsApi = (newsId, newsData) => async (dispatch) => {
  dispatch(updateNewsRequest());
  try {
    const response = await AxiosInstance.patch(`/news/${newsId}`, newsData);
    dispatch(updateNewsSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to update the news.";
    dispatch(updateNewsFailure(errorMessage));
    throw error;
  }
};

export const deleteNewsApi = (newsId) => async (dispatch) => {
  dispatch(deleteNewsRequest());
  try {
    const response = await AxiosInstance.delete(`/news/${newsId}`);
    dispatch(deleteNewsSuccess(newsId));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to delete the news.";
    dispatch(deleteNewsFailure(errorMessage));
  }
};

export const fetchAgent =
  (page = 1) =>
  async (dispatch) => {
    dispatch(fetchAgentRequest());
    try {
      const response = await AxiosInstance.get(`/users?page=${page}`);
      dispatch(fetchAgentSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch agents.";
      dispatch(fetchAgentFailure(errorMessage));
    }
  };
export const fetchAllAgent = () => async (dispatch) => {
  dispatch(fetchAllAgentRequest());
  try {
    const response = await AxiosInstance.get(`/users/all`);
    dispatch(fetchAllAgentSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch agents.";
    dispatch(fetchAllAgentFailure(errorMessage));
  }
};

export const createAppointmentApi = (appointment) => async (dispatch) => {
  dispatch(createAppointmentRequest());
  try {
    const response = await AxiosInstance.post("/appointments", appointment);
    dispatch(createAppointmentSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to create appointment.";
    dispatch(createAppointmentFailure(errorMessage));
  }
};

export const fetchNewsById = (id) => async (dispatch) => {
  dispatch(fetchNewsByIdRequest());
  try {
    const response = await AxiosInstance.get(`/news/${id}`);
    dispatch(fetchNewsByIdSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch news by ID.";
    dispatch(fetchNewsByIdFailure(errorMessage));
  }
};

export const createShowcaseApi = (showcase) => async (dispatch) => {
  dispatch(createShowcaseRequest());
  try {
    const response = await AxiosInstance.post("/showcase", showcase);
    dispatch(createShowcaseSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to create showcase.";
    dispatch(createShowcaseFailure(errorMessage));
  }
};

export const fetchShowcase =
  (page = 1) =>
  async (dispatch) => {
    dispatch(fetchShowcaseRequest());
    try {
      const response = await AxiosInstance.get(`/showcase?page=${page}`);
      dispatch(fetchShowcaseSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to fetch showcase.";
      dispatch(fetchShowcaseFailure(errorMessage));
    }
  };
export const fetchAllShowcase = () => async (dispatch) => {
  dispatch(fetchAllShowcaseRequest());
  try {
    const response = await AxiosInstance.get(`/showcase/all`);
    dispatch(fetchAllShowcaseSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch showcase.";
    dispatch(fetchAllShowcaseFailure(errorMessage));
  }
};

export const deleteShowcaseApi = (showcaseId) => async (dispatch) => {
  dispatch(deleteShowcaseRequest());
  try {
    const response = await AxiosInstance.delete(`/showcase/${showcaseId}`);
    dispatch(deleteShowcaseSuccess(showcaseId));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to delete the showcase.";
    dispatch(deleteShowcaseFailure(errorMessage));
  }
};

export const updateShowcaseApi =
  (showcaseId, showcaseData) => async (dispatch) => {
    dispatch(updateShowcaseRequest());
    try {
      const response = await AxiosInstance.patch(
        `/showcase/${showcaseId}`,
        showcaseData
      );
      dispatch(updateShowcaseSuccess(response.data));
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update the showcase.";
      dispatch(updateShowcaseFailure(errorMessage));
      throw error;
    }
  };

export const fetchIdxProperties = (type, value) => async (dispatch) => {
  dispatch(fetchIdxPropertiesRequest());
  try {
    const response = await AxiosInstance.get(
      `/mls/idx-property?fieldName=${type}&fieldValue=${value}`
    );
    dispatch(fetchIdxPropertiesSuccess(response.data));
    return response.data.value;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch idx properties.";
    dispatch(fetchIdxPropertiesFailure(errorMessage));
  }
};

export const fetchVowProperties = (type, value) => async (dispatch) => {
  dispatch(fetchVowPropertiesRequest());
  try {
    const response = await AxiosInstance.get(
      `/mls/vow-property?fieldName=${type}&fieldValue=${value}`
    );
    dispatch(fetchVowPropertiesSuccess(response.data));
    return response.data.value;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch vow properties.";
    dispatch(fetchVowPropertiesFailure(errorMessage));
  }
}

export const fetchMlsMedia = (resource, timestamp) => async (dispatch) => {
  dispatch(fetchMlsMediaRequest());
  try {
    const response = await AxiosInstance.get(
      `/mls/filtered-media?resourceName=${resource}&modificationTimestamp=${timestamp}`
    );
    dispatch(fetchMlsMediaSuccess(response.data));
    return response.data.value;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch mls media.";
    dispatch(fetchMlsMediaFailure(errorMessage));
  }
};

export const fetchUserById = (id) => async (dispatch) => {
  dispatch(fetchUserByIdRequest());
  try {
    const response = await AxiosInstance.get(`/users/${id}?id=${id}`);
    dispatch(fetchUserByIdSuccess(response.data));
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch user by ID.";
    dispatch(fetchUserByIdFailure(errorMessage));
  }
}


