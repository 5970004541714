import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { createNewsApi, downloadImageApi, uploadImageApi } from '../../modules/dashboard/actions'; // Assuming this is the upload action
import { toast } from 'react-toastify';
import { getDownloadUrl } from '../../config/config';
 // Assuming this is the action to submit the modalData

const AddNews = ({ isOpen, closeModel }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    heading: "",
    content: "",
    imageUrl: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setIsSubmitting(true);
    if (file) {
      try {
        // Upload the image and get the image URL
        const uploadResponse = await dispatch(uploadImageApi(file)); 
        const filePath = new URL(uploadResponse).pathname.substring(1);
        const fileName = uploadResponse.substring(
          uploadResponse.lastIndexOf("/") + 1
        );
        const downloadUrl = getDownloadUrl(fileName);
        if (filePath) {
          const response = await dispatch(downloadImageApi(filePath)); // Pass file path to API
          // const data = await response.json();
         // Set the downloaded image URL
          console.log(response.filePath);
        }
        const imageUrl = downloadUrl; // Assuming the API returns the URL in the payload
        setModalData({ ...modalData, imageUrl });
      } catch (uploadError) {
        setError("Failed to upload image");
      }
    }
    setIsSubmitting(false);
  };

  const handleFileClick = () => {
    if (!modalData.imageUrl) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setModalData({ ...modalData, imageUrl: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!modalData.heading || !modalData.content || !modalData.imageUrl) {
      setError("Please fill in all fields and upload an image");
      return;
    }
    setIsSubmitting(true);

    try {
      // Submit the form with modalData
      await dispatch(createNewsApi(modalData));
      closeModel(); // Close the modal after successful submission
      setModalData({ heading: "", content: "", imageUrl: "" }); // Clear the form
      toast.success("News is added", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
      )
      setModalData({ heading: "", content: "", imageUrl: "" });
    } catch (submitError) {
      setError("Failed to submit news");
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content p-4">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close mt-2 me-2"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              <h1 className="text-center mb-4">Add a News</h1>

              {error && <div className="text-danger mt-3 text-center">{error}</div>}

              <div className="modal-body text-black">
              <form onSubmit={handleSubmit}>
                  <div className="mb-3 ">
                    <label className="form-label"><strong>Upload Cover Image</strong></label>
                    <div className="border p-2 rounded-4 cursor-pointer text-center" onClick={handleFileClick}>
                      {!modalData.imageUrl ? (
                        <p className="text-muted mt-2">
                          Drag & Drop or <span className="text-primary">Choose file</span> to upload
                        </p>
                      ) : (
                        <div className="text-center">
                          <img 
                            src={modalData.imageUrl} 
                            alt="Uploaded Preview" 
                            className="img-fluid rounded-4 me-2" 
                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                          />
                          <button 
                            type="button" 
                            className="btn btn-danger mt-2" 
                            onClick={handleRemoveImage}
                          >
                            Remove Image
                          </button>
                        </div>
                      )}
                    </div>

                    <input
                      type="file"
                      name="imageUrl"
                      accept="image/*"
                      className="d-none"
                      ref={fileInputRef} 
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label><strong>Heading</strong></label>
                    <input
                      type="text"
                      name="heading"
                      placeholder="Heading"
                      className="form-control form-control-rounded"
                      value={modalData.heading}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label><strong>Description</strong></label>
                    <textarea
                      name="content"
                      placeholder="Description"
                      className="form-control rounded-4 mt-2"
                      rows="3"
                      value={modalData.content}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-pill"
                      type="submit"
                      disabled={isSubmitting}
                    >
                       {isSubmitting ? (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNews;
