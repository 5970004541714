import { Route, Routes } from "react-router-dom"

import Listings from "../modules/dashboard/views/listings";
import Preview from "../components/Listinngs/AddListing/Preview";

import { DashBoardPaths } from "./paths"
import Leads from "../modules/dashboard/views/leads";
import Marketing from "../modules/dashboard/views/marketing";
import News from "../modules/dashboard/views/news";
import Profile from "../modules/dashboard/views/Profile";
import Subscribers from "../modules/dashboard/views/subscribers";
import AgentSetting from "../modules/dashboard/views/agentSetting";
import SuperAdmin from "../modules/dashboard/views/super_admin";

const DashBoardRoutes = () => {
    return (
        <Routes>
            <Route path={DashBoardPaths.LISTINGS} element={<Listings/>}/>          
            <Route path={DashBoardPaths.LEADS} element={<Leads/>}/>          
            <Route path={DashBoardPaths.MARKETING} element={<Marketing/>}/>          
            <Route path={DashBoardPaths.NEWS} element={<News/>}/>          
            <Route path={DashBoardPaths.PROFILE} element={<Profile/>}/>          
            <Route path={DashBoardPaths.SUBSCRIBERS} element={<Subscribers/>}/>          
            <Route path={DashBoardPaths.AGENT_SETTING} element={<AgentSetting/>}/>          
            <Route path={DashBoardPaths.SUPER_ADMIN} element={<SuperAdmin/>}/>          
        </Routes>
    );
}

export default DashBoardRoutes;