import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getProfile, login, stripePaymentCheckoutApi } from "../actions";
import logo from "../../../assets/images/myRealPage.png";
import AuthLayout from "../../../components/AuthLayout";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader";
import { el } from "date-fns/locale";

const Login = () => {
  const profile = useSelector((state) => state.auth.profile);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Get the current URL from the browser
    // const token = localStorage.getItem("token");
    const urlParams = new URLSearchParams(window.location.search);
    // if (token) {
      const sessionId = urlParams.get("session_id");
      setSessionId(sessionId);
      
    // }
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchProfile = async () => {
      try {
        const response = await dispatch(getProfile());
        if (token &&  response.role === "superAdmin") {
          navigate("/dashboard/super-admin");
        } else if (token) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchProfile();
    
    
  }, [navigate,profile,dispatch]);

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (error) setError(""); // Clear error when typing
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email || !password) {
      return setError("Please fill out all fields.");
    }

    if (!validateEmail(formData.email)) {
      return setError("Please enter a valid email address.");
    }

    setLoading(true); // Set loading
    try {
      const response = await dispatch(login({ email, password }));
      const user = await dispatch(getProfile());
      console.log("user", user);
      console.log("response", response);
      if (
        response.status === 200 &&
        response.data.token &&
        user.role === "superAdmin"
      ) {
        navigate("/dashboard/super-admin");
      } else if (response.status === 200 && response.data.token) {
        navigate("/dashboard");
        const data = {
          sessionId: sessionId,
        };
        if (sessionId) dispatch(stripePaymentCheckoutApi(data));
      } else {
        setError("Invalid credientials");
      }
    } catch (error) {
      console.log("error", error);
      setError(error.response?.data?.message || "Login failed");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthLayout>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4">
          <Link to="./">
            <img src={logo} height="20" alt="" />
          </Link>
        </div>
        <h2 className="h1 text-center mb-3">
          <strong>Sign in to your account</strong>
        </h2>
        <form
          action="./"
          method="get"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {error && <div className="text-danger mt-3 text-center">{error}</div>}
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input
              type="email"
              className="form-control form-control-rounded"
              placeholder="Email address"
              autoComplete="off"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
          <div className="mb-2">
            <label className="form-label">Password</label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control form-control-rounded"
                placeholder="Password"
                autoComplete="off"
                name="password"
                value={formData.password}
                onChange={handleChange}
                disabled={loading}
              />
              <div
                className="position-absolute end-0 top-0 mt-2 me-2"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <IconEye color="gray" />
                ) : (
                  <IconEyeOff color="gray" />
                )}
              </div>
            </div>
          </div>
          <div className="mb-2 d-flex justify-content-between align-items-center">
            <label className="form-check m-0">
              <input
                type="checkbox"
                className="form-check-input"
                disabled={loading}
              />
              <span className="text-black">Remember me on this device</span>
            </label>
            <Link to="/auth/forgot-password">Forgot password?</Link>
          </div>
          <div className="form-footer">
            <button
              type="submit"
              className="btn btn-primary btn-pill w-100"
              disabled={loading}
            >
              {loading ? <Loader /> : "Sign in"}
            </button>
          </div>
        </form>

        <div className="text-center text-secondary mt-3">
          Don't you have an account?{" "}
          <Link to="/auth/select-package">Create an account</Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
