import React from 'react'

const SEO = ({ formData, handleChange, onSidebarClick }) => {
  return (
    <>
      <h2 className="text-primary">SEO</h2>

      <div className="mb-3">
        <label>
          SEO Title
        </label>
        
          <input
            type="text"
            name='title'
            defaultValue={formData.seo.title}
            onChange={handleChange}
            className="form-control rounded-4"
            placeholder="SEO Title"
            autoComplete="off"
          />
      </div>

      <div className="w-100 h-100">
        <div className="mb-3 w-100 h-100 d-flex flex-column justify-content-between">
          <label>
            SEO Description
          </label>
          <textarea
            name="description"
            placeholder="SEO Description"
            className="mt-2 form-control rounded-4"
            defaultValue={formData.seo.description}
            onChange={handleChange}
          />
        </div>
      </div>
      </>
  )
}

export default SEO