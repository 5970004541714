import {
  IconCake,
  IconCalendarMonth,
  IconEdit,
  IconMail,
  IconMapPin,
  IconPhone,
  IconUser,
  IconWorld,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import UpdateAgent from "./UpdateAgent";

const ViewAgent = ({ isOpen, closeModel, agent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);
  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="">
                <button
                  type="button"
                  className="btn-close mt-2 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
              </div>
              {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
              <div className="modal-body text-black">
                <div className="profile-card">
                  {!isEditing && (
                    <>
                      <div className="card-header mb-4 mt-4">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-lg bg-gray-500 rounded-4 me-3">
                            <span className="fs-3 fw-bold text-dark">
                              {agent.displayName && agent.displayName.charAt(0)}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <strong>
                              {agent.displayName && agent.displayName}{" "}
                            </strong>
                          </div>
                        </div>
                        <div className="card-actions ">
                          {/* <button
                            className="btn btn-primary btn-pill"
                            onClick={() => setIsEditing(true)}
                          >
                            <div className="d-flex align-items-center gap-1">
                              <div className="ms-1">Edit</div>
                              <IconEdit size={16} />
                            </div>
                          </button> */}
                        </div>
                      </div>
                      <div>
                        <p>Basic Information</p>
                        <div className="d-flex gap-2">
                          <IconUser size={16} />
                          <strong>
                            <p>
                            {agent.displayName && agent.displayName}
                            </p>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconPhone size={16} />
                          <strong>
                            <p>{agent.businessPhone && agent.businessPhone}</p>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconMail size={16} />
                          <strong>
                            <p>{agent.businessEmail && agent.businessEmail}</p>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconMapPin size={16} />
                          <strong>
                            <p>{agent.businessAddress && agent.businessAddress}</p>
                          </strong>
                        </div>
                        <div className="d-flex gap-2">
                          <IconCalendarMonth size={16} />
                          <strong>
                            <p>Joined: {new Date(agent.createdOn).toLocaleDateString()}</p>
                          </strong>
                        </div>
                        {/* <div className="d-flex gap-2">
                          <IconCake size={16} />
                          <strong>
                            <p>09.06.1997</p>
                          </strong>
                        </div> */}
                        <div className="d-flex gap-2">
                          <IconWorld size={16} />
                          <strong>
                            <p>www.chirag.com</p>
                          </strong>
                        </div>
                        <p>Socials</p>
                        <div className="d-flex gap-3 ">
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0796 3.54636H11.3329V1.42636C10.7261 1.36326 10.1164 1.3321 9.50625 1.33303C7.69292 1.33303 6.45292 2.43969 6.45292 4.46636V6.21303H4.40625V8.58636H6.45292V14.6664H8.90625V8.58636H10.9462L11.2529 6.21303H8.90625V4.69969C8.90625 3.99969 9.09292 3.54636 10.0796 3.54636Z"
                              fill="black"
                            />
                          </svg>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1087_34311)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.66602 2.00033C3.19326 2.00033 1.99935 3.19423 1.99935 4.66699V11.3337C1.99935 12.8064 3.19326 14.0003 4.66602 14.0003H11.3327C12.8054 14.0003 13.9993 12.8064 13.9993 11.3337V4.66699C13.9993 3.19423 12.8054 2.00033 11.3327 2.00033H4.66602ZM0.666016 4.66699C0.666016 2.45785 2.45688 0.666992 4.66602 0.666992H11.3327C13.5418 0.666992 15.3327 2.45785 15.3327 4.66699V11.3337C15.3327 13.5428 13.5418 15.3337 11.3327 15.3337H4.66602C2.45688 15.3337 0.666016 13.5428 0.666016 11.3337V4.66699Z"
                                fill="#222222"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.32202 5.99267C7.90589 5.93096 7.48091 6.00204 7.10751 6.19579C6.73411 6.38954 6.43131 6.69609 6.24218 7.07185C6.05305 7.44761 5.98722 7.87344 6.05405 8.28877C6.12088 8.7041 6.31697 9.08778 6.61444 9.38525C6.9119 9.68271 7.29558 9.8788 7.71091 9.94563C8.12624 10.0125 8.55207 9.94663 8.92783 9.7575C9.30359 9.56837 9.61015 9.26557 9.8039 8.89217C9.99765 8.51877 10.0687 8.09379 10.007 7.67767C9.94407 7.2532 9.74628 6.86024 9.44286 6.55682C9.13944 6.2534 8.74648 6.05561 8.32202 5.99267ZM6.49342 5.01229C7.11575 4.68937 7.82406 4.57091 8.51759 4.67375C9.22503 4.77866 9.87997 5.10831 10.3857 5.61401C10.8914 6.11971 11.221 6.77465 11.3259 7.48209C11.4288 8.17563 11.3103 8.88393 10.9874 9.50627C10.6645 10.1286 10.1536 10.6333 9.52728 10.9485C8.90102 11.2637 8.1913 11.3734 7.49908 11.262C6.80687 11.1506 6.16739 10.8238 5.67163 10.3281C5.17586 9.83229 4.84904 9.19282 4.73765 8.5006C4.62626 7.80838 4.73598 7.09867 5.0512 6.4724C5.36642 5.84613 5.87108 5.3352 6.49342 5.01229Z"
                                fill="#222222"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 4.33366C11 3.96547 11.2985 3.66699 11.6667 3.66699H11.6733C12.0415 3.66699 12.34 3.96547 12.34 4.33366C12.34 4.70185 12.0415 5.00033 11.6733 5.00033H11.6667C11.2985 5.00033 11 4.70185 11 4.33366Z"
                                fill="#222222"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1087_34311">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.33651 4.66628H3.31773C3.11332 4.67861 2.90856 4.64867 2.71624 4.57831C2.52392 4.50796 2.34817 4.39871 2.19996 4.25739C2.05176 4.11606 1.93428 3.9457 1.85486 3.75694C1.77545 3.56818 1.7358 3.36508 1.7384 3.16031C1.741 2.95554 1.78579 2.75351 1.86997 2.56682C1.95415 2.38014 2.07592 2.21282 2.22766 2.0753C2.37941 1.93779 2.55787 1.83304 2.75192 1.76759C2.94596 1.70214 3.15142 1.6774 3.35545 1.69491C3.5605 1.68006 3.7664 1.70779 3.96021 1.77637C4.15402 1.84494 4.33155 1.95287 4.48163 2.09337C4.63171 2.23387 4.7511 2.4039 4.83229 2.59277C4.91348 2.78164 4.95471 2.98527 4.9534 3.19085C4.95209 3.39643 4.90826 3.59951 4.82467 3.78733C4.74108 3.97515 4.61953 4.14365 4.46767 4.28222C4.31581 4.4208 4.13692 4.52645 3.94225 4.59255C3.74759 4.65864 3.54135 4.68375 3.33651 4.66628Z"
                              fill="#101418"
                            />
                            <path
                              d="M4.68034 6.66602H2.01367V14.666H4.68034V6.66602Z"
                              fill="#101418"
                            />
                            <path
                              d="M11.6797 6.66602C11.2303 6.66724 10.7871 6.77002 10.383 6.96667C9.97895 7.16332 9.62457 7.44875 9.34635 7.80164V6.66602H6.67969V14.666H9.34635V10.9993C9.34635 10.6457 9.48683 10.3066 9.73688 10.0565C9.98693 9.80649 10.3261 9.66602 10.6797 9.66602C11.0333 9.66602 11.3724 9.80649 11.6225 10.0565C11.8725 10.3066 12.013 10.6457 12.013 10.9993V14.666H14.6797V9.66602C14.6797 9.27205 14.6021 8.88194 14.4513 8.51797C14.3006 8.15399 14.0796 7.82327 13.801 7.5447C13.5224 7.26612 13.1917 7.04514 12.8277 6.89438C12.4638 6.74361 12.0737 6.66602 11.6797 6.66602Z"
                              fill="#101418"
                            />
                          </svg>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1087_34319)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.99935 2.00033C4.68564 2.00033 1.99935 4.68662 1.99935 8.00033C1.99935 11.314 4.68564 14.0003 7.99935 14.0003C11.3131 14.0003 13.9993 11.314 13.9993 8.00033C13.9993 4.68662 11.3131 2.00033 7.99935 2.00033ZM0.666016 8.00033C0.666016 3.95024 3.94926 0.666992 7.99935 0.666992C12.0494 0.666992 15.3327 3.95024 15.3327 8.00033C15.3327 12.0504 12.0494 15.3337 7.99935 15.3337C3.94926 15.3337 0.666016 12.0504 0.666016 8.00033Z"
                                fill="#1F1F1F"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0.666016 7.99967C0.666016 7.63148 0.964492 7.33301 1.33268 7.33301H14.666C15.0342 7.33301 15.3327 7.63148 15.3327 7.99967C15.3327 8.36786 15.0342 8.66634 14.666 8.66634H1.33268C0.964492 8.66634 0.666016 8.36786 0.666016 7.99967Z"
                                fill="#1F1F1F"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.99935 0.666992C8.18663 0.666992 8.36527 0.745766 8.49158 0.884043C10.2681 2.82893 11.2777 5.3529 11.3325 7.98644C11.3327 7.9957 11.3327 8.00495 11.3325 8.01421C11.2777 10.6478 10.2681 13.1717 8.49158 15.1166C8.36527 15.2549 8.18663 15.3337 7.99935 15.3337C7.81207 15.3337 7.63342 15.2549 7.50712 15.1166C5.73061 13.1717 4.72102 10.6478 4.66616 8.01421C4.66597 8.00495 4.66597 7.9957 4.66616 7.98644C4.72102 5.3529 5.73061 2.82893 7.50712 0.884043C7.63342 0.745766 7.81207 0.666992 7.99935 0.666992ZM5.9995 8.00033C6.04519 10.0502 6.75018 12.0238 7.99935 13.6331C9.24851 12.0238 9.9535 10.0502 9.99919 8.00033C9.9535 5.95043 9.24851 3.97689 7.99935 2.36752C6.75019 3.97689 6.04519 5.95043 5.9995 8.00033Z"
                                fill="#1F1F1F"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1087_34319">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="mt-3">
                    {isEditing && (
                      <UpdateAgent
                        agent={agent}
                        closeModel={closeModel}
                        setIsEditing={setIsEditing}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewAgent;
