import { IconArrowLeft, IconPlus, IconChevronDown } from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UpdateAgent = ({ agent, setIsEditing, closeModel }) => {
  const [error, setError] = useState("");
  const [additionalFields, setAdditionalFields] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const fileInputRef = useRef(null);
  const handleFileClick = () => {
    // Simulate a click on the hidden file input
    fileInputRef.current.click();
  };

  const handleAddField = (field) => {
    setAdditionalFields([...additionalFields, field]);
    setDropdownOpen(false);
  };

  const handleSubmit = () => {
    // Submit form logic
  };

  const renderAdditionalFields = () => {
    return additionalFields.map((field, index) => {
      return (
        <div key={index} className="mb-3">
          <label>
            <strong>{field}</strong>
          </label>
          <input
            type="text"
            name={field.toLowerCase().replace(/\s+/g, "")}
            placeholder={field}
            className="form-control form-control-rounded"
          />
        </div>
      );
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
        <div>
          <button
            type="button"
            className="btn btn-ghost border-0 btn-sm"
            onClick={() => setIsEditing(false)} // Go back to view mode
          >
            <IconArrowLeft size={16} />
            <span className="ms-1 text-black">Back</span>
          </button>
          <div className="d-flex align-items-center mt-2">
            <div className="avatar avatar-lg bg-gray-500 rounded-5 me-3">
              <span className="fs-3 fw-bold text-dark">
                {agent.firstName && agent.firstName.charAt(0)}
              </span>
            </div>
            <div className="d-flex flex-column gap-1">
              <strong>
                {agent.firstName && agent.firstName}{" "}
                {agent.lastName && agent.lastName}
              </strong>
            </div>
          </div>
        </div>
        <div className="mb-3 position-relative">
          <button
            type="button"
            className="btn-next"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <div className="mx-2 d-flex align-items-center">
              <IconPlus size={16} />
              <span className="ms-2">Add</span>
              <IconChevronDown className="ms-2" size={16} />
            </div>
          </button>
          {dropdownOpen && (
            <div
              className="position-absolute border bg-white rounded shadow-sm p-2"
              style={{ zIndex: 10, top: "100%", left: "0" }}
            >
              <ul className="list-unstyled m-0">
                {[
                  "Phone Number",
                  "Job",
                  "Email",
                  "Company",
                  "Website",
                  "Instagram Link",
                  "LinkedIn",
                  "Facebook Link",
                ].map((field) => (
                  <li
                    key={field}
                    className="p-2 cursor-pointer"
                    onClick={() => handleAddField(field)}
                  >
                    {field}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <form>
        {error && <div className="text-danger mt-3 text-center">{error}</div>}
        <div className="mb-3">
          <label>
            <strong>First Name</strong>
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="form-control form-control-rounded"
          />
        </div>
        <div className="mb-3">
          <label>
            <strong>Last Name</strong>
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="form-control form-control-rounded"
          />
        </div>
        <div className="mb-3">
          <label>
            <strong>Select Agent Type</strong>
          </label>
          <select className="form-select rounded-5" name="agentType">
            <option value="">Select Agent Type</option>
            <option value="solo">Solo</option>
            <option value="brokerage">Brokerage</option>
          </select>
        </div>

        <div className="mb-3">
          <label>
            <strong>Email</strong>
          </label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-control form-control-rounded"
          />
        </div>
        <div className="mb-3">
          <label>
            <strong>Phone Number</strong>
          </label>
          <PhoneInput
            international
            defaultCountry="US"
            className="form-control"
          />
        </div>

        <div className="mb-3">
          <label className="mb-2">
            <strong>Upload Your Logo</strong>
          </label>
          <div
            className="border p-2 rounded-4 cursor-pointer text-center"
            onClick={handleFileClick}
          >
            <p className="text-muted mt-2">
              Drag & Drop or <span className="text-primary">Choose file</span>{" "}
              to upload
            </p>
          </div>

          {/* Hidden file input */}
          <input
            type="file"
            name="image"
            accept="image/*"
            className="d-none"
            ref={fileInputRef} // Use ref for the file input
            //   onChange={handleFileChange}
          />
        </div>

        {/* Dynamic Additional Fields */}
        {renderAdditionalFields()}

        <div className="mt-4 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateAgent;
