import {
  IconChevronLeft,
  IconChevronRight,
  IconCirclePlus,
  IconTrash,
} from "@tabler/icons-react";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { skipOnboarding } from "../modules/auth/actions";
import { submitOnboarding } from "../modules/dashboard/actions"


const Onboarding = ({onClick}) => {
  const dispatch = useDispatch(); 
  const profile = useSelector((state) => state.auth.profile);
  const [error, setError] = useState("");
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [showOnboarding, setShowOnboarding] = useState(false);
  // const [showOnboardingWarning, setShowOnboardingWarning] = useState(false);

  // const handleCompleteProfileClick = () => {
  //   onClick();
  // };

  const [modalData, setModalData] = useState({
    userType: "",
    mlsNumber: "",
    displayName: "",
    businessEmail: "",
    businessPhone: "",
    businessAddress: "",
    name: "",
    phone: "",
    address: "",
    links: [{ value: "" }]
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email && emailRegex.test(email);
  };

  useEffect(() => {
    // const onboardingCompleted = localStorage.getItem("onboardingCompleted");
    
   
    if (profile.isSkipped === undefined) {
      setIsFirstModalOpen(true);
    } else {
      setIsFirstModalOpen(false);
    }
  
  }, [profile]);
  
  useEffect(() => {
    if (isFirstModalOpen || isSecondModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isFirstModalOpen, isSecondModalOpen]);

  const openFirstModal = () => {
    setIsFirstModalOpen(true);
    setIsSecondModalOpen(false);
  };

  const openSecondModal = async (e) => {
    e.preventDefault();
    if (modalData.userType === "solo" &&  !validateEmail(modalData.businessEmail)) {
      return setError("Please enter a valid email address.");
    }
    if (modalData.userType === "brokerage" && !validateEmail(modalData.businessEmail)) {
      return setError("Please enter a valid email address.");
    }
    setError(""); // Clear the error message
    setIsFirstModalOpen(false);
    setIsSecondModalOpen(true);
  };

  const closeModals = () => {
    setIsFirstModalOpen(false);
    setIsSecondModalOpen(false);
    // dispatch(skipOnboarding());
  };

  const handleSkip = () => {
    closeModals();
    dispatch(skipOnboarding());
  };

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
    if (error) setError("");
  };

  // Handle link change
  const handleLinkChange = (index, event) => {
    const newLinks = modalData.links.map((link, i) => {
      if (i === index) {
        return { value: event.target.value };
      }
      return link;
    });
    setModalData({ ...modalData, links: newLinks });
  };

  const handleAddLink = () => {
    setModalData({ ...modalData, links: [...modalData.links, { value: "" }] });
  };

  const handleRemoveLink = (index) => {
    setModalData({
      ...modalData,
      links: modalData.links.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      ...modalData,
      links: modalData.links.map(link => link.value)
    };
    setLoading(true); // Set loading 
    try {
      await dispatch(submitOnboarding(formattedData));
      closeModals();
      // localStorage.setItem("onboardingCompletedDone", "true");
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); 
    }
   
  };

  return (
    <div>
      {profile.isSkipped && (<button className="btn btn-outline-warning btn-sm" onClick={openFirstModal}>Click Here</button>)}
      {isFirstModalOpen  && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close mb-5 me-4"
                  onClick={closeModals}
                  aria-label="Close"
                ></button>
                <h5 className="modal-title mt-5 text-black">Add Your Details</h5>
              </div>
              {error && <div className="text-danger mt-3 text-center">{error}</div>}
              <div className="modal-body m-4 text-black">
                <form>
                <div className="mb-3">
                  <label>
                    <strong>Select Agent Type</strong>
                  </label>
                  <select
                    className="form-select rounded-5"
                    name="userType"
                    value={modalData.userType}
                    onChange={handleChange}
                  >
                    <option value="">Select Agent Type</option>
                    <option value="solo" className="form-select">
                      Solo
                    </option>
                    <option value="brokerage" className="form-select">
                      Brokerage
                    </option>
                  </select>
                </div>
                <div className="mb-3">
                  <label>
                    <strong>MLS Number</strong>
                  </label>
                  <input
                    type="text"
                    name="mlsNumber"
                    placeholder="MLS Number"
                    className="form-control form-control-rounded"
                    value={modalData.mlsNumber}
                    onChange={handleChange}
                  />
                </div>
                {modalData.userType === "solo" && (
                  <>
                    <div className="mb-3">
                      <label>
                        <strong>Display Name</strong>
                      </label>
                      <input
                        type="text"
                        name="displayName"
                        placeholder="Display Name"
                        className="form-control form-control-rounded"
                        value={modalData.displayName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Business Email</strong>
                      </label>
                      <input
                        type="text"
                        name="businessEmail"
                        placeholder="Business Email"
                        className="form-control form-control-rounded"
                        value={modalData.businessEmail}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Business Phone Number</strong>
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={modalData.businessPhone}
                        onChange={(value) => setModalData({ ...modalData, businessPhone: value })}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Business Address</strong>
                      </label>
                      <input
                        type="text"
                        name="businessAddress"
                        placeholder="Business Address"
                        className="form-control form-control-rounded"
                        value={modalData.businessAddress}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                {modalData.userType === "brokerage" && (
                  <>
                    <div className="mb-3">
                      <label>
                        <strong>Name</strong>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Display Name"
                        className="form-control form-control-rounded"
                        value={modalData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Email</strong>
                      </label>
                      <input
                        type="text"
                        name="businessEmail"
                        placeholder="Business Email"
                        className="form-control form-control-rounded"
                        value={modalData.businessEmail}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Phone Number</strong>
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={modalData.phone}
                        onChange={(value) => setModalData({ ...modalData, phone: value })}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Address</strong>
                      </label>
                      <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        className="form-control form-control-rounded"
                        value={modalData.address}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
                </form>
              </div>
              <div className="d-flex justify-content-end me-4 gap-3">
              {!profile.isSkipped ?
                <button
                  className="btn btn-outline-primary btn-pill"
                  onClick={handleSkip}
                >
                  SKIP
                </button>: ""
                 }
                <button className="btn-next" onClick={openSecondModal}>
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Next</div>
                    <IconChevronRight size={18} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSecondModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <span className="h5 mb-5 text-black">
                  <div
                    className="h5 d-flex align-items-center cursor-pointer text-black"
                    onClick={openFirstModal}
                  >
                    <IconChevronLeft />
                    Back
                  </div>
                </span>
                <button
                  type="button"
                  className="btn-close mb-5 me-4"
                  onClick={closeModals}
                  aria-label="Close"
                ></button>
                <h5 className="modal-title mt-5 text-black">Add Social Details</h5>
              </div>
              <div className="modal-body m-4">
                {modalData.links.map((link, index) => (
                  <div className="d-flex align-items-center gap-2 mb-3" key={index}>
                    <input
                      type="text"
                      className="form-control form-control-rounded no-margin-top"
                      value={link.value}
                      onChange={(e) => handleLinkChange(index, e)}
                      placeholder="Add Social Link"
                    />
                    {index > 0 && (
                      <button
                        className="btn-trash"
                        onClick={() => handleRemoveLink(index)}
                      >
                        <IconTrash size={18} />
                      </button>
                    )}
                  </div>
                ))}
                <div className="d-flex align-items-center gap-2">
                  <button className="btn-next" onClick={handleAddLink}>
                    <div className="mx-3 d-flex align-items-center">
                      <div className="ms-2">Add</div>
                      <IconCirclePlus size={17} />
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-flex justify-content-end me-4 gap-3">
              {!profile.isSkipped ?
                <button
                  className="btn btn-outline-primary btn-pill"
                  onClick={handleSkip}
                >
                  SKIP
                </button>: ""
                 }
                <button className="btn-next" onClick={handleSubmit} disabled={loading}>
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Save</div>
                    <IconChevronRight size={18} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding;
