import React, { useEffect, useState } from "react";
import news1 from "../../assets/images/news-1.png"; // Sample image, use dynamic URL if available
import { useDispatch, useSelector } from "react-redux";
import { downloadImageApi, fetchNews } from "../../modules/dashboard/actions";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { getDownloadUrl } from "../../config/config";

const NewsSection = () => {
  const dispatch = useDispatch();
  const { news, newsTotalPages } = useSelector((state) => state.dashboard);
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchNewsData = async () => {
      await dispatch(fetchNews(newsTotalPages));
    };

    fetchNewsData();
  }, [dispatch, newsTotalPages]);

  // Select the latest news item
  const latestNews = news.length > 0 ? news[news.length - 1] : null;

  // Fetch image for the latest news
  // useEffect(() => {
  //   if (latestNews && latestNews.imageUrl) {
  //     const fileName = latestNews.imageUrl.substring(
  //       latestNews.imageUrl.lastIndexOf("/") + 1
  //     ); // Extract file name from the image URL
  //     const filePath = new URL(latestNews.imageUrl).pathname.substring(1);
  //     // Fetch the image using the API
  //     const fetchImage = async () => {
  //       const response = await dispatch(downloadImageApi(filePath));
  //       if (response.filePath) {
  //         const downloadUrl = getDownloadUrl(fileName); // Use utility function
  //         setImageUrl(downloadUrl); // Set the downloaded image URL
  //       }
  //     };

  //     fetchImage();
  //   }
  // }, [dispatch, latestNews]);

  // Function to handle navigation to detailed news
  const handleCardClick = (newsId) => {
    window.open(`/latest-news/${newsId}`, "_blank"); // Open in a new tab
  };

  return (
    <div>
      {latestNews && (
        <div
          className="card rounded-4 my-2"
          style={{ cursor: "pointer" }}
          onClick={() => handleCardClick(latestNews.id)}
        >
          <div className="card-body">
            <div className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner rounded-4 w-100">
                <div className="carousel-item active">
                  <div className="">
                    <img
                      className="card-img-top rounded-4"
                      alt="news"
                      src={latestNews.imageUrl} // Replace with dynamic image if available in API
                    />
                    <div className="my-2">
                      <h2 className="description">{latestNews.heading}</h2>
                      <p className="text-secondary description">
                        {latestNews.content}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsSection;
