export const AuthPaths = {
    LOGIN: 'login',
    PACKAGE: 'select-package' ,
    SIGNUP1: 'signup-details' ,
    SIGNUP2: 'signup-payment' ,
    FORGOT_PASSWORD: 'forgot-password' ,
    EMAIL_VERIFICATION: 'email-verification' ,
    RESET_PASSWORD: 'reset-password' ,
}

export const PublicPaths = {
    HOME: '/',
    NOT_FOUND: '*',
    ABOUT_US: 'about-us',
    LISTING: 'listing/:id',
    MLS_LISTING: 'listingMLS/:id',
    LISTING_GENERATED: 'listing-code/:ids',
    MLS_LISTING_GENERATED: 'listing-code-mls/:ids',
    LATEST_NEWS: 'latest-news/:id',
    MAP_PREVIEW: 'list-idx-search',
    MLS_MAP_PREVIEW: 'list-idx-search-mls',
    SEARCH_PREVIEW: 'compact-search-form',
    MAP_SEARCH_PREVIEW: 'compact-search-form-mls',
}

export const DashBoardPaths = {
    LISTINGS: 'listings',
    LEADS: 'leads',
    MARKETING: 'marketing',
    NEWS: 'news',
    PROFILE: 'profile',
    SUBSCRIBERS: 'subscribers',
    AGENT_SETTING: 'agent-setting',
    SUPER_ADMIN: 'super-admin',
}
