import React, { useEffect, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png"; // Placeholder for default image
import "../../../assets/styles/smallListingCard.scss"; // Adding custom CSS file for styling
import { IconPlus } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";

const MLSShowcaseCard = ({ card, onClick, addCardIdToArray }) => {
  const dispatch = useDispatch();
  const [mlsLoading, setMLSLoading] = useState(false);
  const [MLSCardsImages, setMLSCardsImages] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "CAD"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "CAD"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "USD"; // US Dollar
    }
    // Add more rules as needed
    return "USD"; // Default to USD
  };

  useEffect(() => {
      const fetchImage = async () => {
        if (card?.MediaChangeTimestamp) {
          setLoading(true);
          try {
            const response = await dispatch(fetchMlsMedia("Property", card.MediaChangeTimestamp));
            console.log("Fetched images:", response);
    
            if (response && Array.isArray(response) && response.length > 0) {
              // Filter for valid media items
              const activeMedia = response.find(
                (item) =>
                  item?.MediaURL &&
                  item?.MediaStatus === "Active" &&
                  item?.MediaType === "image/jpeg"
              );
              setImageUrl(activeMedia?.MediaURL || listingImage);
            } else {
              setImageUrl(listingImage);
            }
          } catch (error) {
            console.error("Failed to fetch image:", error);
            setImageUrl(listingImage);
          } finally {
            setLoading(false);
          }
        } else {
          setImageUrl(listingImage);
        }
      };
    
      fetchImage();

      if (card?.UnparsedAddress) {
        const detectedCurrency = getCurrencyFromAddress(card.UnparsedAddress);
        setCurrency(detectedCurrency);
      }
    }, [card, dispatch]);

  return (
    // <div className="row gap-1">
    //   {cards.map((card, index) => (
        <div
          key={card.ListingKey}
          className="custom-card shadow-sm col-12 col-sm-6 col-md-4 col-lg-3 mx-auto m-1" // Responsive column classes
          // Event handling for clicking the card
        >
          {/* Image Section */}
          <div className="image-section cursor-pointer">
            <img
               src={imageUrl || listingImage}
               alt={card.ListingKey || "Default"}
               onError={(e) => {
                e.target.onerror = null; 
                e.target.src = listingImage; 
              }}
              className="property-image"
            />
          </div>

          {/* Details Section */}
          <div className="details-section">
            <div className="price-section">
              <div className="price-text">
                {currency}{" "}
                {card.ListPrice && Number(card.ListPrice).toLocaleString()}
              </div>
            </div>
            {/* {card.currency} */}

            <div className="title-section card-title">
              {card.PublicRemarks}
              <div onClick={() => addCardIdToArray(card.ListingKey,card)}>
                <IconPlus size={20} className="mb-1 cursor-pointer" />
              </div>
            </div>
          </div>
        </div>
    //   ))}
    // </div>
  );
};

export default MLSShowcaseCard;
