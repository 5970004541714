import React, { useState } from "react";
import logo from "../../../assets/images/myRealPage.png";
import { IconChevronRight, IconChevronLeft } from "@tabler/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/AuthLayout";
import { getCountryDataList } from "countries-list";

const SignupPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const previousFormData = location.state;
  const [formData, setFormData] = useState({
    email: "",
    cardNumber: "",
    cardDate: "",
    cvc: "",
    cardholderName: "",
    country: "",
  });

  const countries = getCountryDataList();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "cardNumber") {
      const formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{4})(?=\d)/g, "$1 ")
        .substring(0, 19);
      setFormData({ ...formData, cardNumber: formattedValue });
    } else if (name === "cardDate") {
      const formattedValue = value
        .replace(/\D/g, "")
        .replace(/(\d{2})(?=\d)/g, "$1/")
        .substring(0, 5);
      setFormData({ ...formData, cardDate: formattedValue });
    } else if (name === "cvc") {
      const formattedValue = value.replace(/\D/g, "").substring(0, 3);
      setFormData({ ...formData, cvc: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/auth/login");
  };

  return (
    <AuthLayout>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4">
          <img src={logo} style={{ maxHeight: "25px" }} alt="" />
        </div>
        <div className="text-center text-secondary mt-3">
          Do you have an account? <Link to="../login">Sign in</Link>
        </div>
        <div className="steps steps-counter steps-primary mb-5 mt-5">
          <p  className="step-item">
            Select Package
          </p>
          <p className="step-item">
            Signup with details
          </p>
          <span className="step-item active">
            Payment
          </span>
        </div>
        <form
          action="./"
          method="get"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <div className="mb-3">
            <label className="form-label">Card Information</label>
            <div className="">
              <div className="input-icon">
                <input
                  type="text"
                  name="cardNumber"
                  className="form-control round-top no-margin-top"
                  data-mask-visible="true"
                  placeholder="1234-1234-1234-1234"
                  autocomplete="off"
                  value={formData.cardNumber}
                  onChange={handleChange}
                />
                <span className="input-icon-addon border-0 me-3">
                  <span class="payment payment-provider-visa border-0"></span>
                  <span class="payment payment-provider-mastercard border-0"></span>
                </span>
              </div>
              <div className="d-flex">
                <input
                  type="text"
                  name="cardDate"
                  className="form-control round-bottom-left no-margin-top"
                  placeholder="MM/YY"
                  value={formData.cardDate}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="cvc"
                  className="form-control round-bottom-right no-margin-top"
                  placeholder="CVC"
                  value={formData.cvc}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-2">
            <label className="form-label">Cardholder Name</label>
            <div className="input-icon">
              <input
                type="text"
                className="form-control form-control-rounded"
                placeholder="Cardholder Name"
                autoComplete="off"
                name="cardholderName"
                value={formData.cardholderName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-2">
            <label className="form-label">Country</label>
            <select
              className="form-select rounded-5 text-secondary"
              name="country"
              value={formData.country}
              onChange={handleChange}
            >
              <option value="">Country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-footer">
            <div className="form-footer">
              <div className="btn-list justify-content-between">
                <Link
                  to="../signup-details"
                  className="btn btn-outline-primary btn-pill"
                >
                  <IconChevronLeft />
                  <div className="me-1">Back</div>
                </Link>
                <button type="submit" className="btn btn-primary btn-pill">
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Create</div>
                    <IconChevronRight />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </form>
        {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
      </div>
    </AuthLayout>
  );
};

export default SignupPayment;
