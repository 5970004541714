import React, { useEffect, useState } from "react";
import BasicSection from "../Preview/BasicSection";
import VideosSection from "../Preview/VideosSection";
import ImagesSection from "../Preview/ImageSection";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllListings,
  fetchListings,
} from "../../../modules/dashboard/actions";
import { useParams } from "react-router-dom";

const Preview = () => {
  // const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(isOpen);
  const param = useParams();
  const dispatch = useDispatch();
  // const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("basic");

  const { allListings, error } = useSelector((state) => state.dashboard);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    setUserId(userIdParam);
  }, []);

  useEffect(() => {
    // dispatch(fetchListingById(param.id));
    dispatch(fetchAllListings(userId));
  }, [dispatch, userId]);

  const renderContent = () => {
    switch (activeTab) {
      case "basic":
        return (
          <BasicSection selectedListing={param.id} listings={allListings} />
        );
      case "images":
        return (
          <ImagesSection selectedListing={param.id} listings={allListings} />
        );
      case "videos":
        return (
          <VideosSection selectedListing={param.id} listings={allListings} />
        );
      default:
        return (
          <BasicSection selectedListing={param.id} listings={allListings} />
        );
    }
  };
  return (
    <div className="bg-white">
      {/* {isPreviewModalOpen && ( */}

      <div className="container">
        <div className="d-flex align-items-center justify-content-center mx-auto gap-2">
          <div className="tab-navigation bg-white">
            <div
              className={`tab-item ${activeTab === "basic" ? "active" : ""}`}
              onClick={() => setActiveTab("basic")}
            >
              Basic
            </div>
            <div
              className={`tab-item ${activeTab === "images" ? "active" : ""}`}
              onClick={() => setActiveTab("images")}
            >
              Images
            </div>
            <div
              className={`tab-item ${activeTab === "videos" ? "active" : ""}`}
              onClick={() => setActiveTab("videos")}
            >
              Videos
            </div>
          </div>
        </div>
        {/* {error && (
                  <div className="text-danger mt-3 text-center">{error}</div>
                )} */}
        <div className=" text-black">
          <div>
            {/* Tab Navigation */}

            {/* Content Section */}
            <div className="">{renderContent()}</div>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};

export default Preview;
