import axios from 'axios';
import store from '../redux/store';
import { userLogout } from '../modules/auth/actions'; 
import { API_URL } from './config';

const AxiosInstance = axios.create({
  baseURL: API_URL,
});

const urlsToIgnoreAuth = ['/users/login', '/users/signup', '/users/refresh-token'];

AxiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('token');

    if (!config.headers.Authorization && !urlsToIgnoreAuth.some(ignoreUrl => config.url.includes(ignoreUrl))) {
      config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : undefined;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && error.response?.data?.message === 'Invalid Token') {
      localStorage.removeItem('token');
      store.dispatch(userLogout());
      window.location.href = '/auth/login';
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
