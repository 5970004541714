import { getCountryDataList } from "countries-list";
import { streetTypes } from "../streetTypes";
import { canadaProvincesAndTerritories } from "../CandaProvinces";
import React, { useEffect, useState } from "react";
import { Country, State } from "country-state-city";

const Address = ({ formData, handleChange, onSidebarClick }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    // Load countries on mount
    setCountries(Country.getAllCountries());
    
    // Preload states if country is already set
    if (formData.address.country) {
      const selectedStates = State.getStatesOfCountry(formData.address.country);
      setStates(selectedStates);
    }
  }, [formData.address.country]);

  const handleCountryChange = (e) => {
    const countryCode = e.target.value;
    handleChange(e);

    // Load states based on the selected country
    const selectedStates = State.getStatesOfCountry(countryCode);
    setStates(selectedStates);
  };
 
  return (
    <>
      <h2 className="text-primary">Address</h2>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
        <div className="mb-3 w-100">
          <label>
           Number
          </label>
          <input
            type="number"
            name="number"
            placeholder="Number"
            className="form-control form-control-rounded"
            defaultValue={formData.address.number}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 w-100">
          <label>
           Street Number
          </label>
          <input
            type="number"
            name="streetNumber"
            placeholder="Street Number"
            className="form-control form-control-rounded"
            defaultValue={formData.address.streetNumber}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 w-100">
          <label>
           Street Name
          </label>
          <input
            type="text"
            name="streetName"
            placeholder="Street Name"
            className="form-control form-control-rounded"
            defaultValue={formData.address.streetName}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row  justify-content-between gap-3">
        <div className="mb-3 w-100">
          <label>
           Street Type
          </label>
          <select
            className="form-select rounded-5"
            name="streetType"
            defaultValue={formData.address.streetType}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select Street Type
            </option>
            {/* <option value="condo" className="form-select">
              Condo
            </option>
            <option value="other" className="form-select">
              Other
            </option> */}
            {streetTypes.map((streetType) => (
              <option key={streetType} value={streetType} className="form-select">
                {streetType}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>
           Street Direction
          </label>
          <select
            className="form-select rounded-5"
            name="streetDirection"
            defaultValue={formData.address.streetDirection}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select Street Direction
            </option>
            <option value="East" className="form-select">
              East
            </option>
            <option value="North" className="form-select">
              North
            </option>
            <option value="Northest" className="form-select">
              Northest
            </option>
            <option value="Northwest" className="form-select">
              Northwest
            </option>
            <option value="South" className="form-select">
              South
            </option>
            <option value="Southeast" className="form-select">
              Southeast
            </option>
            <option value="Southwest" className="form-select">
              Southwest
            </option>
            <option value="West" className="form-select">
              West
            </option>
          </select>
        </div>
      </div>
      <div className="w-100 h-100">
        <div className="mb-3 w-100 h-100 d-flex flex-column justify-content-between">
          <label>
           Full Address
          </label>
          <textarea
            name="fullAddress"
            placeholder="Full Address"
            className="mt-2 form-control rounded-4"
            defaultValue={formData.address.fullAddress}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="mb-3">
        <label>
         Town / City
        </label>
        <input
          type="text"
          name="city"
          className="form-control form-control-rounded"
          placeholder="Town / City"
          defaultValue={formData.address.city}
          onChange={handleChange}
        />
      </div>
      <div className="d-flex flex-column flex-md-row  justify-content-between gap-3">
        <div className="mb-3 w-100">
          <label>
           Country
          </label>
          <select
            className="form-select rounded-5 text-secondary"
            name="country"
            value={formData.address.country || ""}
            onChange={handleCountryChange}
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>Province / State</label>
          <select
            className="form-select rounded-5"
            name="state"
            value={formData.address.state || ""}
            onChange={handleChange}
            // disabled={states.length === 0}
          >
            <option value="">Select Province / State</option>
            {states.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>
           Postal Code
          </label>
          <input
            type="number"
            name="postalCode"
            className="form-control form-control-rounded"
            placeholder="Postal Code"
            defaultValue={formData.address.postalCode}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default Address;
