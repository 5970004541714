import { getCountryDataList } from "countries-list";
import React, { useCallback, useEffect, useState } from "react";

const MLSCompactSearchForm = ({
  exportedListings,
  onFilter,
  onCustomFilterChange,
}) => {
  const [formData, setFormData] = useState({
    // Initialize form data
    location: "",
    minPrice: "",
    maxPrice: "",
    currency: "",
    propertyType: "",
    propertyCategory: "",
    status: "",
    year: "",
    approxArea: "",
    areaType: "SQ FT",
    buildingInfo: {
      bedroom: 0,
      bathroom: 0,
    },
    // Add customFilter or any other fields as needed
    customFilter: "",
  });
  const [customFilter, setCustomFilter] = useState("");

  const handleChangeCustome = (event) => {
    const { name, value } = event.target;
    if (name === "customFilter") {
      setCustomFilter(value);
    }
    // Handle other form inputs if needed
  };

  useEffect(() => {
    if (onCustomFilterChange) {
      onCustomFilterChange(customFilter);
    }
  }, [customFilter, onCustomFilterChange]);

  const [bedValue, setBedValue] = useState(0);
  const [bathValue, setBathValue] = useState(0);
  const [yearError, setYearError] = useState("");
  const countries = getCountryDataList();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const currentYear = new Date().getFullYear();

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Handle nested fields
    if (name === "bedroom" || name === "bathroom") {
      setFormData((prevData) => ({
        ...prevData,
        buildingInfo: {
          ...prevData.buildingInfo,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle bedroom and bathroom value changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      buildingInfo: {
        ...prevData.buildingInfo,
        bedroom: bedValue,
        bathroom: bathValue,
      },
    }));
  }, [bedValue, bathValue]);

  const validateYear = (value) => {
    const year = parseInt(value, 10);
    if (year <= currentYear) {
      setYearError("");
      setFormData((prevData) => ({
        ...prevData,
        year: value,
      }));
    } else {
      setYearError(`Year must not be greater than ${currentYear}.`);
      setFormData((prevData) => ({
        ...prevData,
        year: value,
      }));
    }
  };

  // Handle country change to update both currency and selected country code
  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (!countryCode) {
      setSelectedCurrency("");
      setSelectedCountryCode("");
      setFormData((prevData) => ({
        ...prevData,
        currency: "", // Clear currency to show all listings
      }));
    } else if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);
      setFormData((prevData) => ({
        ...prevData,
        currency: singleCurrency,
      }));
    }
  };

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency)
      ? country.currency[0]
      : country.currency;
  };

  // Set country based on initial formData.currency
  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);

  // Filtering Listings
  const [filteredListingIds, setFilteredListingIds] = useState([]);
  console.log(filteredListingIds);

  const filterListings = useCallback(() => {
    const minPrice = Number(formData.minPrice);
    const maxPrice = Number(formData.maxPrice);
    const year = Number(formData.year);

    const filtered = exportedListings.filter((listing) => {
      const listingPrice = listing.ListPrice;

      return (
        // Check if listing price is within the specified range
        listing.UnparsedAddress.toLowerCase().includes(
          formData.location.toLowerCase()
        ) &&
        (!formData.minPrice || listingPrice >= minPrice) &&
        (!formData.maxPrice || listingPrice <= maxPrice) &&
        listing.BedroomsTotal >= bedValue &&
        listing.BathroomsTotalInteger >= bathValue &&
        (listing.TaxYear <= year || !formData.year) &&
        (listing.PropertyType === formData.propertyType ||
          !formData.propertyType) &&
        (listing.TransactionType === formData.propertyCategory ||
          !formData.propertyCategory) &&
        (listing.MlsStatus === formData.status || !formData.status) &&
        // Add condition for customFilter if applicable
        (listing.customField === formData.customFilter ||
          !formData.customFilter)
      );
    });

    setFilteredListingIds(filtered.map((listing) => listing.id));

    // Pass the filtered IDs and form data up to the parent
    if (onFilter) {
      onFilter(
        filtered.map((listing) => listing.id),
        formData
      );
    }
  }, [exportedListings, formData, bedValue, bathValue, onFilter]);

  useEffect(() => {
    filterListings();
  }, [exportedListings, formData, bedValue, bathValue, filterListings]);

  const incrementBed = () => {
    setBedValue((prevValue) => prevValue + 1);
  };

  const decrementBed = () => {
    setBedValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const incrementBath = () => {
    setBathValue((prevValue) => prevValue + 1);
  };

  const decrementBath = () => {
    setBathValue((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const memoizedHandleChange = useCallback(
    (event) => {
      handleChange(event);
    },
    [handleChange]
  );

  // useEffect(() => {
  //   // Only update if the value is different to avoid unnecessary renders
  //   if (formData.buildingInfo.bedroom !== bedValue) {
  //     memoizedHandleChange({ target: { name: "bedroom", value: bedValue } });
  //   }
  // }, [bedValue, memoizedHandleChange, formData.buildingInfo.bedroom]);

  // useEffect(() => {
  //   // Only update if the value is different to avoid unnecessary renders
  //   if (formData.buildingInfo.bathroom !== bathValue) {
  //     memoizedHandleChange({ target: { name: "bathroom", value: bathValue } });
  //   }
  // }, [bathValue, memoizedHandleChange, formData.buildingInfo.bathroom]);

  // price

  // Set country based on initial formData.currency
  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);
  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Pre Selected Location</label>
          <input
            type="text"
            name="location"
            placeholder="Enter Location"
            className="form-control form-control-rounded"
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3 w-100">
          <label>Price</label>
          <div className="d-flex align-items-center">
            <input
              type="number"
              name="minPrice"
              placeholder="Min Price"
              className="form-control round-left"
              value={formData.minPrice}
              onChange={handleChange}
            />
            <input
              type="number"
              name="maxPrice"
              placeholder="Max Price"
              className="form-control"
              value={formData.maxPrice}
              onChange={handleChange}
            />
            {selectedCurrency && (
              <div className="form-control border-end mt-2 w-25 bg-gray-500">
                {selectedCurrency}
              </div>
            )}
            <select
              className="form-select text-secondary"
              name="country"
              value={selectedCountryCode}
              onChange={(e) => handleCountryChange(e.target.value)}
              style={styles.select}
            >
              <option value="">Currency</option>{" "}
              {/* Option to clear the currency filter */}
              {Object.keys(countries).map((code) => (
                <option key={code} value={code}>
                  {countries[code].name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Property Type</label>
          <select
            className="form-select rounded-5"
            name="propertyType"
            // defaultValue={formData.propertyType}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              All
            </option>
            <option value="Commercial" className="form-select">
              Commercial
            </option>
            <option value="Residential Condo & Other" className="form-select">
              Condo
            </option>
            <option value="Residential Freehold" className="form-select">
              Freehold
            </option>
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>Property Category</label>
          <select
            className="form-select rounded-5"
            name="propertyCategory"
            // defaultValue={formData.propertyCategory}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              All
            </option>
            <option value="For Sale" className="form-select">
              Sale
            </option>
            <option value="For Lease" className="form-select">
              Lease
            </option>
          </select>
        </div>

        <div className="mb-3 w-100">
          <label>Status</label>
          <select
            className="form-select rounded-5"
            name="status"
            // defaultValue={formData.status}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              All
            </option>
            <option value="active" className="form-select">
              Active
            </option>
            <option value="New" className="form-select">
              New
            </option>
            <option value="Price Change" className="form-select">
              Price Change
            </option>
            <option value="cancelled" className="form-select">
              Cancelled
            </option>
            <option value="deleted" className="form-select">
              Deleted
            </option>
            <option value="expired" className="form-select">
              Expired
            </option>
            <option value="on hold" className="form-select">
              On Hold
            </option>
            <option value="pending" className="form-select">
              Pending
            </option>
            <option value="sold" className="form-select">
              Sold
            </option>
            <option value="terminated" className="form-select">
              Terminated
            </option>
            <option value="unknown" className="form-select">
              Unknown
            </option>
            <option value="wait approval" className="form-select">
              Wait Approval
            </option>
          </select>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Year Built</label>
          <input
            type="number"
            name="year"
            placeholder="Year Built"
            className="form-control form-control-rounded"
            // defaultValue={formData.buildingInfo.year}
            onChange={(e) => validateYear(e.target.value)}
          />
          {yearError && <small className="text-danger">{yearError}</small>}
        </div>
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2"> Bedrooms</label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBed}
                disabled={bedValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bedValue}
                name="bedroom"
                onChange={(e) => {
                  const newValue = Math.max(0, parseInt(e.target.value, 10));
                  bedValue(isNaN(newValue) ? 0 : newValue);
                }}
                style={styles.input}
              />
              <button style={styles.button} onClick={incrementBed}>
                +
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3 w-100">
          <div style={styles.container}>
            <label className="mb-2"> Bathrooms</label>
            <div style={styles.inputContainer}>
              <button
                style={styles.button}
                onClick={decrementBath}
                disabled={bathValue === 0}
              >
                -
              </button>
              <input
                type="text"
                value={bathValue}
                name="bathroom"
                onChange={(e) => {
                  const newValue = Math.max(0, parseInt(e.target.value, 10));
                  bathValue(isNaN(newValue) ? 0 : newValue);
                }}
                style={styles.input}
              />
              <button style={styles.button} onClick={incrementBath}>
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
        <div className="mb-3 w-100">
          <label>Custom Search</label>
          <select
            className="form-select rounded-5"
            name="customFilter"
            onChange={handleChangeCustome}
          >
            <option value="" className="form-select">
              Select Option
            </option>
            <option value="all" className="form-select">
              All
            </option>
            <option value="default" className="form-select">
              Default
            </option>
            {/* Add more options as needed */}
          </select>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default MLSCompactSearchForm;
