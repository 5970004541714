import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import news1 from "../../assets/images/news-1.png";
import { downloadImageApi, fetchNewsById } from "../../modules/dashboard/actions";
import { getDownloadUrl  } from "../../config/config";
import Loader from "../Loader";
const LatestNews = () => {
  const { id } = useParams(); // Get the news ID from the route parameters
  const dispatch = useDispatch();
  const [newsDetail, setNewsDetail] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchNewsDetail = async () => {
        const newsData = await dispatch(fetchNewsById(id)); // Fetch news details by ID
        setNewsDetail(newsData);

        // Extract the file path from the full URL
      // const fullImageUrl = newsData.imageUrl; // Assuming imageUrl is part of the newsData
      // const filePath = new URL(fullImageUrl).pathname.substring(1); // Extract path without leading "/"
      // const fileName = fullImageUrl.substring(fullImageUrl.lastIndexOf('/') + 1);
      // if (fileName) {
      //   const downloadUrl = getDownloadUrl(fileName); // Use utility function
      //   setImageUrl(downloadUrl); // Set the constructed URL
      //   console.log(downloadUrl)
      // }
      // console.log(fileName)
      // console.log(fullImageUrl)
      // console.log(filePath);
      // console.log(filePath);	
      // if (filePath) {
      //   const response = await dispatch(downloadImageApi(filePath)); // Pass file path to API
      //   // const data = await response.json();
      //  // Set the downloaded image URL
      //   console.log(response.filePath);
      // }

    };
    // console.log(imageUrl)
    fetchNewsDetail();

  }, [dispatch, id, imageUrl]);
  return (
    <div className="container my-5">
      {newsDetail ? (
        <>
          <h1>{newsDetail.heading}</h1>
          <img
            src={newsDetail.imageUrl}
            alt="News"
            className="img-fluid my-3"
          />
          <p>{newsDetail.content}</p>
        </>
      ) : (
        <Loader/>
      )}
    </div>
  );
};

export default LatestNews;
