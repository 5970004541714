import { IconEdit } from '@tabler/icons-react';
import React from 'react';


const NewsCard = ({ imageSrc, title, description, onClick }) => {
  return (
    <div className="news-card">
      <div className="news-card-image">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="news-card-content">
        <h3 className="news-card-title">{title}</h3>
        <p className="news-card-description">{description}</p>
      </div>
      <div onClick={onClick} className='text-primary cursor-pointer'>
        <IconEdit/>
      </div>
    </div>
  );
};

export default NewsCard;
