import React, { useEffect, useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import "../../../assets/styles/listingSet.scss";
import { IconX } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";
import MLSListingSetCard from "./MLSListingSetCard";

const MlsListingSet = ({
  cards,
  sort,
  filter,
  selectedSort,
  selectedFilter,
  removeCard,
  selectedFilterOption,
  setfilterOptions,
}) => {
  const dispatch = useDispatch();
  const [mlsLoading, setMLSLoading] = useState(false);
  const [MLSCardsImages, setMLSCardsImages] = useState({});

  

  return (
    <div className="listing-page-container">
      {/* Listing Group Controls */}
      <div className="listing-group-controls">
        <h2 className="text-primary">Added Listings</h2>
        <div className="control-row">
          {/* <div className="drag-icon"><</div> */}
          <select
            className="form-select rounded-5 me-2"
            value={selectedFilter}
            onChange={filter}
            // disabled={!isDropdownEnabled}
          >
            <option value="">Status: All</option>
            <option value="New">Status: New</option>
            <option value="Price Change">Status: Price Change</option>
            {/* <option value="on hold">Status: On hold</option>
            <option value="pending">Status: Pending</option> */}
            {/* <option value="deleted">Deleted Listings</option>
            <option value="terminated">Terminated Listings</option>
            <option value="expired">Ex Listings</option> */}
          </select>
          <select
            className="form-select rounded-5 me-2"
            value={selectedSort}
            onChange={sort}
          >
            <option value="My Listings">Sort: All Listings</option>
            <option value="Price (Low to High)">
              Sort: Price (Low to High)
            </option>
            <option value="Price (High to Low)">
              Sort: Price (High to Low)
            </option>
            <option value="Listed Date (Recent First)">
              Sort: Listed Date (Recent First)
            </option>
            <option value="Listed Date (Oldest First)">
              Sort: Listed Date (Oldest First)
            </option>
          </select>

          {/* <select className="select-input">
            <option>All Listings</option>
            <option>Featured Listings</option>
          </select> */}
          {/* <div className="toggle-container">
            <label className="switch">
              <input
                type="checkbox"
                checked={isDropdownEnabled}
                onChange={() => setIsDropdownEnabled(!isDropdownEnabled)}
              />
              <span className="slider round"></span>
            </label>
          </div> */}
        </div>
        <select
          className="form-select rounded-5 w-50"
          value={selectedFilterOption}
          onChange={setfilterOptions}
        >
          <option value="Default">Filter Setting: Default</option>
          <option value="Top">Filter Setting: Top</option>
        </select>
      </div>

      {/* Property Listing */}
      <div className="property-listing">
        {cards.length === 0 ? (
          <div className="no-listing-message">
            Select listing by clicking the plus button
          </div>
        ) : (
          cards.map((card) => (
            <MLSListingSetCard card={card} removeCard={removeCard}/>
          ))
        )}
      </div>
    </div>
  );
};

export default MlsListingSet;
