export const streetTypes = [
    "Alley",
    "Arcade",
    "Avenue",
    "Bay",
    "Bend",
    "Boulevard",
    "Bypass",
    "Causeway",
    "Center",
    "Circle",
    "Circuit",
    "Close",
    "Concourse",
    "Corner",
    "Cove",
    "Court",
    "Crescent",
    "Crest",
    "Crossing",
    "Crossroad",
    "Curve",
    "Drive",
    "Esplanade",
    "Expressway",
    "Fork",
    "Freeway",
    "Garden",
    "Gardens",
    "Gate",
    "Glen",
    "Green",
    "Grove",
    "Heights",
    "Highway",
    "Hill",
    "Junction",
    "Key",
    "Knoll",
    "Lane",
    "Loop",
    "Mall",
    "Mews",
    "Motorway",
    "Mount",
    "Parade",
    "Park",
    "Parkway",
    "Pass",
    "Passage",
    "Path",
    "Pike",
    "Place",
    "Plaza",
    "Point",
    "Promenade",
    "Quay",
    "Ridge",
    "Road",
    "Row",
    "Square",
    "Street",
    "Terrace",
    "Thoroughfare",
    "Trace",
    "Trail",
    "Turnpike",
    "Underpass",
    "Vale",
    "Viaduct",
    "View",
    "Vista",
    "Walk",
    "Way",
    "Wharf",
    "Wynd"
  ];
  