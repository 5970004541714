import React, { useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import "../../../assets/styles/listingSet.scss";
import { IconX } from "@tabler/icons-react";

const ShowcaseListingSet = ({ cards, removeCard}) => {

  const truncate = (input, maxLength) => {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + "...";
    }
    return input;
  };
  return (
    <div className="listing-page-container">
      {/* Listing Group Controls */}
     

      {/* Property Listing */}
      <div className="property-listing">
      {cards.length === 0 ? (
          <div className="no-listing-message">
            Select listing by clicking the plus button
          </div>
        ) : (
          cards.map((card) => (
            <div key={card.id} className="property-list-item">
              <div className="d-flex gap-2 align-items-center w-100">
                <img
                  src={
                    card.images && card.images[0] && card.images[0].imageUrl
                      ? card.images[0].imageUrl
                      : listingImage
                  }
                  alt={card.description}
                  className="property-thumb"
                />
                <div className="property-info">
                  <div className="price h4">
                    {card.currency} {card.price}
                  </div>
                  <div className="description">{truncate(`${card.title}`, 30)}</div>
                </div>
              </div>
              <div className="property-actions cursor-pointer rounded-5 bg-gray-900 p-1" onClick={() => removeCard(card.id)}>
                <IconX/>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ShowcaseListingSet;
