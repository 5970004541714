import React, { useState } from "react";
import listingImage from "../../../assets/images/login-cover.png";
import "../../../assets/styles/listingSet.scss";
import { IconX } from "@tabler/icons-react";
import MLSListingSetCard from "../ListingCodeGenerator/MLSListingSetCard";

const MLSShowcaseListingSet = ({ cards, removeCard}) => {

    // const truncate = (input, maxLength) => {
    //     if (input.length > maxLength) {
    //       return input.substring(0, maxLength) + "...";
    //     }
    //     return input;
    //   };

      console.log("cards", cards);
  return (
    <div className="listing-page-container">
      {/* Listing Group Controls */}
     

      {/* Property Listing */}
      <div className="property-listing">
      {cards.length === 0 ? (
          <div className="no-listing-message">
            Select listing by clicking the plus button
          </div>
        ) : (
          cards.map((card) => (
            <MLSListingSetCard card={card} removeCard={removeCard}/>
          ))
        )}
      </div>
    </div>
  );
};

export default MLSShowcaseListingSet;
