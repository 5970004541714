import {
  IconCheck,
  IconCirclePlus,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";

const Features = ({ formData, setFormData }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [featureData, setFeatureData] = useState({
    name: "",
    description: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const toggleForm = () => {
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    setFeatureData({
      name: "",
      description: "",
    });
    setEditingIndex(null);
  };

  const updateFeature = (index, updatedFeature) => {
    const updatedFeatures = [...formData.features];
    updatedFeatures[index] = updatedFeature;
    setFormData({
      ...formData,
      features: updatedFeatures,
    });
  };

  const deleteFeature = (index) => {
    const updatedFeatures = formData.features.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      features: updatedFeatures,
    });
  };

  const addFeature = (feature) => {
    setFormData({
      ...formData,
      features: [...formData.features, feature],
    });
  };

  const handleFeatureInputChange = (e) => {
    const { name, value } = e.target;
    setFeatureData({ ...featureData, [name]: value });
  };

  const handleAddFeature = () => {
    if (editingIndex !== null) {
      updateFeature(editingIndex, featureData);
    } else {
      addFeature(featureData);
    }
    closeForm();
  };

  const handleEditFeature = (index) => {
    const featureToEdit = formData.features[index];
    setFeatureData(featureToEdit);
    setEditingIndex(index);
    toggleForm();
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Special Features</h2>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={toggleForm}
            disabled={isFormOpen}
          >
            Add
            <IconCirclePlus className="ms-1" />
          </button>
        </div>
      </div>
      <div className="video-player mt-3">
        {isFormOpen && (
          <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
            <div className="flex-grow-1">
              <div className="mb-2">
                <label className="form-label">
                  Feature Name 
                </label>
                {/* <select
                  className="form-select rounded-5"
                  name="name"
                  value={featureData.name}
                  onChange={handleFeatureInputChange}
                >
                  <option value="" className="form-select">
                    Select Feature
                  </option>
                  <option value="Feature" className="form-select">
                    Feature Name
                  </option>
                  <option value="other" className="form-select">
                    Other
                  </option>
                </select> */}
                <input
                  type="text"
                  name="name"
                  placeholder="Feature Name"
                  className="form-control rounded-4"
                  value={featureData.name}
                  onChange={handleFeatureInputChange}
                />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={featureData.description}
                  className="form-control rounded-4"
                  onChange={handleFeatureInputChange}
                />
              </div>
            </div>
            <div className="d-flex flex-md-column flex-row justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={closeForm}
                style={{ width: "40px", height: "40px" }}
              >
                <IconX size={17} />
              </button>
              <button
                type="button"
                className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleAddFeature}
                style={{ width: "40px", height: "40px" }}
              >
                <IconCheck size={17} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        {formData.features.length > 0
          ? formData.features.map((feature, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <IconGripVertical />
                  </div>
                  <div>
                    <p className="h3">{feature.name}</p>
                    <p className="mb-0">{feature.description}</p>
                  </div>
                </div>
                <div className="d-flex flex-md-column flex-row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => handleEditFeature(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconPencilMinus size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => deleteFeature(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconTrash size={17} />
                  </button>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

export default Features;
