import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';

import AuthRoutes from './routes/AuthRoutes';
import PublicRoutes from './routes/PublicRoutes';
import DashboardRootLayout from './components/DashboardRootLayout';
import Dashboard from './modules/dashboard/views'
import DashBoardRoutes from './routes/DashboardRoutes';

const App = () => {
  return (
    <>
    <ToastContainer />
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/*" element={<PublicRoutes />} />
      <Route path="/dashboard" element={<DashboardRootLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="/dashboard/*" element={<DashBoardRoutes />}/>
      </Route>
    </Routes>
    </>
  );
};

export default App;