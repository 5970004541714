import React, { useState } from "react";
import logo from "../../../assets/images/myRealPage.png";
import {
  IconEye,
  IconEyeOff,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../components/AuthLayout";
import { signup, stripePaymentApi } from "../actions";
import { useDispatch } from "react-redux";
import { Bounce, toast } from "react-toastify";

const SignupDetails = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    retypePassword: "",
  });
  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setRetypeShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRetypePasswordVisibility = () => {
    setRetypeShowPassword(!showRetypePassword);
  };

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password || !formData.retypePassword) {
      setError("Please fill out all fields.");
      return;
    }
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address.");
      return;
    }
    if (formData.password !== formData.retypePassword) {
      setError("Passwords do not match.");
      return;
    }
    if (!validatePassword(formData.password)) {
      setError(
        "Password must be at least 6 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character."
      );
      return;
    }

    setError(""); // Clear any previous errors
    setIsSubmitting(true);
    try {
      const response = await dispatch(signup(formData));
      // console.log("response", response.response.data.message);
      const user = response.userId;
      const data = {
        plan:"pro",
      }
      const paymentResponse = await dispatch(stripePaymentApi(data))
      if (response.userId) {
        toast.success("Account is created", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        if(paymentResponse)  window.location.href = paymentResponse.sessionUrl;
        setFormData({
          email: "",
          password: "",
          retypePassword: "",
        });
        setIsSubmitting(false);
      } else {
        // setError(response.message);
        toast.error(response.error.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (err) {
      // setError(err.message);
      toast.error("Email is already in use", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <AuthLayout>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4">
          <img src={logo} style={{ maxHeight: "25px" }} alt="" />
        </div>
        <div className="text-center text-secondary mt-3">
          Do you have an account? <Link to="../login">Sign in</Link>
        </div>
        <div className="steps steps-counter steps-primary mb-5 mt-5">
          <span className="step-item">Select Package</span>
          <span href="#" className="step-item active">
            Signup with details
          </span>
          <span href="#" className="step-item">
            Payment
          </span>
        </div>
        <form
          action="./"
          method="get"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          {error && <div className="text-danger mt-3 text-center">{error}</div>}
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control form-control-rounded"
              placeholder="Email"
              autoComplete="off"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-2">
            <label className="form-label">Password</label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control form-control-rounded"
                placeholder="Password"
                autoComplete="off"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <div
                className="position-absolute end-0 top-0 mt-2 me-2"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? (
                  <IconEye color="gray" />
                ) : (
                  <IconEyeOff color="gray" />
                )}
              </div>
            </div>
          </div>
          <div className="mb-6">
            <label className="form-label">Retype Password</label>
            <div className="input-icon">
              <input
                type={showRetypePassword ? "text" : "password"}
                className="form-control form-control-rounded"
                placeholder="Retype Password"
                autoComplete="off"
                name="retypePassword"
                value={formData.retypePassword}
                onChange={handleChange}
              />
              <div
                className="position-absolute end-0 top-0 mt-2 me-2"
                onClick={toggleRetypePasswordVisibility}
                style={{ cursor: "pointer" }}
              >
                {showRetypePassword ? (
                  <IconEye color="gray" />
                ) : (
                  <IconEyeOff color="gray" />
                )}
              </div>
            </div>
          </div>

          <div className="form-footer">
            <div className="btn-list justify-content-between">
              <Link
                to="../select-package"
                className="btn btn-outline-primary btn-pill"
              >
                <IconChevronLeft />
                <div className="me-1">Back</div>
              </Link>
              <button
                type="submit"
                className="btn btn-primary btn-pill"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <div className="mx-3 d-flex align-items-center">
                    <div className="ms-2">Next</div>
                    <IconChevronRight />
                  </div>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default SignupDetails;
