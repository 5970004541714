import { IconChevronLeft } from "@tabler/icons-react";
import React, { useState, useRef } from "react";
import AuthLayout from "../../../components/AuthLayout";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { emailVerification } from "../actions";
import { useDispatch } from "react-redux";

const EmailVerification = () => {
  const [error, setError] = useState(null);
  const [code, setCode] = useState(Array(6).fill(""));

  const navigate = useNavigate();
  const location = useLocation();
  const inputRefs = useRef([]);
  const enteredEmail = location.state;
  const dispatch = useDispatch();

  const handleKeyDown = (e) => {
    if (
      !/^[0-9]{1}$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Tab" &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    const index = inputRefs.current.indexOf(e.target);

    if (e.key === "Delete" || e.key === "Backspace") {
      e.preventDefault();
      setCode((prevOtp) => [
        ...prevOtp.slice(0, index),
        "",
        ...prevOtp.slice(index + 1),
      ]);
      if (index > 0 && e.key === "Backspace") {
        inputRefs.current[index - 1].focus();
      } else if (index < code.length - 1 && e.key === "Delete") {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleChange = (e) => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target);
    if (target.value) {
      setCode((prevOtp) => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);
      if (index < code.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleFocus = (e) => {
    e.target.select();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text");
    if (!new RegExp(`^[0-9]{${code.length}}$`).test(text)) {
      return;
    }
    const digits = text.split("");
    setCode(digits);
    inputRefs.current[digits.length - 1].focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!code[0] || !code[1] || !code[2] || !code[3] || !code[4] || !code[5]) {
      return setError("Need 6 digit code.");
    }
    try {
      await dispatch(
        emailVerification({
          email: enteredEmail,
          otp: code.join(""),
          verifyMode: 1,
        })
      );
      navigate("../reset-password", { state: enteredEmail });
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  console.log(code);

  return (
    <AuthLayout>
      <Link to="../forgot-password" className="back-button m-3 h5 mb-4">
        <div className="h5 d-flex align-items-center">
          <IconChevronLeft />
          Back
        </div>
      </Link>
      <div className="container container-tight my-5 px-5">
        <div className="text-center mb-4 mt-4">
          <h2 className="h1 text-center mb-3">
            <strong>Check Your Email</strong>
          </h2>
        </div>
        <p className="text-center mb-3">
          Enter the 6-digit code has been sent to your email.
        </p>
        <div className="d-flex text-center justify-content-center align-items-center gap-4">
          <h3>{enteredEmail}</h3>{" "}
          <p>
            <Link to="../forgot-password">Edit</Link>
          </p>
        </div>
       
          {error && <div className="text-danger mt-3 text-center">{error}</div>}
      
        <form
          action="./"
          method="get"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="mb-3 text-center">
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onPaste={handlePaste}
                ref={(el) => (inputRefs.current[index] = el)}
                className="form-control form-control-rounded mx-1 text-center my-2"
                style={{ width: "54px", display: "inline-block" }}
              />
            ))}
          </div>
          <p className="text-center">
            Didn't receive code?{" "}
            <span className="cursor-pointer">
             <strong><p className="text-dark">Resend</p></strong> 
            </span>
          </p>
          {/* <p className="text-center">
            Would you like us to send a new one?{" "}
            <span>
              <a href="#">Resend</a>
            </span>
          </p> */}

          <div className="form-footer mt-8">
            <button type="submit" className="btn btn-primary btn-pill w-100">
              Submit
            </button>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default EmailVerification;
