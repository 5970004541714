import { IconCheck, IconClipboard, IconLink, IconPencilMinus, IconTrash, IconX } from "@tabler/icons-react";
import React, { useState } from "react";

const ExternalLinks = ({ formData, setFormData }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [links, setLinks] = useState({
    name: "",
    link: "",
  });

  const toggleForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setLinks({
      name: "",
      link: "",
    });
  };

  //  Update the external link in the formData object
  // const updateLink = (updatedLink) => {
  //   setFormData({
  //     ...formData,
  //     externalLinks: updatedLink,
  //   });
  // };

  //  Delete the external link by setting it to null or an empty object
  const deleteLink = () => {
    setFormData({
      ...formData,
      externalLinks: null,
    });
  };

  //  Add the external link to the formData object
  const addLink = (link) => {
    setFormData({
      ...formData,
      externalLinks: link,
    });
  };

  const handleLinkInputChange = (e) => {
    const { name, value } = e.target;
    setLinks({ ...links, [name]: value });
  };

  const handleAddLink = () => {
    addLink(links);
    closeForm();
  };

  const handleEditLink = () => {
    setLinks(formData.externalLinks || { name: "" , link: ""});
    toggleForm();
  };

  return (
    <>
      <div className="">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="text-primary">External Links</h2>
          </div>
        </div>
        <div className="mb-3">
          <label>
            Video Link
          </label>
          <div className="d-flex w-100 align-items-center gap-2">
            <div className="input-icon mb-3 w-100">
              <input
                type="text"
                name="link"
                placeholder="Paste Link Here"
                className="form-control form-control-rounded"
                value={links.link}
                onChange={handleLinkInputChange}
                disabled={isFormOpen}
              />
              <span className="input-icon-addon">
                <IconClipboard />
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-pill"
              onClick={toggleForm}
              disabled={isFormOpen || !links.link}
            >
              Add
            </button>
          </div>
        </div>
        <div className="video-player mt-3">
          {isFormOpen && (
            <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
              <div className="mb-2">
                <IconLink />
              </div>
              <div className="flex-grow-1">
                <div className="mb-2">
                  <label className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={links.name}
                    className="form-control rounded-4"
                    onChange={handleLinkInputChange}
                  />
                  <p className="mt-3">{links.link}</p>
                </div>
              </div>
              <div className="d-flex flex-md-column flex-row justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={closeForm}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconX size={17} />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={handleAddLink}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconCheck size={17} />
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          {formData.externalLinks && formData.externalLinks.link ? (
            <div className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4">
              <div className="d-flex align-items-center gap-2">
                <div>
                  <IconLink />
                </div>
                <div>
                  <p className="h3">{formData.externalLinks.name}</p>
                  <p className="mb-0">{formData.externalLinks.link}</p>
                </div>
              </div>
              <div className="d-flex flex-md-column flex-row justify-content-between">
                <button
                  type="button"
                  className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                  onClick={handleEditLink}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconPencilMinus size={17} />
                </button>
                <button
                  type="button"
                  className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                  onClick={deleteLink}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconTrash size={17} />
                </button>
              </div>
            </div>
          ) : (
            ""
            // <p>No external link available. Please add one.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ExternalLinks;
