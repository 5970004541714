import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Onboarding from './Onboarding';

const WarningMessage = ({ onClick }) => {
    const [isOnboardingCompleted, setIsOnboardingCompleted] = useState(true);
    const profile = useSelector((state) => state.auth.profile);

    // const toggleSidebar = () => {
    //     setIsOnboardingCompleted(!isOnboardingCompleted);
    useEffect(() => {
    if (!profile.isSkipped) {
        setIsOnboardingCompleted(false);
      } else {
        setIsOnboardingCompleted(true);
      }
    }, [profile]);
  return (
    <div>{profile.isSkipped ? (
        <div className="alert alert-warning bg-white  mx-3 mt-3 no-margin-bottom">
          <div className="d-flex align-items-center">
            <>
              {" "}
              <div className="alert-icon text-warning">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-alert-circle"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="12" y1="8" x2="12" y2="12" />
                  <line x1="12" y1="16" x2="12.01" y2="16" />
                </svg>
              </div>
              <div className="ms-3">
                <div className="alert-title text-warning">
                  Complete Your Profile
                </div>
                <div className="text-muted">
                  Your profile is incomplete. Please update your profile
                  information to enhance your experience.
                </div>
                <div className="mt-2">
                {/* <button className="btn btn-outline-warning btn-sm" onClick={onClick}>Click Here</button> */}
                  <Onboarding onClick={onClick}/>
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <Onboarding/>
      )}</div>
  )
}

export default WarningMessage