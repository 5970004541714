import React, { useEffect, useState } from "react";
import BasicSection from "./Preview/BasicSection";
import ImagesSection from "./Preview/ImageSection";
import VideosSection from "./Preview/VideosSection";
import { useSelector } from "react-redux";
import MlsBasic from "./Mls/MlsBasic";
import MlsImages from "./Mls/MlsImages";
import MlsVideo from "./Mls/MlsVideo";

const MLSListingPreview = ({
  isOpen,
  closeModal,
  selectedListing,
  listings,
}) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(isOpen);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("basic");
  const profile = useSelector((state) => state.auth.profile);



  console.log(selectedListing);
  console.log(listings);

  useEffect(() => {
    setIsPreviewModalOpen(isOpen);
    if (isPreviewModalOpen) {
      document.body.classList.add("modal-open");
      // document.getElementById("blur-background").classList.add("blurred");
    } else {
      document.body.classList.remove("modal-open");
      // document.getElementById("blur-background").classList.remove("blurred");
    }
  }, [isOpen, isPreviewModalOpen]);

  const renderContent = () => {
    switch (activeTab) {
      case "basic":
        return (
          <MlsBasic
            selectedListing={selectedListing}
            listings={listings}
            user={profile.id}
            // page={page}
          />
        );
      case "images":
        return (
          <MlsImages
            selectedListing={selectedListing}
            listings={listings}
            user={profile.id}
            // page={page}
          />
        );
      case "videos":
        return (
          <MlsVideo
            selectedListing={selectedListing}
            listings={listings}
            userId={profile.id}
            // page={page}
          />
        );
      default:
        return (
          <BasicSection
            selectedListing={selectedListing}
            listings={listings}
            user={profile.id}
            // page={page}
          />
        );
    }
  };
  return (
    <div className="">
      {isPreviewModalOpen && (
        
        <div className="modal fade show d-block">
          <div
            className="modal-dialog custom-modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close mb-5 mt-2 me-4"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
                {/* <div className="d-flex align-items-center justify-content-center mx-auto gap-2">
                  <div className="tab-navigation">
                    <div
                      className={`tab-item ${
                        activeTab === "basic" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("basic")}
                    >
                      Basic
                    </div>
                    <div
                      className={`tab-item ${
                        activeTab === "images" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("images")}
                    >
                      Images
                    </div>
                    <div
                      className={`tab-item ${
                        activeTab === "videos" ? "active" : ""
                      }`}
                      onClick={() => setActiveTab("videos")}
                    >
                      Videos
                    </div>
                  </div>
                </div> */}
              </div>
              {error && (
                <div className="text-danger mt-3 text-center">{error}</div>
              )}
              <div className="modal-body-listing text-black">
                <div>
                  {/* Tab Navigation */}

                  {/* Content Section */}
                  <div className="tab-content">{renderContent()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MLSListingPreview;
