import { IconCalendar } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLeads,
  fetchAllListings,
  fetchLeads,
  fetchListings,
} from "../../modules/dashboard/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { eachDayOfInterval, format, isWithinInterval, subDays, subWeeks } from "date-fns";

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Helper function to group and count listings by date
const groupListingsByDate = (listings, startDate, endDate) => {
  // Generate all dates between startDate and endDate
  const allDates = eachDayOfInterval({ start: startDate, end: endDate }).map((date) =>
    date.toLocaleDateString()
  );

  // Create an object to store the count of listings for each date
  const listingsCountByDate = allDates.reduce((acc, date) => {
    acc[date] = 0; // Initialize every date with a count of 0
    return acc;
  }, {});

  // Filter listings within the date range and count them by date
  listings.forEach((listing) => {
    if (!listing.createdDate) return;
    const createdDate = new Date(listing.createdDate).toLocaleDateString();
    if (listingsCountByDate[createdDate] !== undefined) {
      listingsCountByDate[createdDate] += 1;
    }
  });

  // Return the dates and counts for the chart
  const dates = Object.keys(listingsCountByDate);
  const counts = Object.values(listingsCountByDate);

  return { dates, counts };
};


// Helper function to count listings by propertyType
const countListingsByPropertyType = (listings) => {
  const propertyTypeCounts = {
    commercial: 0,
    condo: 0,
    freehold: 0,
  };

  listings.forEach((listing) => {
    if (listing.propertyType in propertyTypeCounts) {
      propertyTypeCounts[listing.propertyType] += 1;
    }
  });

  return propertyTypeCounts;
};

// Helper function to group and count listings by date
const groupListingsByDateForLast7Days = (listings) => {
  const today = new Date();
  const last7Days = eachDayOfInterval({
    start: subDays(today, 6), // 7 days including today
    end: today,
  }).map((date) => format(date, "yyyy-MM-dd"));

  // Initialize count for each of the last 7 days
  const listingsCountByDate = last7Days.reduce((acc, date) => {
    acc[date] = 0;
    return acc;
  }, {});

  // Count listings created on each of the last 7 days
  listings.forEach((listing) => {
    if (!listing.createdDate) return;
    const createdDate = format(new Date(listing.createdDate), "yyyy-MM-dd");
    if (listingsCountByDate[createdDate] !== undefined) {
      listingsCountByDate[createdDate] += 1;
    }
  });

  return {
    dates: Object.keys(listingsCountByDate),
    counts: Object.values(listingsCountByDate),
  };
};

// Dummy data for charts
// const trafficData = {
//   labels: [
//     "May 18",
//     "May 20",
//     "May 22",
//     "May 24",
//     "May 26",
//     "May 28",
//     "May 30",
//     "Jun 1",
//     "Jun 3",
//     "Jun 5",
//     "Jun 7",
//     "Jun 9",
//   ],
//   datasets: [
//     {
//       label: "Listing Traffic",
//       data: [5, 15, 8, 20, 25, 18, 12, 15, 10, 18, 5, 7],
//       backgroundColor: "rgba(54, 162, 235, 0.5)",
//       borderColor: "rgba(54, 162, 235, 1)",
//       borderWidth: 2,
//       fill: true,
//     },
//   ],
// };

// const dailyActivityData = {
//   labels: ["04", "05", "06", "07", "08", "09"],
//   datasets: [
//     {
//       label: "Daily Activity",
//       data: [12, 9, 7, 10, 14, 5],
//       backgroundColor: "rgba(54, 162, 235, 1)",
//       borderWidth: 1,
//     },
//   ],
// };

// const topListingsData = {
//   labels: ["Property 01", "Property 02", "Property 03", "Property 04"],
//   datasets: [
//     {
//       data: [300, 50, 100, 200],
//       backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0"],
//     },
//   ],
// };

// Chart options for responsiveness and layout
const chartOptions = {
  responsive: true,
  maintainAspectRatio: false, // Allow the chart to adjust its height based on its container
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: false,
    },
  },
};

const ListingAnalytics = () => {
  const profile = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();
  const { total, allListings, leadsTotal,leadsTotalPages,leadsCurrentPage,leads } = useSelector((state) => state.dashboard);
  // State to manage date range (initialize start date 2 weeks ago and end date as today)
  const [startDate, setStartDate] = useState(subWeeks(new Date(), 2)); // 2 weeks ago
  const [endDate, setEndDate] = useState(new Date()); // Today
  const [showDatePicker, setShowDatePicker] = useState(false); // Controls visibility of DatePicker
  const [potentialLeadsCount, setPotentialLeadsCount] = useState(0);

  const [trafficData, setTrafficData] = useState({
    labels: [],
    datasets: [
      {
        label: "Listing Traffic",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 2,
        fill: true,
      },
    ],
  });

  // State for doughnut chart data
  const [topListingsData, setTopListingsData] = useState({
    labels: ["Commercial", "Condo", "Freehold"],
    datasets: [
      {
        label: "Property Types",
        data: [0, 0, 0], // Initial values (to be updated dynamically)
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  });

  // State for bar chart (daily activity for the last 7 days)
  const [dailyActivityData, setDailyActivityData] = useState({
    labels: [], // Dates for the last 7 days
    datasets: [
      {
        label: "Listings Created",
        data: [], // Listing counts for each day
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  });

  // const fetchAllLeads = async () => {
  //   let allLeads = [];
  //   for (let page = 1; page <= leadsTotalPages; page++) {
  //     await dispatch(fetchLeads(page)); // Fetch leads for each page
  //     allLeads = allLeads.concat(leads); // Accumulate leads from each page
  //   }
  //   return allLeads;
  // };

  const countPotentialLeads = async () => {
    const allLeads = await dispatch(fetchAllLeads());
    const potentialLeads = allLeads.filter((lead) => lead.isPotential);
    const potentialCount = potentialLeads.length;
    setPotentialLeadsCount(potentialCount);
  };


  // Handle date range selection
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    dispatch(fetchListings(1, profile.id));
    dispatch(fetchAllListings(profile.id));
  }, [dispatch, profile.id]);

  useEffect(() => {
    // Update the chart data when listings, startDate, or endDate change
    if (allListings.length > 0) {
      const { dates, counts } = groupListingsByDate(allListings, startDate, endDate);

      // Update the trafficData state for the line chart
      setTrafficData({
        labels: dates,
        datasets: [
          {
            label: "Listing Traffic",
            data: counts,
            backgroundColor: "rgba(54, 162, 235, 0.5)",
            borderColor: "rgba(54, 162, 235, 1)",
            borderWidth: 2,
            fill: true,
          },
        ],
      });
    }
  }, [allListings, startDate, endDate]);

  useEffect(() => {
    dispatch(fetchLeads());
    if (leadsCurrentPage) {
      countPotentialLeads();
    }
  }, [leadsCurrentPage, leadsTotalPages, dispatch]);


  useEffect(() => {
    // Update the doughnut chart data when listings change
    if (allListings.length > 0) {
      const propertyTypeCounts = countListingsByPropertyType(allListings);
      const { dates, counts } = groupListingsByDateForLast7Days(allListings);

      // Update the topListingsData state for the doughnut chart
      setTopListingsData({
        labels: ["Commercial", "Condo", "Freehold"],
        datasets: [
          {
            label: "Property Types",
            data: [
              propertyTypeCounts.commercial,
              propertyTypeCounts.condo,
              propertyTypeCounts.freehold,
            ],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      });

      setDailyActivityData({
        labels: dates,
        datasets: [
          {
            label: "Listings Created",
            data: counts,
            backgroundColor: "rgba(54, 162, 235, 1)",
            borderWidth: 1,
          },
        ],
      });
    }
  }, [allListings]);


  return (
    <div className="container p-1">
      {/* Date Range Picker */}
      <div
        className="date-picker-container mb-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {/* Custom date picker toggle button */}
        <div
          className="date-picker-toggle"
          onClick={() => setShowDatePicker(!showDatePicker)} // Toggle visibility on click
          style={{
            display: "inline-flex",
            alignItems: "center",
            border: "1px solid #0057FF",
            padding: "8px 15px",
            borderRadius: "30px",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          <IconCalendar size={20} style={{ marginRight: "10px" }} />
          <span className="text-black">
            {startDate
              ? format(startDate, "dd, MMM yyyy")
              : "Select start date"}{" "}
            - {endDate ? format(endDate, "dd, MMM yyyy") : "Select end date"}
          </span>
        </div>

        {/* Conditionally render DatePicker */}
        {showDatePicker && (
          <div
            style={{ position: "absolute", zIndex: 1000, marginTop: "40px" }}
          >
            <DatePicker
              selected={startDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
            />
          </div>
        )}
      </div>
      {/* Traffic Chart */}
      <div
        className="card rounded-4 mb-4 p-4 shadow-sm"
        style={{ height: "400px" }}
      >
        <h4 className="text-center mb-4">Listing Traffic</h4>
        <div style={{ height: "100%" }}>
          <Line data={trafficData} options={chartOptions} />
        </div>
      </div>

      {/* Summary Stats */}
      <div className="row mb-4">
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">
                Total Listing
              </h4>
              <h1 className="card-title-analytics h1">
                <strong>{total}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                  <IconTrendingUp className="me-1" />
                  8.5%
                </span> */}
                Total listing up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mb-3 mb-md-0">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">
                Form Submitted
              </h4>
              <h1 className="card-title-analytics h1">
                <strong>{potentialLeadsCount}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                  <IconTrendingUp className="me-1" />
                  8.5%
                </span> */}
                Form Submitted up to date
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card rounded-4 h-100">
            <div className="card-body">
              <h4 className="card-subtitle text-body-secondary">Manual</h4>
              <h1 className="card-title-analytics h1">
                <strong>{leadsTotal - potentialLeadsCount}</strong>
              </h1>
              <div className="card-text text-cyan d-flex gap-2">
                {/* <span className="text-cyan">
                  <IconTrendingUp className="me-1" />
                  8.5%
                </span> */}
                Manual up to date
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Lower Section with Top Listings and Daily Activity */}
      <div className="row">
        <div className="col-md-6 mb-4">
          <div
            className="card rounded-4 p-4 shadow-sm"
            style={{ height: "400px" }}
          >
            <h4 className="text-center mb-4">Top Listings</h4>
            <div style={{ height: "100%" }}>
              <Doughnut data={topListingsData} options={chartOptions} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div
            className="card rounded-4 p-4 shadow-sm"
            style={{ height: "400px" }}
          >
            <h4 className="text-center mb-4">Daily Activity</h4>
            <div style={{ height: "100%" }}>
              <Bar data={dailyActivityData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingAnalytics;
