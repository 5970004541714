import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { addLeadsApi } from "../../modules/dashboard/actions";

const AddLead = ({ isOpen, closeModel }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const [modalData, setModalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    label: "",
    userId: profile.id,
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  const validateEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Regular expression for validating phone number (digits only, optional "+" at the start for international numbers)
    const phoneRegex = /^\+?\d{9,15}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!modalData.firstName || !modalData.lastName || !modalData.email || !modalData.phone) {
      setError("Please fill all the fields");
      return;
    }
   

    if (!validateEmail(modalData.email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (!validatePhone(modalData.phone)) {
      setError("Please enter a valid phone number");
      return;
    }
    setIsSubmitting(true);
    const response = await dispatch(addLeadsApi(modalData));
    console.log(response.data);
    if (response.data) {
      toast.success("Leads is created", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModel();
      setError("");
      setModalData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        label: "",  
      });
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };
  return (
    <div>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="">
                <button
                  type="button"
                  className="btn-close mt-2 me-4"
                  onClick={closeModel}
                  aria-label="Close"
                ></button>
                <h1 className="ml-4 text-black">Add New Leads</h1>
              </div>
              {error && <div className="text-danger mt-3 text-center">{error}</div>}
              <div className="modal-body text-black">
                <form>
                  <div className="mb-3">
                    <label>
                      <strong>First Name</strong>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="form-control form-control-rounded"
                      value={modalData.firstName}
                      onChange={handleChange}
                    />
                    {/* <select
                      className="form-select rounded-5"
                      name="userType"
                      // value={modalData.userType}
                      // onChange={handleChange}
                    >
                      <option value="">Select Agent Type</option>
                      <option value="solo" className="form-select">
                        Solo
                      </option>
                      <option value="brokerage" className="form-select">
                        Brokerage
                      </option>
                    </select> */}
                  </div>
                  <div className="mb-3">
                    <label>
                      <strong>Last Name</strong>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-control form-control-rounded"
                      value={modalData.lastName}
                      onChange={handleChange}
                    />
                  </div>

                  <>
                    <div className="mb-3">
                      <label>
                        <strong>Email</strong>
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="form-control form-control-rounded"
                        value={modalData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        <strong>Phone Number</strong>
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="Phone Number"
                        className="form-control form-control-rounded"
                        value={modalData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                    <label>
                      <strong>Label</strong>
                    </label>
                    <input
                      type="text"
                      name="label"
                      placeholder="Label"
                      className="form-control form-control-rounded"
                      value={modalData.label}
                      onChange={handleChange}
                    />
                  </div>
                    {/* <div className="mb-3">
                      <label>
                        <strong>Business Phone Number</strong>
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={modalData.businessPhone}
                        onChange={(value) => setModalData({ ...modalData, businessPhone: value })}
                      />
                    </div> */}
                  </>
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      className="btn-next"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                       {isSubmitting ? (
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                      ) : (
                        "Save and Continue"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLead;
