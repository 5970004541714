import {
    IconBath,
    IconBed,
    IconMapPin,
    IconRuler,
  } from "@tabler/icons-react";
  import React, { useEffect, useState } from "react";
  import listingImage from "../../../assets/images/login-cover.png";
import { useDispatch } from "react-redux";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";

  const MLSSingleCard = ({ card, onClick }) => {
    const [imageUrl, setImageUrl] = useState(null); // To store the image URL
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("$");
  const dispatch = useDispatch();

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  console.log("image",imageUrl);

    const formatListedDate = (dateString) => {
      const listedDate = new Date(dateString);
      const currentDate = new Date();
    
      // Set both dates to midnight to ignore time differences
      listedDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
    
      // Calculate the days difference, ensuring no negative values
      const timeDifference = currentDate - listedDate;
      const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days
    
      // Ensure no negative daysAgo by returning 0 if timeDifference is negative
      const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;
    
      return daysAgoFinal === 0
        ? "Listed today"
        : daysAgoFinal === 1
        ? "Listed 1 day ago"
        : `Listed ${daysAgoFinal} days ago`;
    };

    useEffect(() => {
      const fetchImage = async () => {
        if (card?.MediaChangeTimestamp) {
          setLoading(true);
          try {
            const response = await dispatch(fetchMlsMedia("Property", card.MediaChangeTimestamp));
            console.log("Fetched images:", response);
    
            if (response && Array.isArray(response) && response.length > 0) {
              // Filter for valid media items
              const activeMedia = response.find(
                (item) =>
                  item?.MediaURL &&
                  item?.MediaStatus === "Active" &&
                  item?.MediaType === "image/jpeg"
              );
              setImageUrl(activeMedia?.MediaURL || listingImage);
            } else {
              setImageUrl(listingImage);
            }
          } catch (error) {
            console.error("Failed to fetch image:", error);
            setImageUrl(listingImage);
          } finally {
            setLoading(false);
          }
        } else {
          setImageUrl(listingImage);
        }
      };
    
      fetchImage();

      if (card?.UnparsedAddress) {
        const detectedCurrency = getCurrencyFromAddress(card.UnparsedAddress);
        setCurrency(detectedCurrency);
      }
    }, [card, dispatch]);
    
    
    
    return (
      <div className="cards mb-3 ">
     
     <div key={card.ListingKey} className="card-listing">
            <div className="position-relative">
            <img
            src={imageUrl || listingImage}
            alt={card.ListingKey || "Default"}
            onError={(e) => {
              e.target.onerror = null; 
              e.target.src = listingImage; 
            }}
          />
              <div className="position-absolute top-0 start-0 m-2 P-4">
                <span
                  className={`status ${card.MlsStatus?.toLowerCase()} text-capitalize`}
                >
                  {card.MlsStatus}
                </span>
              </div>
            </div>
            <div className="card-listing-details cursor-pointer" onClick={() => onClick(card.ListingKey)}>
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <h2>{currency}</h2>
                  <h2>{card.ListPrice && Number(card.ListPrice).toLocaleString()}</h2>
                </div>
                {card.TransactionType && (
                  <div className="h5 bg-primary-lt rounded-4 p-2 text-capitalize">
                    {card.TransactionType}
                  </div>
                )}
              </div>
              <p
                className="h2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {card.PublicRemarks}
              </p>
              <div className="d-flex gap-2 mb-2">
                <IconMapPin />
                <p>{card.UnparsedAddress}</p>
              </div>
              <div className="d-flex gap-2">
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconBed />
                  <div>
                    <strong>{card.BedroomsTotal}</strong>
                  </div>
                </div>
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconBath />
                  <div>
                    <strong>{card.BathroomsTotalInteger}</strong>
                  </div>
                </div>
                <div className="d-flex bg-gray-900 rounded-4 p-2 align-items-center gap-1">
                  <IconRuler />
                  <div>
                    <strong>
                      {card.BuildingAreaTotal} {card.BuildingAreaUnits}
                    </strong>
                  </div>
                </div>
              </div>
              <div className="text-black mt-2">
                {card.OriginalEntryTimestamp &&
                  formatListedDate(card.OriginalEntryTimestamp)}
              </div>
            </div>
          </div>
     
      </div>
    );
  };
  
  export default MLSSingleCard;
  