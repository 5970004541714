import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchIdxProperties, fetchVowProperties } from "../../../modules/dashboard/actions";
import { getCountryDataList } from "countries-list";
import MLSSingleCard from "./MLSSingleCard";
import logo from "../../../assets/images/myRealPage.png";

const MLSCompactSearchPreview = () => {
  const dispatch = useDispatch();
  const { allListings } = useSelector((state) => state.dashboard);
  const [filteredListings, setFilteredListings] = useState([]);
  const [userId, setUserId] = useState("");
  const [customSearch, setCustomSearch] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    // Initialize form data
    searchTerm: "",
    location: "",
    minPrice: "",
    maxPrice: "",
    currency: "",
    propertyType: "",
    propertyCategory: "",
    status: "",
    year: "",
    approxArea: "",
    areaType: "SQ FT",
    buildingInfo: {
      bedroom: 0,
      bathroom: 0,
    },
    // Add customFilter or any other fields as needed
    customFilter: "",
  });

  console.log("formData", formData);
  const countries = getCountryDataList();
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");
  const [mlsCards, setMLSCards] = useState([]);
  const [mlsLoading, setMLSLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "bedroom" || name === "bathroom") {
      setFormData((prevData) => ({
        ...prevData,
        buildingInfo: {
          ...prevData.buildingInfo,
          [name]: Number(value), // Parse to number for proper comparison
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency)
      ? country.currency[0]
      : country.currency;
  };

  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (!countryCode) {
      setSelectedCurrency("");
      setSelectedCountryCode("");
      setFormData((prevData) => ({
        ...prevData,
        currency: "", // Clear currency to show all listings
      }));
    } else if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);
      setFormData((prevData) => ({
        ...prevData,
        currency: singleCurrency,
      }));
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const customFilter = urlParams.get("customFilter");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");
    setUserId(userIdParam);
    setCustomSearch(customFilter);
    setMls(mlsParam);
    setUserType(userTypeParam);
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      // Set loading to true before the API call
      setMLSLoading(true);
      try {
        let response;
        if (userType === "brokerage") {
          response = await dispatch(fetchIdxProperties("ListOfficeKey", mls));
        } else if (userType === "solo") {
          response = await dispatch(fetchVowProperties("ListAgentKey", mls));
        }
        console.log("mls", response);
        setMLSCards(response.value || response); // Use response.value if it exists, else use response directly
      } catch (error) {
        console.error("Failed to fetch properties:", error);
      } finally {
        // Set loading to false after the API call completes
        setMLSLoading(false);
      }
    };

    fetchProperties();
  }, [dispatch, userType, mls]);

  const openListingPreviewModal = (listingId) => {
    const baseUrl = window.location.origin;
    const preview = `${baseUrl}/listingMLS/${listingId}?userId=${userId}&mls=${mls}&type=${userType}`;
    window.open(preview, "_blank");
  };

  const filterListings = useCallback(() => {
    const isFilterApplied =
      formData.searchTerm ||
      formData.location ||
      formData.minPrice ||
      formData.maxPrice ||
      formData.propertyType ||
      formData.propertyCategory ||
      formData.status ||
      formData.year ||
      formData.buildingInfo.bedroom ||
      formData.buildingInfo.bathroom;

    if (!isFilterApplied) {
      return []; // Return an empty array if no filters are applied
    }

    return mlsCards.filter((listing) => {
      const matchesSearchTerm =
        !formData.searchTerm ||
        (listing.PublicRemarks &&
          listing.PublicRemarks.toLowerCase().includes(
            formData.searchTerm.toLowerCase()
          ));

      const matchesLocation =
        !formData.location ||
        (listing.UnparsedAddress &&
          listing.UnparsedAddress.toLowerCase().includes(
            formData.location.toLowerCase()
          ));

      const matchesMinPrice =
        !formData.minPrice ||
        (listing.ListPrice && listing.ListPrice >= Number(formData.minPrice));

      const matchesMaxPrice =
        !formData.maxPrice ||
        (listing.ListPrice && listing.ListPrice <= Number(formData.maxPrice));

      const matchesPropertyType =
        !formData.propertyType ||
        listing.PropertyType === formData.propertyType;

      const matchesPropertyCategory =
        !formData.propertyCategory ||
        listing.TransactionType === formData.propertyCategory;

      const matchesStatus =
        !formData.MlsStatus || listing.status === formData.status;

      const matchesYear =
        !formData.year ||
        (listing.TaxYear && listing.TaxYear === formData.year);

      const matchesBedroom =
        !formData.buildingInfo.bedroom ||
        (listing.BedroomsTotal &&
          listing.BedroomsTotal >= formData.buildingInfo.bedroom);

      const matchesBathroom =
        !formData.buildingInfo.bathroom ||
        (listing.BathroomsTotalInteger &&
          listing.BathroomsTotalInteger >= formData.buildingInfo.bathroom);

      return (
        matchesSearchTerm &&
        matchesLocation &&
        matchesMinPrice &&
        matchesMaxPrice &&
        matchesPropertyType &&
        matchesPropertyCategory &&
        matchesStatus &&
        matchesYear &&
        matchesBedroom &&
        matchesBathroom
      );
    });
  }, [mlsCards, formData]);

  // Update filtered listings whenever `allListings` or `formData` changes
  useEffect(() => {
    const result = filterListings();
    setFilteredListings(result);
  }, [filterListings]);
  // console.log(filteredListings);

  return (
    <div className="container-fluid">
      <div className="row d-flex flex-column flex-lg-row ">
        {/* filter section */}
        <div className="fixed-search-section" style={{ overflow: "hidden" }}>
          {/* <div>{customFilter}</div> */}
          <div className="d-flex align-items-center  ">
            {/* <img
              src={logo}
              alt="logo"
              className="img-fluid me-4 ms-3" // Use Bootstrap class for responsive image
              style={{ maxHeight: "25px" }} // Ensure max height is set for the image
            /> */}
            <div className="">
              <div className="input-icon mx-3 my-3">
                <span className="input-icon-addon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                  </svg>
                </span>
                <input
                  type="text"
                  className="form-control form-control-rounded w-100"
                  placeholder="Search for listings"
                  name="searchTerm"
                  value={formData.searchTerm}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {customSearch === "all" && (
            <div className="m-3">
              <div className="mb-3 w-100">
                <label>Price</label>
                <div className="d-flex align-items-center">
                  <input
                    type="number"
                    name="minPrice"
                    placeholder="Min Price"
                    className="form-control round-left"
                    value={formData.minPrice}
                    onChange={handleChange}
                  />
                  <input
                    type="number"
                    name="maxPrice"
                    placeholder="Max Price"
                    className="form-control round-right"
                    value={formData.maxPrice}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between gap-2 mb-3">
                <div className="mb-3 w-100">
                  <label>Property Type</label>
                  <select
                    className="form-select rounded-5"
                    name="propertyType"
                    // defaultValue={formData.propertyType}
                    onChange={handleChange}
                  >
                    <option value="" className="form-select">
                      All
                    </option>
                    <option value="Commercial" className="form-select">
                      Commercial
                    </option>
                    <option
                      value="Residential Condo & Other"
                      className="form-select"
                    >
                      Condo
                    </option>
                    <option
                      value="Residential Freehold"
                      className="form-select"
                    >
                      Freehold
                    </option>
                  </select>
                </div>
                <div className="mb-3 w-100">
                  <label>Property Category</label>
                  <select
                    className="form-select rounded-5"
                    name="propertyCategory"
                    // defaultValue={formData.propertyCategory}
                    onChange={handleChange}
                  >
                    <option value="" className="form-select">
                      All
                    </option>
                    <option value="For Sale" className="form-select">
                      Sale
                    </option>
                    <option value="For Lease" className="form-select">
                      Lease
                    </option>
                    {/* <option value="rent" className="form-select">
                      Rent
                    </option> */}
                  </select>
                </div>

                <div className="mb-3 w-100">
                  <label>Status</label>
                  <select
                    className="form-select rounded-5"
                    name="status"
                    // defaultValue={formData.status}
                    onChange={handleChange}
                  >
                    <option value="" className="form-select">
                      All
                    </option>
                    <option value="active" className="form-select">
                      Active
                    </option>
                    <option value="New" className="form-select">
                      New
                    </option>
                    <option value="Price Change" className="form-select">
                      Price Change
                    </option>
                    <option value="cancelled" className="form-select">
                      Cancelled
                    </option>
                    <option value="deleted" className="form-select">
                      Deleted
                    </option>
                    <option value="expired" className="form-select">
                      Expired
                    </option>
                    <option value="on hold" className="form-select">
                      On Hold
                    </option>
                    <option value="pending" className="form-select">
                      Pending
                    </option>
                    <option value="sold" className="form-select">
                      Sold
                    </option>
                    <option value="terminated" className="form-select">
                      Terminated
                    </option>
                    <option value="unknown" className="form-select">
                      Unknown
                    </option>
                    <option value="wait approval" className="form-select">
                      Wait Approval
                    </option>
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* card section */}
        <div className="col-12" style={{ marginTop: customSearch === "all" ? "300px" : "150px" }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              justifyContent: "center",
              gap: "1rem",
              overflowX: "auto",
            }}
          >
            {filteredListings.length > 0 ? (
              <div className="d-flex flex-wrap gap-3">
                {filteredListings.map((listing) => (
                  <MLSSingleCard
                    key={listing.id} // Ensure unique key
                    card={listing}
                    onClick={openListingPreviewModal}
                  />
                ))}
              </div>
            ) : (
              <div className="text-center my-4">
                {customSearch === "all" ? (
                  <p className="text-muted">
                    Search or Apply filters to see results.
                  </p>
                ) : (
                  <p className="text-muted">Search to see results.</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default MLSCompactSearchPreview;
