import { IconCirclePlus, IconX } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import AddNews from "../../../components/News/AddNews";
import NewsCard from "../../../components/News/NewsCard";
import NewsImage from "../../../assets/images/news-1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadImageApi,
  fetchAllNewsApi,
  fetchNews,
  uploadImageApi,
} from "../actions";
import UpdateNews from "../../../components/News/UpdateNews";
import { getDownloadUrl } from "../../../config/config";

const News = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const imageInputRef = useRef(null);
  const [formData, setFormData] = useState({
    imageUrl: [
      "http://139.59.29.58/download/1729690574668.png",
      "http://139.59.29.58/download/1729690649644.png",
    ],
  });
  const [images, setImages] = useState(formData.imageUrl || []);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImages = await Promise.all(
      files.map(async (file) => {
        // Upload image first
        const uploadResponse = await dispatch(uploadImageApi(file));

        // Then call the download API to get the real image URL
        const filePath = new URL(uploadResponse).pathname.substring(1);
        const fileName = uploadResponse.substring(
          uploadResponse.lastIndexOf("/") + 1
        ); // Extract file name from file path

        const downloadUrl = getDownloadUrl(fileName); // Construct the download URL
        console.log(downloadUrl);
        if (filePath) {
          const response = await dispatch(downloadImageApi(filePath)); // Pass file path to API
          // const data = await response.json();
          // Set the downloaded image URL
          console.log(response.filePath);
        }
        return downloadUrl; // Use the constructed URL
      })
    );

    console.log("Uploaded Images:", uploadedImages);

    // Update formData with new images
    // setFormData({
    //   ...formData,
    //   images: [...formData.images, ...uploadedImages],
    // });

    // Update state to display images in real-time
    setImages((prevImages) => [...prevImages, ...uploadedImages]);
    event.target.value = null;
  };

  const handleImageButtonClick = () => {
    imageInputRef.current.click();
  };

  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index)); // Remove image at the specified index
  };

  const openLeadsModal = () => {
    setIsModalOpen(true);
  };

  const closeLeadsModal = () => {
    setIsModalOpen(false);
  };

  const openUpdateModal = (news) => {
    setIsUpdateModalOpen(true);
    setSelectedNews(news);
  };
  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const {allNews} =
    useSelector((state) => state.dashboard);

  useEffect(() => {
    const fetchNewsData = async () => {
      setLoading(true); // Show loading spinner
      await dispatch(fetchNews());
      await dispatch(fetchAllNewsApi());
      setLoading(false); // Hide loading spinner after fetching data
    };

    fetchNewsData();
  }, [dispatch, isModalOpen, isUpdateModalOpen]);


  const sortedNews = allNews.slice().reverse();

  const totalNews = sortedNews?.length || 0;
  const newsPerPage = 10;
  const totalPages = Math.ceil(totalNews / newsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const paginatedNews = sortedNews?.slice(
    (currentPage - 1) * newsPerPage,
    currentPage * newsPerPage
  );

  const handlePageChangeNews = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <AddNews isOpen={isModalOpen} closeModel={closeLeadsModal} />
      <UpdateNews
        isOpen={isUpdateModalOpen}
        closeModel={closeUpdateModal}
        news={selectedNews}
      />
      <div className="page-wrapper">
        <div className="page-header m-3">
          <div className="row align-items-center gap-2">
            <div className="col-auto ms-auto">
              <div className="btn-list">
                <button
                  className="btn btn-pill btn-primary"
                  onClick={openLeadsModal}
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="ms-2">Add News</div>
                    <IconCirclePlus size={18} />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Loading Spinner */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div
            style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}
            className="m-3"
          >
            {paginatedNews.map((newsItem, index) => (
              <NewsCard
                key={index}
                imageSrc={newsItem.imageUrl || NewsImage}
                title={newsItem.heading}
                description={newsItem.content}
                onClick={() => openUpdateModal(newsItem)}
              />
            ))}
          </div>
        )}

        <div className="d-flex align-item-center justify-content-center mt-4">
          <nav>
            <ul className="pagination gap-2">
              <button
                className="pagination-arrow text-primary me-2"
                disabled={currentPage === 1}
                onClick={() => handlePageChangeNews(currentPage - 1)}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <li
                    key={page}
                    className={`page-item rounded-5 me-1 ${
                      currentPage === page ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link rounded-5"
                      onClick={() => handlePageChangeNews(page)}
                    >
                      {page}
                    </button>
                  </li>
                )
              )}
              <button
                className="pagination-arrow text-primary"
                disabled={currentPage === totalPages}
                onClick={() => handlePageChangeNews(currentPage + 1)}
              >
                Next
              </button>
            </ul>
          </nav>
        </div>
      </div>
      {/* <div className="card m-3 p-4 rounded-4">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="text-primary">Login Banner Images</h2>
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-primary btn-pill"
              onClick={handleImageButtonClick}
            >
              Add Images
              <IconCirclePlus className="ms-1" />
            </button>
            <input
              type="file"
              accept="image/*"
              multiple
              className="form-control d-none"
              ref={imageInputRef}
              onChange={handleImageUpload}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 mt-1">
          {images.map((image, index) => (
            <div key={index} className="position-relative">
              <img
                src={image}
                alt={index}
                className="me-3 rounded-4 object-fit-cover"
                style={{ width: "180px", height: "150px" }}
              />

         
              <div className="position-absolute top-0 end-0 me-4 mb-2 mt-2">
                <button
                  className="btn btn-ghost btn-circle border border-primary d-flex align-items-center justify-content-center"
                  onClick={() => handleImageRemove(index)}
                >
                  <IconX size={17} className="text-primary" />
                </button>
              </div>
          
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default News;
