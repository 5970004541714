import { IconCheck, IconCirclePlus, IconFileText, IconPencilMinus, IconTrash, IconX } from '@tabler/icons-react';
import React, { useRef, useState } from 'react'

const FloorPlans = ({
    formData,
    setFormData,
  }) => {
    const [plans, setPlans] = useState([]);
    const [selectedPlans, setSelectedPlans] = useState(formData.floorPlans || []);
    const [temporaryPlans, setTemporaryPlans] = useState({
      name: "",
    });
    const fileInputRef = useRef(null);
    const [editingIndex, setEditingIndex] = useState(-1);
    // const [editedName, setEditedName] = useState("");
  
    // Function to handle the file input click
    const handleButtonClick = () => {
      fileInputRef.current.click(); // Programmatically click the hidden file input
    };
  
    const handleFileChange = (e) => {
      const files = Array.from(e.target.files);
  
      const newPlans = files.map((file) => ({
        name: file.name,
        documentId: Math.floor(Math.random() * 1000),
        documentUrl: URL.createObjectURL(file),
        file,
      }));
      //   onAddPlans(newPlans);
      setPlans((prevPlans) => [...prevPlans, ...newPlans]);
      e.target.value = null;
    };
    const handleInputChange = (index, field, value, isSelected) => {
      const targetArray = isSelected ? [...selectedPlans] : [...plans];
      const updatedPlans = { ...targetArray[index], [field]: value };
      const newPlansArray = [...targetArray.slice(0, index), updatedPlans, ...targetArray.slice(index + 1)];
      // newPlans[index][field] = value;
      if (isSelected) {
        setSelectedPlans(newPlansArray);
        setFormData((prevData) => ({
          ...prevData,
          floorPlans: newPlansArray,
        }));
      } else {
      //   setSelectedPlans(newPlans);
        setFormData((prevData) => ({
          ...prevData,
          floorPlans: newPlansArray,
        }));
      }
    };
  
    const handleRemovePlans = (index, isSelected) => {
      const targetArray = isSelected ? selectedPlans : plans;
      const newPlans = targetArray.filter((_, i) => i !== index);
      if (isSelected) {
        setSelectedPlans(newPlans);
        setFormData((prevData) => ({
          ...prevData,
          floorPlans: newPlans,
        }));
      } else {
        setPlans(newPlans);
        setFormData((prevData) => ({
          ...prevData,
          floorPlans: newPlans,
        }));
      }
    };
  
    const handleSelectPlans = (index) => {
      const plansToSelect = plans[index];
      setSelectedPlans((prevSelected) => [...prevSelected, plansToSelect]);
      setFormData({
        ...formData,
        floorPlans: [...formData.floorPlans, plansToSelect],
      });
      setPlans([]);
    };
  
    const startEditing = (index) => {
      const plansToSelect = selectedPlans[index];
      setEditingIndex(index);
      setTemporaryPlans({
        name: plansToSelect.name,
      });
    };
  
    const stopEditing = () => {
      setEditingIndex(-1);
      setTemporaryPlans(null);
    };

    const stopEditingCancel = (index) => {
      setEditingIndex(-1);
      selectedPlans[index].name = temporaryPlans.name;
      setTemporaryPlans(null);
    };
  
    console.log("selectedPlans", selectedPlans);
    return (
      <>
        <div className="video-uploader">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="text-primary">Floor Plans</h2>
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="btn btn-primary btn-pill"
                onClick={handleButtonClick}
                disabled={plans.length > 0}
              >
                Add
                <IconCirclePlus className="ms-1" />
              </button>
              <input
                type="file"
                accept="application/pdf"
                multiple
                className="form-control d-none"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
  
          <div className="video-player mt-3">
            {plans.map((plans, index) => (
              <div
                key={index}
                className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <IconFileText />
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="mb-2">
                    <label className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      value={plans.name}
                      className="form-control form-control-rounded"
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value, false)
                      }
                    />
                    <p className="mt-2">PDF</p>
                  </div>
                </div>
                <div className="d-flex flex-md-column flex-row justify-content-between gap-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                    onClick={() => handleRemovePlans(index, false)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconX size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                    onClick={() => handleSelectPlans(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconCheck size={17} />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="video-player mt-3">
            {/* <h2 className="text-primary">Selected Videos</h2> */}
            {selectedPlans.map((selectPlans, index) => (
              <div
                key={index}
                className="d-flex align-items-center flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <IconFileText />
                  </div>
                </div>
  
                {editingIndex === index ? (
                  <>
                    <div className="flex-grow-1">
                      <div className="mb-2">
                        <label className="form-label">
                          Plans Name
                        </label>
                        <input
                          type="text"
                          //   value={video.name}
                          className="form-control form-control-rounded"
                          value={selectPlans.name}
                          onChange={(e) =>
                            handleInputChange(index, "name", e.target.value, true)
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-md-column flex-row justify-content-between  gap-2">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                        onClick={() => stopEditingCancel(index)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <IconX size={17} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                        onClick={stopEditing}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <IconCheck size={17} />
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex-grow-1">
                      <div className="mb-2">
                        <label className="form-label">
                          <p className="h3">{selectPlans.name}</p>
                        </label>
                      </div>
                      <div className="mb-2">PDF</div>
                    </div>
                    <div className="d-flex flex-md-column flex-row justify-content-between">
                      <button
                        type="button"
                        className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                        onClick={() => startEditing(index)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <IconPencilMinus size={17} />
                      </button>
                      <button
                        type="button"
                        className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                        onClick={() => handleRemovePlans(index, true)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <IconTrash size={17} />
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
}

export default FloorPlans