import {
  IconMail,
  IconPhone,
  IconBrandFacebook,
  IconBrandTwitter,
  IconBrandLinkedin,
  IconBrandInstagram,
  IconLink,
} from "@tabler/icons-react";
import React from "react";

const ProfileCard = ({ profile, email, phone }) => {
  const getSocialMediaIcon = (link) => {
    const lowerLink = link.toLowerCase();
    if (lowerLink.includes("facebook.com"))
      return <IconBrandFacebook size={20} />;
    if (lowerLink.includes("twitter.com"))
      return <IconBrandTwitter size={20} />;
    if (lowerLink.includes("linkedin.com"))
      return <IconBrandLinkedin size={20} />;
    if (lowerLink.includes("instagram.com"))
      return <IconBrandInstagram size={20} />;
    return <IconLink size={20} />; // Generic icon for other links
  };

  const ensureAbsoluteURL = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <div className="card shadow-sm p-4 agent-card rounded-4 mb-4">
      <div className="d-flex flex-column gap-4">
        {/* Agent Avatar */}
        <div className="d-flex align-items-center gap-3">
        <span
              className="avatar avatar-xl bg-gray rounded-circle"
              style={{
                backgroundImage: `url(${
                  profile.profileUrl || "https://via.placeholder.com/150"
                })`,
                position: "relative",
                width: "120px",
                height: "120px",
                backgroundSize: "cover",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          <div className="ml-3">
            {/* Agent Name */}
            <h3 className="mb-0">
              {profile.displayName ? profile.displayName : profile.name}
            </h3>
          </div>
        </div>
        {/* Edit Button */}
        {phone && (
          <div className="d-flex align-items-center gap-2">
            <div>
              <IconPhone size={20} />
            </div>
            {profile.businessPhone ? profile.businessPhone : profile.phone}
          </div>
        )}
        {email && (
          <div className="d-flex align-items-center gap-2">
            <div>
              <IconMail size={20} />
            </div>
            {profile.businessEmail ? profile.businessEmail : profile.email}
          </div>
        )}

        {profile.links && profile.links.length > 0 && (
          <div>
            <h4 className="mb-1">Socials</h4>
            <div className="d-flex flex-wrap gap-2">
              {profile.links.map((link, index) => (
                <div className="border rounded-circle p-2">
                    <a
                  key={index}
                  href={ensureAbsoluteURL(link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none text-muted"
                  data-bs-toggle="tooltip"
                  title={link}
                >
                  {getSocialMediaIcon(link)}
                </a>
                    </div>
              ))}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between gap-2">
          {email && (
            <button className="btn btn-black-outline d-flex align-items-center w-100">
              <div className="d-flex align-items-center gap-2">
                Email{" "}
                <div>
                  <IconMail size={20} />
                </div>
              </div>
            </button>
          )}
          {phone && (
            <button className="btn btn-black btn-pill d-flex align-items-center w-100">
              <div className="d-flex align-items-center gap-2">
                Call{" "}
                <div>
                  <IconPhone size={20} />
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
