import React, { Component } from 'react';

class AboutUs extends Component {
  render() {
    return (
      <div>
        <h2>About Us Page</h2>
        <p>Welcome to the About Us Page. This page does not require authentication.</p>
      </div>
    );
  }
}

export default AboutUs;
