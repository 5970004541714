import {
  IconBath,
  IconBed,
  IconCheck,
  IconClipboard,
  IconDirections,
  IconDoor,
  IconFileDescription,
  IconLink,
  IconMapPin,
  IconMapPinFilled,
  IconPhotoScan,
  IconPrinter,
  IconRuler,
  IconShare,
  IconToolsKitchen2,
  IconVocabulary,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import preImage1 from "../../../assets/images/pre1.png";
import preImage2 from "../../../assets/images/pre2.png";
import preImage3 from "../../../assets/images/pre3.png";
import preImage4 from "../../../assets/images/pre4.png";
import preImage5 from "../../../assets/images/pre5.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Schedule from "./../Preview/Schedule";
import L from "leaflet";
// import Mortgage from "./Mortgage";
import { Link } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMlsMedia,
  fetchUserById,
} from "../../../modules/dashboard/actions";
import listingImage from "../../../assets/images/login-cover.png";
import Mortgage from "../Preview/Mortgage";
import ProfileCard from "../Preview/ProfileCard";

const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MlsBasic = ({ selectedListing, listings, user }) => {
  const profile = useSelector((state) => state.auth.profile);
  const [listingLink, setListingLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(user || "");
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const [displayEmail, setDisplayEmail] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);

  const handleEmailToggle = () => setDisplayEmail(!displayEmail);
  const handlePhoneToggle = () => setDisplayPhone(!displayPhone);
  const googleApiKey = "AIzaSyClmOljs5wfE6KmOauX_vC_dBdq06A8rpk";
  const mapRef = useRef(null);
  const [userProfile, setUserProfile] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    // Get the current URL from the browser
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const userIdParam = urlParams.get("userId");
      const emailParam = urlParams.get("email");
      const phoneParam = urlParams.get("phone");
      console.log("User ID:", userIdParam);
      console.log("Email:", emailParam);
      console.log("Phone:", phoneParam);
      setUserId(userIdParam);
      setDisplayEmail(emailParam === "true");
      setDisplayPhone(phoneParam === "true");
      console.log("Display Email:", displayEmail);
      console.log("Display Phone:", displayPhone);
      const fetchUser = async () => {
        try {
          const response = await dispatch(fetchUserById(userIdParam));

          if (response) {
            setUserProfile(response);
          }
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };
      fetchUser();
    }
  }, [user, dispatch]);
  const [mls, setMls] = useState("");
  const [userType, setUserType] = useState("");
  const [listing, setListing] = useState({});
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currency, setCurrency] = useState("$");

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get("userId");
    const mlsParam = urlParams.get("mls");
    const userTypeParam = urlParams.get("type");
    setUserId(userIdParam);
    setMls(mlsParam);
    setUserType(userTypeParam);
  }, []);

  useEffect(() => {
    const selected = listings.find(
      (listing) => listing.ListingKey === selectedListing
    );
    if (selected) {
      geocodeAddress(selected);
      setListing(selected);
    }
  }, [selectedListing, listings]);

  console.log(selectedListing);
  console.log(listings);

  useEffect(() => {
    // Update the map center if the map instance and mapCenter are available
    if (mapRef.current) {
      const map = mapRef.current;
      map.setView(mapCenter, 13); // Set zoom level here if needed
    }
  }, [mapCenter]);

  useEffect(() => {
    const fetchImage = async () => {
      if (listing?.MediaChangeTimestamp) {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchMlsMedia("Property", listing.MediaChangeTimestamp)
          );
          console.log("Fetched images:", response);

          if (response && Array.isArray(response) && response.length > 0) {
            // Filter for valid media items
            const activeMediaUrls = response
              .filter(
                (item) =>
                  item?.MediaURL &&
                  item?.MediaStatus === "Active" &&
                  item?.MediaType === "image/jpeg"
              )
              .map((item) => item.MediaURL);

            if (activeMediaUrls.length > 0) {
              setImageUrl(activeMediaUrls); // Store all MediaURLs
            } else {
              setImageUrl([listingImage]); // Default fallback
            }
          } else {
            setImageUrl([listingImage]); // Default fallback
          }
        } catch (error) {
          console.error("Failed to fetch images:", error);
          setImageUrl([listingImage]); // Default fallback
        } finally {
          setLoading(false);
        }
      } else {
        setImageUrl([listingImage]); // Default fallback
      }
    };

    fetchImage();

    if (listing?.UnparsedAddress) {
      const detectedCurrency = getCurrencyFromAddress(listing.UnparsedAddress);
      setCurrency(detectedCurrency);
    }
  }, [dispatch, listing]);

  const geocodeAddress = async (listing) => {
    const fullAddress = `${
      listing.UnitNumber ? `#${listing.UnitNumber} - ` : ""
    }${listing.StreetNumber || ""} ${listing.StreetName || ""} ${
      listing.StreetSuffix || ""
    }, ${listing.City || ""}, ${listing.StateOrProvince || ""}, ${
      listing.Country || ""
    }, ${listing.PostalCode || ""}`;
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: fullAddress,
            key: googleApiKey,
          },
        }
      );
      if (response.data.results[0]) {
        const location = response.data.results[0].geometry.location;
        setMapCenter([location.lat, location.lng]);
        console.log(mapCenter);
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const generateListingLink = (listingId) => {
    const mlsParam = profile?.mlsNumber || mls || ""; // Ensure it uses mls if profile.mlsNumber is not available
    const user = profile?.id || userId || "";
    return `${
      window.location.origin
    }/listingMLS/${listingId}?userId=${user}&mls=${mlsParam}&type=${
      profile?.userType || userType
    }&email=${displayEmail}&phone=${displayPhone}`;
  };

  const handleShareListing = () => {
    const link = generateListingLink(selectedListing);
    setListingLink(link);
    setIsModalOpen(true);
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = listingLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  const handleCopyLink = () => {
    console.log("Attempting to copy the link..."); // Debugging log
    if (navigator.clipboard) {
      navigator.clipboard.writeText(listingLink).then(
        () => {
          console.log("Link copied successfully"); // Success log
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error("Failed to copy using Clipboard API: ", err);
          handleCopyLinkFallback(); // Fallback for unsupported cases
        }
      );
    } else {
      handleCopyLinkFallback(); // Fallback for older browsers
    }
  };

  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();

    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days

    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;

    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const cleanAddress = (address) => {
    return address
      .replace(/#/g, "") // Remove #
      .replace(/-\s*/g, "") // Remove - and any trailing spaces
      .replace(/,\s*ON.*/g, "") // Remove ", ON" and everything after it
      .trim(); // Trim extra spaces
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div>
      {listings.map((listing) => (
        <div className="">
          {listing.ListingKey === selectedListing && (
            <div className="row">
              {/* Left Section */}
              <div className="col-lg-12">
                {/* Property Images */}
                <div className="image-grid">
                  {loading ? (
                    <p>Loading images...</p>
                  ) : (
                    <>
                      {imageUrl.slice(0, 5).map((url, index) => (
                        <div
                          key={index}
                          className={`image-item ${
                            index === 0 ? "main-image" : "thumbnail"
                          }`}
                        >
                          <img
                            src={url || preImage1}
                            alt={`listing`}
                            className="image"
                          />
                          {index === 4 && imageUrl.length > 5 && (
                            <div className="overlay">
                              <span>+{imageUrl.length - 5}</span>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-8 mt-3">
                {/* Property Details */}
                <div className="mb-3">
                  <div className="card-body">
                    <h1 className="card-title h1">
                      <strong>
                        {listing.UnparsedAddress &&
                          cleanAddress(listing.UnparsedAddress)}
                      </strong>
                    </h1>
                    <div className="mb-4">
                      {/* Header Section */}
                      <div className="card-body">
                        <h3 className="text-capitalize mb-2">
                          {listing.PropertyType || "Property"} |{" "}
                          {listing.MlsStatus || "Status"}
                        </h3>
                        <h4 className="text-muted mb-3">
                          MLS - {listing.ListingKey || "N/A"}
                        </h4>
                        <div className="d-flex flex-wrap align-items-center gap-2 mb-3">
                          <span className="badge bg-success text-white">
                            {listing.StandardStatus || "For Sale"}
                          </span>
                          |
                          <span className="text-black">
                            {listing.TransactionType || "Transaction Type"}
                          </span>
                          |
                          <span className="text-black">
                            {listing.OriginalEntryTimestamp &&
                              formatListedDate(listing.OriginalEntryTimestamp)}
                          </span>
                        </div>
                        <h2 className="">
                          {currency}{" "}
                          {listing.ListPrice &&
                            Number(listing.ListPrice).toLocaleString()}{" "}
                          <small className="text-muted">/ Per Sq Ft</small>
                        </h2>
                      </div>
                    </div>

                    {/* Info Grid */}

                    {/* Left Column */}

                    <div className="mb-5">
                      <h3 className="card-title">
                        <strong>Property Details</strong>
                      </h3>
                      <p>
                        For:
                        <strong>
                          {" "}
                          {listing.PropertySubType || "N/A"} |{" "}
                          {listing.ArchitecturalStyle?.[0] ||
                            "Style not available"}
                        </strong>
                      </p>
                      <p>
                        Approx. Age:
                        <strong> {listing.ApproximateAge || "N/A"}</strong>
                      </p>
                      <p>
                        Exposure:
                        <strong> {listing.Exposure || "N/A"}</strong>
                      </p>
                    </div>

                    {/* Right Column */}

                    <div className=" mb-5">
                      <h3 className="card-title">
                        <strong>Location Info</strong>
                      </h3>
                      <p>
                        <IconMapPin className="me-1" />
                        {/* Address: */}
                        <strong>
                          {" "}
                          {listing.UnparsedAddress || "Address not available"}
                        </strong>
                      </p>
                      <p>
                        <IconDirections className="me-1" />
                        Directions:
                        <strong> {listing.CrossStreet || "N/A"}</strong>
                      </p>
                    </div>

                    <h3 className="mt-4">About this property</h3>
                    <p>{listing.PublicRemarks && listing.PublicRemarks}</p>

                    {/* Property Info */}
                    <div className="d-flex flex-column flex-md-row  my-4 border pt-3 pb-2 rounded-3 me-2">
                      <div className="col ms-2 ps-2">
                        <p>Tax Year</p>
                        <h5>{listing.TaxYear && listing.TaxYear}</h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Living Area</p>
                        <h5>
                          <IconRuler className="me-2" />
                          {listing.BuildingAreaTotal &&
                            listing.BuildingAreaTotal}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Total Approx Floor Area</p>
                        <h5>
                          <IconRuler className="me-2" />
                          {listing.LivingAreaRange && listing.LivingAreaRange}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Bedrooms</p>
                        <h5>
                          <IconBed className="me-2" />
                          {listing.BedroomsTotal && listing.BedroomsTotal
                            ? listing.BedroomsTotal
                            : "-"}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Bathrooms</p>
                        <h5>
                          <IconBath className="me-2" />
                          {listing.BathroomsTotalInteger &&
                            listing.BathroomsTotalInteger}
                        </h5>
                      </div>
                      <div className="col border-md-start ms-2 ps-2">
                        <p>Kitchen</p>
                        <h5>
                          <IconToolsKitchen2 className="me-2" />
                          {listing.KitchensTotal && listing.KitchensTotal}
                        </h5>
                      </div>
                    </div>

                    {listing.PropertyFeatures &&
                      listing.PropertyFeatures.length > 0 && (
                        <div className="card shadow-sm mb-5">
                          <div className="card-body">
                            <h3 className="card-title mb-3">
                              <strong>Property Features</strong>
                            </h3>
                            <div className="row">
                              {listing.PropertyFeatures.map(
                                (feature, index) => (
                                  <div
                                    key={index}
                                    className="col-md-4 col-sm-6 col-12 mb-2 d-flex align-items-center"
                                  >
                                    <div className="badge bg-primary text-white p-2 d-flex align-items-center w-100">
                                      <span className="me-2">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          className="bi bi-check-circle"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.707 10.293l4-4a1 1 0 0 0-1.414-1.414l-3.293 3.293-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0z" />
                                        </svg>
                                      </span>
                                      <span className="text-capitalize">
                                        {feature}
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                    <h3>Bathroom</h3>
                    <div className="table-responsive rounded-3 border mb-5">
                      <table className="table">
                        <thead className="">
                          <tr className="">
                            <th className="text-capitalize">Floor</th>
                            <th className="text-capitalize">Ensuite</th>
                            <th className="text-capitalize">Pieces</th>
                            <th className="text-capitalize">Other</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listing.WashroomsType1 && (
                            <tr>
                              <td>
                                {listing.WashroomsType1Level &&
                                  listing.WashroomsType1Level}
                              </td>
                              <td className="text-secondary">-</td>
                              <td className="text-secondary">
                                {listing.WashroomsType1Pcs &&
                                  listing.WashroomsType1Pcs}
                              </td>
                              <td className="text-secondary">
                                {/* {listing.bathroom.info && listing.bathroom.info} */}
                              </td>
                            </tr>
                          )}
                          {listing.WashroomsType2 && (
                            <tr>
                              <td>
                                {listing.WashroomsType2Level &&
                                  listing.WashroomsType2Level}
                              </td>
                              <td className="text-secondary">-</td>
                              <td className="text-secondary">
                                {listing.WashroomsType2Pcs &&
                                  listing.WashroomsType2Pcs}
                              </td>
                              <td className="text-secondary">
                                {/* {listing.bathroom.info && listing.bathroom.info} */}
                              </td>
                            </tr>
                          )}
                          {listing.WashroomsType3 && (
                            <tr>
                              <td>
                                {listing.WashroomsType3Level &&
                                  listing.WashroomsType3Level}
                              </td>
                              <td className="text-secondary">-</td>
                              <td className="text-secondary">
                                {listing.WashroomsType3Pcs &&
                                  listing.WashroomsType3Pcs}
                              </td>
                              <td className="text-secondary">
                                {/* {listing.bathroom.info && listing.bathroom.info} */}
                              </td>
                            </tr>
                          )}
                          {listing.WashroomsType4 && (
                            <tr>
                              <td>
                                {listing.WashroomsType4Level &&
                                  listing.WashroomsType4Level}
                              </td>
                              <td className="text-secondary">-</td>
                              <td className="text-secondary">
                                {listing.WashroomsType4Pcs &&
                                  listing.WashroomsType4Pcs}
                              </td>
                              <td className="text-secondary">
                                {/* {listing.bathroom.info && listing.bathroom.info} */}
                              </td>
                            </tr>
                          )}
                          {listing.WashroomsType5 && (
                            <tr>
                              <td>
                                {listing.WashroomsType5Level &&
                                  listing.WashroomsType5Level}
                              </td>
                              <td className="text-secondary">-</td>
                              <td className="text-secondary">
                                {listing.WashroomsType5Pcs &&
                                  listing.WashroomsType5Pcs}
                              </td>
                              <td className="text-secondary">
                                {/* {listing.bathroom.info && listing.bathroom.info} */}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <h3>Additional Info</h3>

                    <div className="table-responsive rounded-3 border mb-5">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Heating Type</td>
                            <th>{listing.HeatType || "-"}</th>
                          </tr>
                          <tr>
                            <td>Heating Fuel</td>
                            <th>{listing.HeatSource || "-"}</th>
                          </tr>
                          <tr>
                            <td>Basement</td>
                            <th>{listing.Basement || "-"}</th>
                          </tr>
                          <tr>
                            <td>Fireplace/Stove</td>
                            <th>{listing.FireplaceYN ? "Yes" : "No"}</th>
                          </tr>
                          <tr>
                            <td>Covered Spaces</td>
                            <th>{listing.CoveredSpaces || "-"}</th>
                          </tr>
                          <tr>
                            <td>Garage</td>
                            <th>{listing.GarageType || "-"}</th>
                          </tr>
                          <tr>
                            <td>Parking Type</td>
                            <th>{listing.ParkingFeatures?.[0] || "-"}</th>
                          </tr>
                          <tr>
                            <td>Parking Spaces</td>
                            <th>{listing.ParkingSpaces || "-"}</th>
                          </tr>
                          <tr>
                            <td>Total Parking Spaces</td>
                            <th>{listing.ParkingTotal || "-"}</th>
                          </tr>
                          <tr>
                            <td>Parking/Drive</td>
                            <th>{listing.ParkingFeatures?.[0] || "-"}</th>
                          </tr>
                          <tr>
                            <td>Exterior Features</td>
                            <th>
                              {listing.ConstructionMaterials?.length
                                ? listing.ConstructionMaterials.join(", ")
                                : "-"}
                            </th>
                          </tr>
                          <tr>
                            <td>Family Room</td>
                            <th>{listing.DenFamilyroomYN ? "Yes" : "No"}</th>
                          </tr>
                          <tr>
                            <td>Ensuite Laundry</td>
                            <th>
                              {listing.LaundryFeatures?.[0] ? "Yes" : "No"}
                            </th>
                          </tr>
                          <tr>
                            <td>Possession Details</td>
                            <th>{listing.PossessionDetails || "-"}</th>
                          </tr>
                          <tr>
                            <td>Locker</td>
                            <th>{listing.Locker || "-"}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <h3>Financial Info</h3>

                    <div className="table-responsive rounded-3 border mb-5">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Maintenance Fee</td>
                            <th>{listing.AssociationFee || "-"}</th>
                          </tr>
                          <tr>
                            <td>Maintenance Fees Include</td>
                            <th>
                              {listing.AssociationFeeIncludes?.[0] || "-"}
                            </th>
                          </tr>
                          <tr>
                            <td>Taxes</td>
                            <th>
                              {listing.TaxAnnualAmount || "-"} /{" "}
                              {new Date().getFullYear()}
                            </th>
                          </tr>
                          <tr>
                            <td>Assessment</td>
                            <th>
                              {listing.AssessmentYear
                                ? `$ ${listing.AssessmentYear}`
                                : "$ - / -"}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h3>Condominium Info</h3>

                    <div className="table-responsive rounded-3 border mb-5">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Balcony</td>
                            <th>{listing.BalconyType || "-"}</th>
                          </tr>
                          <tr>
                            <td>Condo Corporation Number</td>
                            <th>{listing.CondoCorpNumber || "-"}</th>
                          </tr>
                          <tr>
                            <td>Condo Registry Office</td>
                            <th>{listing.AssociationName || "-"}</th>
                          </tr>
                          <tr>
                            <td>Pets Permitted</td>
                            <th>{listing.PetsAllowed || "-"}</th>
                          </tr>
                          <tr>
                            <td>Property Management Company</td>
                            <th>{listing.PropertyManagementCompany || "-"}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h3>Listing Info</h3>
                    <div className="table-responsive rounded-3 border mb-5">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Date Listed</td>
                            <th>
                              {listing.MediaChangeTimestamp
                                ? formatDate(listing.MediaChangeTimestamp)
                                : "-"}
                            </th>
                          </tr>
                          <tr>
                            <td>Original Price</td>
                            <th>
                              {currency}{" "}
                              {listing.ListPrice &&
                                Number(listing.ListPrice).toLocaleString()}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="col-lg-4">
                {/* Schedule a Viewing */}
                <div className="d-flex align-items-center gap-2 mb-3">
                  <IconPrinter size={65} />
                  <buttoh
                    className="btn btn-black-outline w-100"
                    onClick={handleShareListing}
                  >
                    Share Listing <IconShare size={20} className="ms-1" />
                  </buttoh>
                  <button className="btn btn-black w-100">Send Listing</button>
                </div>

                {user && (
                  <>
                    <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          Display Email
                        </div>
                      </div>
                      <div className="ml-auto">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={displayEmail}
                            onChange={handleEmailToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                          Display Phone
                        </div>
                      </div>
                      <div className="ml-auto">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={displayPhone}
                            onChange={handlePhoneToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </>
                )}

                {!user && (
                  <ProfileCard
                    profile={userProfile}
                    email={displayEmail}
                    phone={displayPhone}
                  />
                )}

                <MapContainer
                  center={mapCenter}
                  ref={mapRef}
                  zoom={13}
                  style={{
                    height: "150px",
                    width: "100%",
                    marginBottom: "3px",
                  }}
                  className="mb-4 rounded-3"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={mapCenter} icon={customIcon}>
                    {/* <Popup>{selectedListing.UnparsedAddress}</Popup> */}
                  </Marker>
                </MapContainer>

                {/* schedule */}
                <Schedule listingID={selectedListing} userId={userId} />

                {/* Calculate Mortgage */}
                {/* <Mortgage /> */}
              </div>
            </div>
          )}
        </div>
      ))}

      {isModalOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Listing Link</h3>
                <IconX
                  onClick={closeModal}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control form-control-rounded pe-5"
                    value={listingLink}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MlsBasic;
