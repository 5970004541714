import { Route, Routes } from "react-router-dom"

import Login from "../modules/auth/views/Login";

import { AuthPaths } from "./paths"
import SelectPackage from "../modules/auth/views/SelectPackage";
import SignupDetails from "../modules/auth/views/SignupDetails";
import SignupPayment from "../modules/auth/views/SignupPayment";
import ForgotPassword from "../modules/auth/views/ForgotPassword";
import EmailVerification from "../modules/auth/views/EmailVerification";
import ResetPassword from "../modules/auth/views/ResetPassword";

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path={AuthPaths.LOGIN} element={<Login/>}/>
            <Route path={AuthPaths.PACKAGE} element={<SelectPackage/>}/>
            <Route path={AuthPaths.SIGNUP1} element={<SignupDetails/>}/>
            <Route path={AuthPaths.SIGNUP2} element={<SignupPayment/>}/>

            <Route path={AuthPaths.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
            <Route path={AuthPaths.EMAIL_VERIFICATION} element={<EmailVerification/>}/>
            <Route path={AuthPaths.RESET_PASSWORD} element={<ResetPassword/>}/>
        </Routes>
    );
}

export default AuthRoutes;