export const roomNames = [
  " 2nd Den",
  "2nd Family Room",
  "2nd Kitchen",
  "2nd Living Room",
  "Atrium",
  "Attic",
  "Back Hall",
  "Balcony",
  "Bar Room",
  "Basement",
  "Bedroom",
  "Bonus Room",
  "Breakfast Area",
  "Breakfast Nook",
  "Butlers Pantry",
  "Center Hall",
"Cold Room",
"Common Room",
"Computer Room",
"Conservatory",
"Country Kitchen",
"Crawl Space",
"Covered Porch",
"Dance Studio",
"Dark Room",
"Den",
"Den/Office",
"Dinette",
"Dining Area",
"Dining Nook",
"Dining Room",
"Dressing Area",
"Dressing Room",
"Eat-in Kitchen",
"Eating Area",
"Enclosed Balcony",
"Enclosed Porch Heated",
"Enclosed Porch",
"Entrance Hall",
"Exercise Room",
"Family Kitchen",
"Family Room",
"Family Room/Fireplace",
"Finished Attic",
"Flex Room",
"Florida Room",
"Florida/Fireplace",
"Foyer",
"Furnace",
"Gallery",
"Games Room",
"Great Room",
"Guest/Maid Room",
"Gym",
"Hall",
"Home Theatre",
"Hobby Room",
"Jacuzzi Room",
"Kitchen",
"Kitchen/Dining Room",
"Kitchen/Living Room",
"Kitchen With Eating Area",
"Kitchenette",
"Laundry",
"Laundry/Bath",
"Laundry In Utility",
"Library",
"Living Room",
"Living/Dining Room",
"Living/Fireplace",
"Lobby",
"Loft",
"Locker Room",
"Maid Room",
"Master Bedroom",
"Media Room",
"Media/Theatre",
"Mud Room",
"Muskoka Room",
"Nook",
"Nursery",
"Office",
"Other",
"Pantry",
"Patio",
"Photography-dark room",
"Playroom",
"Pool Room",
"Porch",
"Powder Room",
"Projection Room",
"Recreation Room",
"Sauna",
"Sauna/Steam",
"Screened Porch",
"Sewing Room",
"Sitting Room",
"Solarium",
"Sound Studio",
"Steam Room",
"Storage",
"Study",
"Summer Kitchen",
"Sunroom/Fireplace",
"Sun Room",
"Tandem Room",
"Terrace",
"Theater Room",
"Theatre",
"Unfinished",
"Utility",
"Walk-in Closet",
"Walk-In Pantry",
"Wine Cellar",
"Wine Room",
"Work Kitchen",
"Work Rooms",
"Workshop",
];
