import {
  IconCheck,
  IconCirclePlus,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useState } from "react";

const Section = ({
  formData,
  setFormData,
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [sectionData, setSectionData] = useState({
    name: "",
    description: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);

  const toggleForm = () => {
    setIsFormOpen(true);
  };
  const closeForm = () => {
    setIsFormOpen(false);
    setSectionData({
      name: "",
      description: "",
    });
    setEditingIndex(null);
  };

  const updateSection = (index, updateSection) => {
    const updatedSection = [...formData.section];
    updatedSection[index] = updateSection;
    setFormData({
      ...formData,
      section: updatedSection,
    });
  };

  const deleteSection = (index) => {
    const updatedSections = formData.section.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      section: updatedSections,
    });
  };

  const addSection = (section) => {
    setFormData({
      ...formData,
      section: [...formData.section, section],
    });
  };

  const handleSectionFeatureInputChange = (e) => {
    const { name, value } = e.target;
    setSectionData({ ...sectionData, [name]: value });
  };

  const handleAddSections = () => {
    if (editingIndex !== null) {
      updateSection(editingIndex, sectionData);
    } else {
      addSection(sectionData);
    }
    closeForm();
  };

  const handleEditSections = (index) => {
    const sectionsToEdit = formData.section[index];
    setSectionData(sectionsToEdit);
    setEditingIndex(index);
    toggleForm();
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Section</h2>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={toggleForm}
            disabled={isFormOpen}
          >
            Add
            <IconCirclePlus className="ms-1" />
          </button>
        </div>
      </div>
      <div className="video-player mt-3">
        {isFormOpen && (
          <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
            <div className="flex-grow-1">
              <div className="mb-2">
                <label className="form-label">
                  Section Name 
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control rounded-4"
                  placeholder="Section Name"
                  value={sectionData.name}
                  onChange={handleSectionFeatureInputChange}
                 
                />
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={sectionData.description}
                  className="form-control rounded-4"
                  onChange={handleSectionFeatureInputChange}
                />
              </div>
            </div>
            <div className="d-flex flex-md-column flex-row justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={closeForm}
                style={{ width: "40px", height: "40px" }}
              >
                <IconX size={17} />
              </button>
              <button
                type="button"
                className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleAddSections}
                style={{ width: "40px", height: "40px" }}
              >
                <IconCheck size={17} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        {formData.section.length > 0
          ? formData.section.map((section, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <IconGripVertical />
                  </div>
                  <div>
                    <strong className="h2">{section.name}</strong>
                    <p className="mb-0">{section.description}</p>
                  </div>
                </div>
                <div className="d-flex flex-md-column flex-row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => handleEditSections(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconPencilMinus size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => deleteSection(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconTrash size={17} />
                  </button>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

export default Section;
