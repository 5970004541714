import React, { Component } from 'react';

class NotFound extends Component {
    render() {
        return (
            <div className="border-top-wide border-primary d-flex flex-column">
                <div className="page page-center">
                    <div className="container-tight py-4">
                        <div className="empty">
                            <div className="empty-header">404</div>
                            <p className="empty-title">Oops… You just found an error page</p>
                            <p className="empty-subtitle text-secondary">
                                We are sorry but the page you are looking for was not found
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
