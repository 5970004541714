import React, { Component } from 'react';

class Home extends Component {
  render() {
    return (
      <div>
        <h2>Home Page</h2>
        <p>Welcome to the Home Page. This page does not require authentication.</p>
      </div>
    );
  }
}

export default Home;
