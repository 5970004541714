import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { fetchAllListings } from "../../../modules/dashboard/actions";
import { useDispatch, useSelector } from "react-redux";
import SelectedListingCard from "../SelectedListingCard";
import ListingPreview from "../ListingPreview";
import FilterSection from "./filters";
import Loader from "../../Loader";
import TopFilters from "./topFilters";

// Deep equality check to avoid unnecessary updates
const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

const GeneratedListing = () => {
  const { ids } = useParams(); // Get the `:ids` parameter from the URL
  const idArray = ids ? ids.split(",") : []; // Split the IDs into an array
  const dispatch = useDispatch();
  const { allListings, currentPage } = useSelector((state) => state.dashboard);
  const [exportedListings, setExportedListings] = useState([]); // This is the filtered listings state
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const [activeTab, setActiveTab] = useState("all"); // Tabs filter state
  const [userId, setUserId] = useState('');
  const [filterOption, setFilterOptions] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    // Get the current URL from the browser
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get('userId');
    const filterOptions = urlParams.get('filter');
    const titleParam = urlParams.get('title');
    setUserId(userIdParam);
    setFilterOptions(filterOptions);
    setTitle(titleParam);
  }, []);

  // Filters State
  const [propertyType, setPropertyType] = useState([]);
  const [propertyCategory, setPropertyCategory] = useState([]);
  const [status, setStatus] = useState([]);
  const [priceRange, setPriceRange] = useState([1000, 800000]);
  const currentYear = new Date().getFullYear();
  const [yearBuiltRange, setYearBuiltRange] = useState([1900, currentYear]);
  const [areaRange, setAreaRange] = useState([500, 20000]);
  const [bedrooms, setBedrooms] = useState(''); // New state for bedrooms
  const [bathrooms, setBathrooms] = useState('');

  // Fetch all listings on component mount
  useEffect(() => {
    dispatch(fetchAllListings(userId)).then(() => setLoading(false)); // Set loading to false after fetching
  }, [dispatch, userId]);
  
  // Memoize the filtered listings to avoid unnecessary recalculations
  const filteredListings = useMemo(() => {
    if (allListings.length === 0) return [];

    // Step 1: Filter listings by IDs from URL params
    let filteredByIds = allListings.filter((listing) =>
      idArray.includes(listing.id.toString())
    );

    // Step 2: Apply tab filtering logic (activeTab)
    if (activeTab !== "all") {
      filteredByIds = filteredByIds.filter((listing) => {
        if (activeTab === "commercial") {
          return listing.propertyType === "commercial";
        } else if (activeTab === "condo") {
          return listing.propertyType === "condo";
        } else if (activeTab === "freehold") {
          return listing.propertyType === "freehold";
        }
        return true; // Return all for "all" tab
      });
    }

    // Step 3: Apply further filtering logic based on selected filters
    return filteredByIds.filter((listing) => {
      const matchesPropertyType =
        propertyType.length === 0 ||
        propertyType.includes(listing.propertyType);
      const matchesPropertyCategory =
        propertyCategory.length === 0 ||
        propertyCategory.includes(listing.propertyCategory);
      const matchesStatus =
        status.length === 0 || status.includes(listing.status);
        const matchesBedrooms = !bedrooms || listing.buildingInfo.bedroom === parseInt(bedrooms);
        const matchesBathrooms = !bathrooms || listing.buildingInfo.bathroom === parseInt(bathrooms);
        const matchesPriceRange = priceRange[0]===1000 ||(parseFloat(listing.price) >= priceRange[0] && parseFloat(listing.price) <= priceRange[1]);
        const matchesYearBuilt = yearBuiltRange[0]===1900|| (listing.buildingInfo.year >= yearBuiltRange[0] && listing.buildingInfo.year <= yearBuiltRange[1]);
        const matchesArea = areaRange[0] === 500 || (parseFloat(listing.buildingInfo.livingArea) >= areaRange[0] && parseFloat(listing.buildingInfo.livingArea) <= areaRange[1]);

      return (
        matchesPropertyType &&
        matchesPropertyCategory &&
        matchesStatus &&
        matchesBedrooms &&
        matchesBathrooms &&
        matchesPriceRange &&
        matchesYearBuilt &&
        matchesArea
      );
    });
  }, [
    allListings,
    idArray,
    activeTab,
    propertyType,
    propertyCategory,
    status,
    bedrooms,
    bathrooms,
    priceRange,
    yearBuiltRange,
    areaRange,
  ]);

  // Only update exportedListings when filteredListings changes
  useEffect(() => {
    if (!deepEqual(exportedListings, filteredListings)) {
      setExportedListings(filteredListings);
    }
  }, [filteredListings]);

  const openListingPreviewModal = (listing) => {
    const baseUrl = window.location.origin;
    const preview = `${baseUrl}/listing/${listing}?userId=${userId}`;
    window.open(preview, "_blank"); // Opens the link in a new tab
  };

  const closeListingPreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  // const handlePreview = (listingId) => {
  //   const baseUrl = window.location.origin;
  //   const preview = `${baseUrl}/listing/${listingId}?userId=${userId}`;
  //   window.open(preview, "_blank"); // Opens the link in a new tab
  // };

  return (
    <div className="container my-4">
      <h1 className="text-center text-primary">{title && title}</h1>
      <div className="d-flex align-items-center justify-content-center gap-1 mt-5">
        <div className="tab-navigation">
          <div
            className={`tab-item ${activeTab === "all" ? "active" : ""}`}
            onClick={() => setActiveTab("all")}
          >
            All Properties
          </div>
          <div
            className={`tab-item ${activeTab === "commercial" ? "active" : ""}`}
            onClick={() => setActiveTab("commercial")}
          >
            Commercial
          </div>
          <div
            className={`tab-item ${activeTab === "condo" ? "active" : ""}`}
            onClick={() => setActiveTab("condo")}
          >
            Condo
          </div>
          <div
            className={`tab-item ${activeTab === "freehold" ? "active" : ""}`}
            onClick={() => setActiveTab("freehold")}
          >
            Freehold
          </div>
        </div>
      </div>

      {filterOption === "Top" && (
        <div className="filter-section-container-top mb-4">
          <TopFilters
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            propertyCategory={propertyCategory}
            setPropertyCategory={setPropertyCategory}
            status={status}
            setStatus={setStatus}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            yearBuiltRange={yearBuiltRange}
            setYearBuiltRange={setYearBuiltRange}
            areaRange={areaRange}
            setAreaRange={setAreaRange}
            bedrooms={bedrooms}
            setBedrooms={setBedrooms}
            bathrooms={bathrooms}
            setBathrooms={setBathrooms}
          />
        </div>
      )}

      <div className="d-flex gap-5">
      {filterOption === "Default" && (
          <div className="filter-section-container">
          <FilterSection
            propertyType={propertyType}
            setPropertyType={setPropertyType}
            propertyCategory={propertyCategory}
            setPropertyCategory={setPropertyCategory}
            status={status}
            setStatus={setStatus}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            yearBuiltRange={yearBuiltRange}
            setYearBuiltRange={setYearBuiltRange}
            areaRange={areaRange}
            setAreaRange={setAreaRange}
            bedrooms={bedrooms} // Pass bedrooms state
            setBedrooms={setBedrooms} // Pass setBedrooms state updater
            bathrooms={bathrooms} // Pass bathrooms state
            setBathrooms={setBathrooms} // Pass setBathrooms state updater
          />
        </div>)}
        <div className="d-flex flex-column align-items-center">
          <ListingPreview
            isOpen={isPreviewModalOpen}
            closeModal={closeListingPreviewModal}
            selectedListing={selectedListingId}
            listings={allListings}
            page={currentPage}
          />

          <div className="my-3 w-100 d-flex justify-content-center">
            {loading ? (
               <div
               className="spinner-container"
               style={{
                 display: "flex",
                 justifyContent: "center",
                 alignItems: "center",
                 height: "100vh",
                 width: "100%",
                 position: "fixed",
                 top: 0,
                 left: 0,
                 zIndex: 9999, // Ensure it stays above other content
                 backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional, to create an overlay
               }}
             >
               <div className="spinner-border text-primary" role="status"></div>
             </div>
            ) : exportedListings.length > 0 ? (
              <SelectedListingCard
                cards={exportedListings}
                onClick={openListingPreviewModal}
              />
            ) : (
              <div className="no-listing-message text-center">
                <p>No listings available for the selected criteria.</p>
              </div>
            )}
          </div>
        </div>

        {/* Filter Section */}
        
      </div>
    </div>
  );
};

export default GeneratedListing;
