import { IconX } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import listingImage from "../../../assets/images/login-cover.png";
import { fetchMlsMedia } from "../../../modules/dashboard/actions";

const MLSListingSetCard = ({ card, removeCard }) => {
  const [imageUrl, setImageUrl] = useState(null); // To store the image URL
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("$");
  const dispatch = useDispatch();

  const truncate = (input, maxLength) => {
    if (input.length > maxLength) {
      return input.substring(0, maxLength) + "...";
    }
    return input;
  };

  const getCurrencyFromAddress = (address) => {
    if (address.includes("ON") || address.includes("Canada")) {
      return "$"; // Canadian Dollar
    } else if (address.includes("St. Catharines")) {
      return "$"; // Specific city in Canada
    } else if (address.includes("US") || address.includes("United States")) {
      return "$"; // US Dollar
    }
    // Add more rules as needed
    return "$"; // Default to USD
  };

  useEffect(() => {
    const fetchImage = async () => {
      if (card?.MediaChangeTimestamp) {
        setLoading(true);
        try {
          const response = await dispatch(
            fetchMlsMedia("Property", card.MediaChangeTimestamp)
          );
          console.log("Fetched images:", response);

          if (response && Array.isArray(response) && response.length > 0) {
            // Filter for valid media items
            const activeMedia = response.find(
              (item) =>
                item?.MediaURL &&
                item?.MediaStatus === "Active" &&
                item?.MediaType === "image/jpeg"
            );
            setImageUrl(activeMedia?.MediaURL || listingImage);
          } else {
            setImageUrl(listingImage);
          }
        } catch (error) {
          console.error("Failed to fetch image:", error);
          setImageUrl(listingImage);
        } finally {
          setLoading(false);
        }
      } else {
        setImageUrl(listingImage);
      }
    };

    fetchImage();

    if (card?.UnparsedAddress) {
      const detectedCurrency = getCurrencyFromAddress(card.UnparsedAddress);
      setCurrency(detectedCurrency);
    }
  }, [card, dispatch]);
  return (
    <div>
      <div key={card.ListingKey} className="property-list-item">
        <div className="d-flex gap-2 align-items-center w-100">
          <img
            src={imageUrl || listingImage}
            alt={card.ListingKey || "Default"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = listingImage;
            }}
            className="property-thumb"
          />
    
            <div className="property-info">
              <div className="price h4">
                {currency}{" "}
                {card.ListPrice && Number(card.ListPrice).toLocaleString()}
              </div>
              <div className="description">
                {truncate(`${card.PublicRemarks}`, 30)}
              </div>
            </div>
        
        </div>
        <div
          className="property-actions cursor-pointer rounded-5 bg-gray-900 p-1"
          onClick={() => removeCard(card.ListingKey)}
        >
          <IconX />
        </div>
      </div>
    </div>
  );
};

export default MLSListingSetCard;
