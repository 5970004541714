import React, { useEffect, useState } from "react";
import SelectedListingCard from "./SelectedListingCard";
import {
  IconArrowUpRight,
  IconCheck,
  IconClipboard,
  IconX,
} from "@tabler/icons-react";
import SmallListingCard from "./ListingCodeGenerator/SmallListingCard";
import ListingSet from "./ListingCodeGenerator/ListingSet";
import IndividualListing from "./ListingCodeGenerator/IndividualListing";
import ListingSearch from "./ListingCodeGenerator/ListingSearch";
import { useSelector } from "react-redux";
import MlsSmallListingCard from "./ListingCodeGenerator/MLSSmallListingCard";
import MlsListingSet from "./ListingCodeGenerator/MlsListingSet";

const SelectedListingPreview = ({
  isOpen,
  closeModal,
  exportedListings,
  mls,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  // const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [scriptLink, setScriptLink] = useState("");
  const [selectedListing, setSelected] = useState(null);
  const [listingIndividualArray, setListingIndividualArray] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedMLsCards, setMLsCards] = useState([]);
  const [copied, setCopied] = useState(false);
  const [activeTab, setActiveTab] = useState("Listing Set"); // Default to Listing Set
  const [activeFilter, setActiveFilter] = useState(""); // Default to Active filter
  const [activeMLSFilter, setMLSActiveFilter] = useState(""); // Default to Active filter
  const [activePropertyType, setActivePropertyType] = useState("");
  const [activeMlsPropertyType, setMlsActivePropertyType] = useState("");
  const [selectedSort, setSelectedSort] = useState("All Listings");
  const [selectedStatus, setSelectedStatus] = useState("My Listings");
  const [selectedMLSStatus, setSelectedMLSStatus] = useState("MLS Listings");
  const [filterOption, setFilterOptions] = useState("Default");
  const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
  const [listingSearchActiveTab, setListingSearchActiveTab] =
    useState("List IDX Search");
  const [filteredListingIds, setFilteredListingIds] = useState([]);
  const profile = useSelector((state) => state.auth.profile);
  const [customFilter, setCustomFilter] = useState("");
  const [listingType, setListingType] = useState("");

  console.log(mls);

  const handleListingSearchCustomFilter = (filterValue) => {
    setCustomFilter(filterValue);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isMLsDropdownOpen, setMLsDropdownOpen] = useState(false);
  const [isPropertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
  const [isPropertyMlsDropdownOpen, setPropertyMlsDropdownOpen] =
    useState(false);
  const [isListingTypeDropdownOpen, setListingTypeDropdownOpen] =
    useState(false);

  const individualListing = async (agent) => {
    // handleTabChange("Individual Listing");
    if (activeTab === "Individual Listing") {
      setSelected(agent); // Update selectedListing with the new single listing

      setListingIndividualArray([agent]);
    }
  };

  const removeIndividualListing = (listingId) => {
    setListingIndividualArray((prevArray) =>
      prevArray.filter((listing) => listing.id !== listingId)
    );
    if (selectedListing && selectedListing.id === listingId) {
      setSelected(null); // Clear selectedListing if it's the one being removed
    }
  };

  console.log("Selected Listing: ", selectedListing);
  console.log("Listing Individual Array: ", listingIndividualArray);

  // Function to add card ID to the array
  const addCardIdToArray = (cardId) => {
    setSelectedCards((prev) => {
      if (!prev.includes(cardId)) {
        return [...prev, cardId]; // Add the card ID if it's not already in the array
      }
      return prev; // Return the same array if cardId is already present
    });
    console.log(selectedCards);
  };

  const addMLsCardIdToArray = (cardId) => {
    setMLsCards((prev) => {
      if (!prev.includes(cardId)) {
        return [...prev, cardId]; // Add the card ID if it's not already in the array
      }
      return prev; // Return the same array if cardId is already present
    });
    console.log(selectedMLsCards);
  };
  console.log(exportedListings);
  const listingSetListings = exportedListings.filter((listing) =>
    selectedCards.includes(listing.id)
  );

  const mlsListingSetListings = mls.filter((listing) =>
    selectedMLsCards.includes(listing.ListingKey)
  );

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen, selectedListing]);

  const filteredListings = exportedListings.filter((listing) => {
    const statusMatch = activeFilter ? listing.status === activeFilter : true;
    const propertyMatch = activePropertyType
      ? listing.propertyType === activePropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const filteredMLSListings = mls.filter((listing) => {
    const statusMatch = activeMLSFilter
      ? listing.MlsStatus === activeMLSFilter
      : true;
    const propertyMatch = activeMlsPropertyType
      ? listing.PropertyType === activeMlsPropertyType
      : true;
    return statusMatch && propertyMatch; // Both filters should match
  });

  const filteredCards = listingSetListings.filter((card) => {
    if (selectedStatus === "active") {
      return card.status === "active"; // Assume card.status holds the status
    } else if (selectedStatus === "sold") {
      return card.status === "sold";
    } else if (selectedStatus === "on hold") {
      return card.status === "on hold";
    } else if (selectedStatus === "pending") {
      return card.status === "pending";
    }
    return true; // Default, return all
  });

  const filteredMLsCards = mlsListingSetListings.filter((card) => {
    if (selectedMLSStatus === "New") {
      return card.MlsStatus === "New"; // Assume card.status holds the status
    } else if (selectedMLSStatus === "Price Change") {
      return card.MlsStatus === "Price Change";
    }
    return true; // Default, return all
  });

  console.log("Filtered Cards: ", filteredCards);

  const sortedCards = filteredCards.sort((a, b) => {
    if (selectedSort === "Price (Low to High)") {
      return a.price - b.price;
    } else if (selectedSort === "Price (High to Low)") {
      return b.price - a.price;
    } else if (selectedSort === "Listed Date (Recent First)") {
      return new Date(b.listedDate) - new Date(a.listedDate);
    } else if (selectedSort === "Listed Date (Oldest First)") {
      return new Date(a.listedDate) - new Date(b.listedDate);
    }
    return 0; // Default, no sorting
  });

  const sortedMlsCards = filteredMLsCards.sort((a, b) => {
    if (selectedSort === "Price (Low to High)") {
      return a.ListPrice - b.ListPrice;
    } else if (selectedSort === "Price (High to Low)") {
      return b.ListPrice - a.ListPrice;
    } else if (selectedSort === "Listed Date (Recent First)") {
      return new Date(b.MediaChangeTimestamp) - new Date(a.MediaChangeTimestamp);
    } else if (selectedSort === "Listed Date (Oldest First)") {
      return new Date(a.MediaChangeTimestamp) - new Date(b.MediaChangeTimestamp);
    }
    return 0; // Default, no sorting
  });

  const removeCard = (cardId) => {
    setSelectedCards((prevCards) => prevCards.filter((id) => id !== cardId));
  };
  const removeMlsCard = (cardId) => {
    setMLsCards((prevCards) =>
      prevCards.filter((ListingKey) => ListingKey !== cardId)
    );
  };

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const handleFilter = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleFilterMLS = (e) => {
    setSelectedMLSStatus(e.target.value);
  };
  const handleFilterOptions = (e) => {
    setFilterOptions(e.target.value);
  };
  const handleFilteredListingIds = (ids) => {
    setFilteredListingIds(ids);
  };

  // Generate the link for sharing the exported listings
  const handleGenerateLink = () => {
    const baseUrl = window.location.origin;
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let generatedLink = "";
    if (activeTab === "Listing Search") {
      if (listingSearchActiveTab === "List IDX Search") {
        if (filteredListingIds.length > 0 && listingType !== "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          generatedLink = `${baseUrl}/list-idx-search?listingIds=${listingIdsParam}&userId=${profile.id}`;
        } else if (filteredListingIds.length > 0 && listingType === "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          generatedLink = `${baseUrl}/list-idx-search-mls?listingIds=${listingIdsParam}&userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
        }
      } else if (listingSearchActiveTab === "Compact Search Form") {
        if (listingType !== "MLS") {
          generatedLink = `${baseUrl}/compact-search-form?userId=${profile.id}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            generatedLink += `&${params.toString()}`;
          }
        } else if (listingType === "MLS") {
          generatedLink = `${baseUrl}/compact-search-form-mls?userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            generatedLink += `&${params.toString()}`;
          }
        }
      }
    } else if (activeTab === "Listing Set" && listingType === "MLS") {
      listingIds = sortedMlsCards
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      userId = profile.id;
      generatedLink = `${baseUrl}/listing-code-mls/${listingIds}?userId=${profile.id}&filter=${filterOption}&mls=${profile.mlsNumber}&type=${profile.userType}`;
    } else {
      listingIds = sortedCards.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId = sortedCards.length > 0 ? sortedCards[0].userId : "";
      generatedLink = `${baseUrl}/listing-code/${listingIds}?userId=${profile.id}&filter=${filterOption}`;
    }
    setGeneratedLink(generatedLink);
    setIsModalLinkOpen(true);
  };

  const handleGenerateScriptLink = () => {
    handleGenerateLink();
    const baseUrl = window.location.origin; // Gets the base URL, such as http://localhost:3000
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let scriptUrl = "";

    if (activeTab === "Listing Search") {
      if (listingSearchActiveTab === "List IDX Search") {
        if (filteredListingIds.length > 0 && listingType !== "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          scriptUrl = `${baseUrl}/list-idx-search?listingIds=${listingIdsParam}&userId=${profile.id}`;
        } else if (filteredListingIds.length > 0 && listingType === "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          scriptUrl = `${baseUrl}/list-idx-search-mls?listingIds=${listingIdsParam}&userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
        }
      } else if (listingSearchActiveTab === "Compact Search Form") {
        if (listingType !== "MLS") {
          scriptUrl = `${baseUrl}/compact-search-form?userId=${profile.id}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            scriptUrl += `&${params.toString()}`;
          }
        } else if (listingType === "MLS") {
          scriptUrl = `${baseUrl}/compact-search-form-mls?userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            scriptUrl += `&${params.toString()}`;
          }
        }
      }
    } else if (activeTab === "Listing Set" && listingType === "MLS") {
      listingIds = sortedMlsCards
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      userId = profile.id;
      scriptUrl = `${baseUrl}/listing-code-mls/${listingIds}?userId=${profile.id}&filter=${filterOption}&mls=${profile.mlsNumber}&type=${profile.userType}`;
    } else {
      listingIds = sortedCards.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId = sortedCards.length > 0 ? sortedCards[0].userId : "";
      scriptUrl = `${baseUrl}/listing-code/${listingIds}?userId=${profile.id}&filter=${filterOption}`;
    }

    // Generate the dynamic script to fetch and display listings from the correct route
    const scriptTag = `<script>(function() {
      const scriptUrl = "${scriptUrl}";
      const container = document.createElement('div');
      container.id = 'listing-container';
      container.style.height = "100vh";
      document.body.appendChild(container);

      const iframe = document.createElement('iframe');
      iframe.src = scriptUrl;
      iframe.width = "100%";
      iframe.style.height = "100vh";
      iframe.style.border = "none";
      container.appendChild(iframe);
    })();
  </script>`;

    setScriptLink(scriptTag);
    setIsModalLinkOpen(true);
  };

  // Function to display the script code in the modal
  const renderEmbedScriptCode = () => {
    return scriptLink;
  };

  const handlePreview = () => {
    const baseUrl = window.location.origin;
    let listingIds = ""; // Initialize the listing IDs string
    let userId = "";
    let generatedLink = "";
    if (activeTab === "Listing Search") {
      if (listingSearchActiveTab === "List IDX Search") {
        if (filteredListingIds.length > 0 && listingType !== "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          generatedLink = `${baseUrl}/list-idx-search?listingIds=${listingIdsParam}&userId=${profile.id}`;
        } else if (filteredListingIds.length > 0 && listingType === "MLS") {
          const listingIdsParam = filteredListingIds.join(",");
          generatedLink = `${baseUrl}/list-idx-search-mls?listingIds=${listingIdsParam}&userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
        }
      } else if (listingSearchActiveTab === "Compact Search Form") {
        if (listingType !== "MLS") {
          generatedLink = `${baseUrl}/compact-search-form?userId=${profile.id}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            generatedLink += `&${params.toString()}`;
          }
        } else if (listingType === "MLS") {
          generatedLink = `${baseUrl}/compact-search-form-mls?userId=${profile.id}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
          if (customFilter) {
            const params = new URLSearchParams();
            params.append("customFilter", customFilter);
            generatedLink += `&${params.toString()}`;
          }
        }
      }
    } else if (activeTab === "Listing Set" && listingType === "MLS") {
      listingIds = sortedMlsCards
        .map((listing) => listing.ListingKey)
        .join(","); // Joins listing IDs into a comma-separated string
      userId = profile.id;
      generatedLink = `${baseUrl}/listing-code-mls/${listingIds}?userId=${userId}&filter=${filterOption}&mls=${profile?.mlsNumber}&type=${profile?.userType}`;
    } else {
      listingIds = sortedCards.map((listing) => listing.id).join(","); // Joins listing IDs into a comma-separated string
      userId = sortedCards.length > 0 ? sortedCards[0].userId : "";
      generatedLink = `${baseUrl}/listing-code/${listingIds}?userId=${profile.id}&filter=${filterOption}`;
    }
    setGeneratedLink(generatedLink);
    if (generatedLink) {
      window.open(generatedLink, "_blank"); // Opens the link in a new tab
    }
  };

  const closeModalLink = () => {
    setIsModalLinkOpen(false);
  };

  console.log(listingSearchActiveTab);

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(renderEmbedScriptCode()).then(
        () => {
          console.log("Link copied successfully using Clipboard API");
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error(
            "Failed to copy using Clipboard API, using fallback: ",
            err
          );
          handleCopyLinkFallback(); // Use fallback if Clipboard API fails
        }
      );
    } else {
      console.log("Clipboard API not available, using fallback");
      handleCopyLinkFallback(); // Fallback for unsupported browsers
    }
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = renderEmbedScriptCode();
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy"); // Older API for copying
      console.log("Link copied using fallback method");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Unable to copy text", err);
    }
    document.body.removeChild(textArea);
  };

  const renderContent = () => {
    if (activeTab === "Listing Set" && listingType === "MLS") {
      return (
        <MlsListingSet
          cards={sortedMlsCards}
          sort={handleSortChange}
          filter={handleFilterMLS}
          selectedSort={selectedSort}
          selectedFilter={selectedMLSStatus}
          removeCard={removeMlsCard}
          selectedFilterOption={filterOption}
          setfilterOptions={handleFilterOptions}
        />
      );
    }

    switch (activeTab) {
      case "Listing Set":
        return (
          <ListingSet
            cards={sortedCards}
            sort={handleSortChange}
            filter={handleFilter}
            selectedSort={selectedSort}
            selectedFilter={selectedStatus}
            removeCard={removeCard}
            selectedFilterOption={filterOption}
            setfilterOptions={handleFilterOptions}
          />
        );
      case "Listing Search":
        return <ListingSearch exportedListings={exportedListings} />;
      default:
        return <ListingSet cards={listingSetListings} />;
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleMLSDropdown = () => {
    setMLsDropdownOpen(!isMLsDropdownOpen);
  };

  const togglePropertyDropdown = () => {
    setPropertyDropdownOpen(!isPropertyDropdownOpen);
  };
  const toggleMlsPropertyDropdown = () => {
    setPropertyMlsDropdownOpen(!isPropertyMlsDropdownOpen);
  };
  const toggleListingTypeDropdown = () => {
    setListingTypeDropdownOpen(!isListingTypeDropdownOpen);
  };

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    setDropdownOpen(false);
  };
  const handleMLSFilterChange = (filter) => {
    setMLSActiveFilter(filter);
    setMLsDropdownOpen(false);
  };

  const handlePropertyTypeChange = (type) => {
    setActivePropertyType(type); // Set the selected property type filter
    setPropertyDropdownOpen(false); // Close the dropdown after selection
  };
  const handleMlsPropertyTypeChange = (type) => {
    setMlsActivePropertyType(type); // Set the selected property type filter
    setPropertyMlsDropdownOpen(false); // Close the dropdown after selection
  };
  const handleListingTypeTypeChange = (type) => {
    setListingType(type); // Set the selected property type filter
    setListingTypeDropdownOpen(false); // Close the dropdown after selection
  };
  useEffect(() => {
    console.log("Selected Cards Updated: ", selectedCards);
  }, [selectedCards]); // This runs every time `selectedCards` changes

  return (
    <>
      {isModalOpen && (
        <div className="modal fade show d-block">
          <div
            className="modal-dialog custom-modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div>
                <button
                  className="btn btn-outline-black btn-pill"
                  onClick={toggleListingTypeDropdown}
                  style={{ width: "150px" }}
                >
                  {listingType ? `Type: ${listingType}` : "Type: Manual"}
                </button>

                {/* Dropdown Menu */}
                {isListingTypeDropdownOpen && (
                  <div className="dropdown-menu show">
                    <button
                      className="dropdown-item"
                      onClick={() => handleListingTypeTypeChange("Manual")}
                    >
                      Manual
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => handleListingTypeTypeChange("MLS")}
                    >
                      MLS
                    </button>
                  </div>
                )}
              </div>
              <div className="modal-header">
                <h5 className="modal-title mt-3 mb-3">Short Code Generator</h5>
                <button
                  type="button"
                  className="btn-close mb-5 mt-2 me-4"
                  onClick={() => {
                    closeModal();
                    setListingSearchActiveTab("List IDX Search");
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="d-flex gap-3 mb-3 mx-auto p-2 border justify-content-center rounded-4">
                <div className="">
                  <button
                    className={`btn ${
                      activeTab === "Listing Set"
                        ? "btn btn-outline-primary btn-pill bg-blue-lt"
                        : "btn-light"
                    }`}
                    onClick={() => handleTabChange("Listing Set")}
                  >
                    Listing Set
                  </button>
                  {/* <button
                    className={`btn ${
                      activeTab === "Individual Listing"
                        ? "btn btn-outline-primary btn-pill bg-blue-lt"
                        : "btn-light"
                    }`}
                    onClick={() => handleTabChange("Individual Listing")}
                  >
                    Individual Listing
                  </button> */}
                  <button
                    className={`btn ${
                      activeTab === "Listing Search"
                        ? "btn btn-outline-primary btn-pill bg-blue-lt"
                        : "btn-light"
                    }`}
                    onClick={() => handleTabChange("Listing Search")}
                  >
                    Listing Search
                  </button>
                </div>
              </div>
              <div className="modal-body">
                {activeTab !== "Listing Search" ? (
                  <div className="row">
                    <div className="col-md-6 bg-gray-400 p-1 mb-3  rounded-4 border">
                      <div className="d-flex gap-3 mb-3 p-2 rounded-4 ">
                        {listingType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleDropdown}
                            >
                              {activeFilter
                                ? `Filter: ${activeFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("active")}
                                >
                                  Active
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("sold")}
                                >
                                  Sold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("on hold")}
                                >
                                  On hold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("pending")}
                                >
                                  Pending
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("deleted")}
                                >
                                  Deleted
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("terminated")
                                  }
                                >
                                  Terminated
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("expired")}
                                >
                                  Expired
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("cancelled")
                                  }
                                >
                                  Cancelled
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("unknown")}
                                >
                                  Unknown
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterChange("wait approval")
                                  }
                                >
                                  Wait approval
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMLSDropdown}
                            >
                              {activeMLSFilter
                                ? `Filter: ${activeMLSFilter}`
                                : "Filter Listings"}
                            </button>

                            {/* Dropdown Menu */}
                            {isMLsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("New")}
                                >
                                  New
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMLSFilterChange("Price Change")
                                  }
                                >
                                  Price Change
                                </button>
                                {/* <button
                                className="dropdown-item"
                                onClick={() => handleFilterChange("on hold")}
                              >
                                On hold
                              </button>
                             */}
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMLSFilterChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType !== "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={togglePropertyDropdown}
                            >
                              {activePropertyType
                                ? `Type: ${activePropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("condo")
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handlePropertyTypeChange("freehold")
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handlePropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                        {listingType === "MLS" && (
                          <div>
                            <button
                              className="btn btn-outline-black btn-pill"
                              onClick={toggleMlsPropertyDropdown}
                            >
                              {activeMlsPropertyType
                                ? `Type: ${activeMlsPropertyType}`
                                : "Filter by Property Type"}
                            </button>

                            {/* Dropdown Menu for Property Type */}
                            {isPropertyMlsDropdownOpen && (
                              <div className="dropdown-menu show">
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange("Commercial")
                                  }
                                >
                                  Commercial
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange("Residential Condo & Other")
                                  }
                                >
                                  Condo
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleMlsPropertyTypeChange("Residential Freehold")
                                  }
                                >
                                  Freehold
                                </button>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleMlsPropertyTypeChange("")}
                                >
                                  Show All
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-auto ms-auto"></div>
                      <div className="">
                        {listingType !== "MLS" && (
                          //  <div className="row gap-1">

                          <SmallListingCard
                            cards={filteredListings}
                            onClick={individualListing}
                            addCardIdToArray={addCardIdToArray}
                          />
                          // </div>
                        )}
                        {listingType === "MLS" && (
                          <div className="row gap-1">
                            {filteredMLSListings.map((listing) => (
                              <MlsSmallListingCard
                                card={listing}
                                onClick={individualListing}
                                addCardIdToArray={addMLsCardIdToArray}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">{renderContent()}</div>
                  </div>
                ) : (
                  <>
                    {listingType !== "MLS" && (
                      <ListingSearch
                        exportedListings={exportedListings}
                        setListingSearchActiveTab={setListingSearchActiveTab}
                        onFilter={handleFilteredListingIds}
                        onCustomFilterChange={handleListingSearchCustomFilter}
                        type={listingType}
                        activeTab={listingSearchActiveTab}
                      />
                    )}
                    {listingType === "MLS" && (
                      <ListingSearch
                        exportedListings={mls}
                        setListingSearchActiveTab={setListingSearchActiveTab}
                        onFilter={handleFilteredListingIds}
                        onCustomFilterChange={handleListingSearchCustomFilter}
                        type={listingType}
                        activeTab={listingSearchActiveTab}
                      />
                    )}
                  </>
                )}
              </div>
              <div className="d-flex justify-content-end mt-3 me-4 gap-3">
                <button
                  type="submit"
                  className="btn btn-primary btn-pill"
                  onClick={handleGenerateScriptLink}
                  disabled={
                    activeTab !== "Listing Search" &&
                    mlsListingSetListings.length === 0 &&
                    sortedCards.length === 0 &&
                    selectedCards.length === 0 &&
                    selectedListing === null
                  }
                >
                  Get Short Code
                </button>
                <button
                  className="btn btn-outline-primary btn-pill ms-2"
                  onClick={handlePreview}
                  disabled={
                    activeTab !== "Listing Search" &&
                    mlsListingSetListings.length === 0 &&
                    sortedCards.length === 0 &&
                    selectedCards.length === 0 &&
                    selectedListing === null
                  }
                >
                  Preview <IconArrowUpRight className="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalLinkOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Listing Link</h3>
                <IconX
                  onClick={closeModalLink}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <textarea
                    // type="text"
                    className="form-control rounded-4 flex-grow-1 textarea-input pe-5"
                    value={renderEmbedScriptCode()}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-4"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedListingPreview;
