import {
  IconCheck,
  IconClipboard,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useRef, useState } from "react";

const Videos = ({ formData, setFormData }) => {
  const [videos, setVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState(formData.videos || []);
  const [youtubeLink, setYoutubeLink] = useState(""); // To hold YouTube link
  const [newVideoDetails, setNewVideoDetails] = useState({
    name: "",
    caption: "",
  });
  const [editingIndex, setEditingIndex] = useState(-1);
  const fileInputRef = useRef(null);

  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    const videoUrls = files.map((file) => ({
      videoUrl: URL.createObjectURL(file),
      name: file.name,
      videoId: file.name,
      caption: "",
      type: "",
      file,
    }));
    setVideos((prevVideos) => [...prevVideos, ...videoUrls]);
    event.target.value = null;
  };

  // const handleButtonClick = () => {
  //   fileInputRef.current.click();
  // };

  const handleInputChange = (index, field, value, isSelected) => {
    const targetArray = isSelected ? selectedVideos : videos;
    const newVideos = [...targetArray];
    newVideos[index][field] = value;
    if (isSelected) {
      setSelectedVideos(newVideos);
      setFormData((prevData) => ({
        ...prevData,
        videos: newVideos,
      }));
    } else {
      setVideos(newVideos);
    }
  };

  const handleRemoveVideo = (index, isSelected) => {
    const targetArray = isSelected ? selectedVideos : videos;
    const newVideos = targetArray.filter((_, i) => i !== index);
    if (isSelected) {
      setSelectedVideos(newVideos);
      setFormData((prevData) => ({
        ...prevData,
        videos: newVideos,
      }));
    } else {
      setVideos(newVideos);
    }
  };

  const handleSelectVideo = (index) => {
    const videoToSelect = videos[index];
    setSelectedVideos((prevSelected) => [...prevSelected, videoToSelect]);
    // setFormData({
    //   ...formData,
    //   videos: [...formData.videos, videoToSelect],
    // });
    setFormData({
      ...formData,
      videos: [...formData.videos, videoToSelect],
    });
    setVideos([]);
  };

  const handleYouTubeAdd = () => {
    let videoUrl = "";
    let videoId = "";
    let name = newVideoDetails.name;
    let caption = newVideoDetails.caption;
  
    // Check if the link is a YouTube or Google Drive link
    if (youtubeLink.includes("youtube.com") || youtubeLink.includes("youtu.be")) {
      // YouTube link handling
      videoId = youtubeLink.split("v=")[1] || youtubeLink.split("/").pop();
      
      videoUrl = `https://www.youtube.com/embed/${videoId}`;
      name = newVideoDetails.name;
      caption = newVideoDetails.caption;
    } else if (youtubeLink.includes("drive.google.com")) {
      // Google Drive link handling
      videoId = getGoogleDriveId(youtubeLink);
      if (videoId) {
        videoUrl = `https://drive.google.com/file/d/${videoId}/preview`;
      } else {
        alert("Please enter a valid Google Drive URL.");
        return;
      }
    } else {
      alert("Please enter a valid YouTube or Google Drive URL.");
      return;
    }
  
    // Add the video to the selectedVideos array
    const newVideo = {
      videoUrl,
      name,
      caption,
      type: youtubeLink.includes("drive.google.com") ? "google-drive" : "youtube",
      videoId,
    };
  
    setVideos((prevSelected) => [...prevSelected, newVideo]);
    // setFormData({
    //   ...formData,
    //   videos: [...formData.videos, newVideo],
    // });
  
    // Clear input fields
    setYoutubeLink("");
    setNewVideoDetails({ name: "", caption: "" });
  };
  
  const getGoogleDriveId = (url) => {
    const match = url.match(/\/d\/([a-zA-Z0-9_-]+)/);
    return match ? match[1] : null;
  };
  

  const startEditing = (index) => {
    const videoToEdit = selectedVideos[index];
    setEditingIndex(index);
    setNewVideoDetails({
      name: videoToEdit.name,
      caption: videoToEdit.caption,
    });
  };

  const saveEditedVideo = (index) => {
    const updatedVideos = [...selectedVideos];
    updatedVideos[index] = {
      ...updatedVideos[index],
      name: newVideoDetails.name,
      caption: newVideoDetails.caption,
    };
    setSelectedVideos(updatedVideos);
    setFormData({
      ...formData,
      videos: updatedVideos,
    });
    setEditingIndex(-1);
    setNewVideoDetails({ name: "", caption: "" });
  };

  const cancelEditing = () => {
    setEditingIndex(-1);
    setNewVideoDetails({ name: "", caption: "" });
  };

  return (
    <>
      <div className="video-uploader">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="text-primary">Videos</h2>
          </div>
          <div className="mb-3">
            <input
              type="file"
              accept="video/*"
              multiple
              className="form-control d-none"
              ref={fileInputRef}
              onChange={handleVideoUpload}
            />
          </div>
        </div>
        <div className="mb-3">
          <label>
            Video Link
          </label>
          <div className="d-flex w-100 justify-content-between align-items-center gap-2">
            <div className="input-icon mb-3 w-100">
              <input
                type="text"
                name="videoLink"
                placeholder="Video Link"
                className="form-control form-control-rounded"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)} // Capture the YouTube link
              />
              <span className="input-icon-addon">
                <IconClipboard />
              </span>
            </div>
            <button
              className="btn btn-outline-primary btn-pill"
              onClick={handleYouTubeAdd}
            >
              Add
            </button>
          </div>
          {/* {youtubeLink && (
            <div className="d-flex flex-column gap-2">
              <input
                type="text"
                placeholder="Video Name"
                value={newVideoDetails.name}
                onChange={(e) =>
                  setNewVideoDetails({
                    ...newVideoDetails,
                    name: e.target.value,
                  })
                }
              />
              <input
                type="text"
                placeholder="Video Caption"
                value={newVideoDetails.caption}
                onChange={(e) =>
                  setNewVideoDetails({
                    ...newVideoDetails,
                    caption: e.target.value,
                  })
                }
              />
            </div>
          )} */}
        </div>
        <div className="video-player mt-3">
          {videos.map((video, index) => (
            <div
              key={index}
              className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
            >
              <div className="d-flex align-items-center gap-2">
                <div>
                  <IconGripVertical />
                </div>
                <div
                  className="position-relative"
                  style={{ width: "150px", height: "150px" }}
                >
                  <iframe
                    title="video"
                      src={video.videoUrl}
                      className="me-3 rounded-4 object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Name</strong>
                  </label>
                  <input
                    type="text"
                    placeholder="Video name"
                    value={video.name}
                    className="form-control form-control-rounded"
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value, false)
                    }
                  />
                </div>
                <div className="mb-2">
                  <label className="form-label">
                    <strong>Caption</strong>
                  </label>
                  <input
                    type="text"
                    placeholder="Other Info"
                    value={video.caption}
                    className="form-control form-control-rounded"
                    onChange={(e) =>
                      handleInputChange(index, "caption", e.target.value, false)
                    }
                  />
                </div>
              </div>
              <div className="d-flex flex-md-column flex-row justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={() => handleRemoveVideo(index, false)}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconX size={17} />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={() => handleSelectVideo(index)}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconCheck size={17} />
                </button>
              </div>
            </div>
          ))}
        </div>

        <div className="video-player mt-3">
          {selectedVideos.map((video, index) => (
            <div
              key={index}
              className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
            >
              <div className="d-flex align-items-center gap-2">
                <div>
                  <IconGripVertical />
                </div>
                <div
                  className="position-relative"
                  style={{ width: "150px", height: "150px" }}
                >
                  {video.type === "youtube" ? (
                    <iframe
                    title="video"
                      src={video.videoUrl}
                      className="me-3 rounded-4 object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <iframe
                    title="video"
                      src={video.videoUrl}
                      className="me-3 rounded-4 object-fit-cover"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </div>
                
              </div>

              {editingIndex === index ? (
                <>
                  <div className="flex-grow-1">
                    <div className="mb-2">
                      <label className="form-label">
                        <strong>Name</strong>
                      </label>
                      <input
                        type="text"
                        value={newVideoDetails.name}
                        className="form-control form-control-rounded"
                        onChange={(e) =>
                          setNewVideoDetails({
                            ...newVideoDetails,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="mb-2">
                      <label className="form-label">
                        <strong>Caption</strong>
                      </label>
                      <input
                        type="text"
                        value={newVideoDetails.caption}
                        className="form-control form-control-rounded"
                        onChange={(e) =>
                          setNewVideoDetails({
                            ...newVideoDetails,
                            caption: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-md-column flex-row justify-content-between">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                      style={{ width: "40px", height: "40px" }}
                      onClick={cancelEditing}
                    >
                      <IconX size={17} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-centers"
                      style={{ width: "40px", height: "40px" }}
                      onClick={() => saveEditedVideo(index)}
                    >
                      <IconCheck size={17} />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-grow-1">
                    <div className="mb-2">
                      <h2 className="">
                        <strong>{video.name}</strong>
                      </h2>
                    </div>
                    <div className="mb-2">{video.caption}</div>
                  </div>
                  
                  <div className="d-flex flex-md-column flex-row justify-content-between">
                    <button
                      className="btn btn-ghost btn-circle border-0"
                      onClick={() => startEditing(index)}
                    >
                      <IconPencilMinus />
                    </button>
                    <button
                      className="btn btn-ghost btn-circle border-0"
                      onClick={() => handleRemoveVideo(index, true)}
                    >
                      <IconTrash />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Videos;
