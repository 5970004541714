import React, { useState } from "react";
import { Range } from "react-range";

const MLSTopFilters = ({
  propertyType,
  setPropertyType,
  propertyCategory,
  setPropertyCategory,
  status,
  setStatus,
  priceRange,
  setPriceRange,
  yearBuiltRange,
  setYearBuiltRange,
  areaRange,
  setAreaRange,
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
}) => {
    const statusOptions = [
        "New",
        "Price Change",
        // "on hold",
        // "pending",
        // "deleted",
        // "terminated",
        // "expired",
        // "cancelled",
        // "unknown",
        // "wait approval",
      ];
  const currentYear = new Date().getFullYear();

  const priceOptions = [
    { label: "Under $200K", min: 0, max: 200000 },
    { label: "$200K - $500K", min: 200000, max: 500000 },
    { label: "$500K - $1M", min: 500000, max: 1000000 },
    { label: "> $1M", min: 1000000, max: Infinity },
  ];

  const yearOptions = [
    { label: "2020 - Present", min: 2020, max: currentYear },
    { label: "2000 - 2019", min: 2000, max: 2019 },
    { label: "1980 - 1999", min: 1980, max: 1999 },
    { label: "1960 - 1979", min: 1960, max: 1979 },
    { label: "1940 - 1959", min: 1940, max: 1959 },
    { label: "Before 1940", min: 0, max: 1940 },
  ];

  const areaOptions = [
    { label: "Below 500 sq. ft", min: 0, max: 500 },
    { label: "500 - 1000 sq. ft", min: 500, max: 1000 },
    { label: "1000 - 2000 sq. ft", min: 1000, max: 2000 },
    { label: "2000 - 3000 sq. ft", min: 2000, max: 3000 },
    { label: "3000 - 5000 sq. ft", min: 3000, max: 5000 },
    { label: "5000 - 10000 sq. ft", min: 5000, max: 10000 },
    { label: "Above 10000 sq. ft", min: 10000, max: Infinity },
  ];

  const trackStyle = {
    height: "2px",
    backgroundColor: "#ccc",
  };

  const selectedTrackStyle = {
    height: "4px", // Thicker height for the selected range
    backgroundColor: "#000", // Black color for the selected range
  };

  const thumbStyle = {
    height: "16px",
    width: "16px",
    backgroundColor: "#1a1a1a",
    borderRadius: "50%",
    border: "2px solid #fff",
  };

  const rangeContainerStyle = {
    marginTop: "30px", // Adjust this for the desired top and bottom margin
  };

  return (
    <div className="">
      <h2 className="mb-3">Filter By</h2>

      {/* Property Type */}
      <div className="d-flex justify-content-between">
        <div className="mb-5">
          <h4 className="mb-2">Property Type</h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="commercial"
              checked={propertyType.includes("Commercial")}
              onChange={() =>
                setPropertyType((prev) =>
                  prev.includes("Commercial")
                    ? prev.filter((item) => item !== "Commercial")
                    : [...prev, "Commercial"]
                )
              }
            />
            <label className="form-check-label" htmlFor="commercial">
              Commercial
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="condo"
              checked={propertyType.includes("Residential Condo & Other")}
              onChange={() =>
                setPropertyType((prev) =>
                  prev.includes("Residential Condo & Other")
                    ? prev.filter((item) => item !== "Residential Condo & Other")
                    : [...prev, "Residential Condo & Other"]
                )
              }
            />
            <label className="form-check-label" htmlFor="condo">
              Condo
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="freehold"
              checked={propertyType.includes("Residential Freehold")}
              onChange={() =>
                setPropertyType((prev) =>
                  prev.includes("Residential Freehold")
                    ? prev.filter((item) => item !== "Residential Freehold")
                    : [...prev, "Residential Freehold"]
                )
              }
            />
            <label className="form-check-label" htmlFor="freehold">
              Freehold
            </label>
          </div>
        </div>

        {/* Property Category */}
        <div className="mb-5">
          <h4 className="mb-2">Property Category</h4>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="sale"
              checked={propertyCategory.includes("For Sale")}
              onChange={() =>
                setPropertyCategory((prev) =>
                  prev.includes("For Sale")
                    ? prev.filter((item) => item !== "For Sale")
                    : [...prev, "For Sale"]
                )
              }
            />
            <label className="form-check-label" htmlFor="sale">
              Sale
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="rent"
              checked={propertyCategory.includes("For Lease")}
              onChange={() =>
                setPropertyCategory((prev) =>
                  prev.includes("For Lease")
                    ? prev.filter((item) => item !== "For Lease")
                    : [...prev, "For Lease"]
                )
              }
            />
            <label className="form-check-label" htmlFor="lease">
              Lease
            </label>
          </div>
          {/* <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="rent"
              checked={propertyCategory.includes("rent")}
              onChange={() =>
                setPropertyCategory((prev) =>
                  prev.includes("rent")
                    ? prev.filter((item) => item !== "rent")
                    : [...prev, "rent"]
                )
              }
            />
            <label className="form-check-label" htmlFor="rent">
              Rent
            </label>
          </div> */}
        </div>

        {/* Status */}

        <div className="mb-5">
          <h4 className="mb-2">Status</h4>
          <div className="status-options-container">
            {statusOptions.map((option) => (
              <div className="form-check" key={option}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={option}
                  checked={status.includes(option)}
                  onChange={() =>
                    setStatus((prev) =>
                      prev.includes(option)
                        ? prev.filter((item) => item !== option)
                        : [...prev, option]
                    )
                  }
                />
                <label className="form-check-label" htmlFor={option}>
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Price Range */}
      <div className="d-flex justify-content-between">
        <div className="mb-5">
          <h4 className="mb-2">Bedrooms</h4>
          <select
            className="form-select rounded-4"
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
          >
            <option value="">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5+">5+</option>
          </select>
        </div>

        {/* Bathrooms */}
        <div className="mb-5">
          <h4 className="mb-2">Bathrooms</h4>
          <select
            className="form-select rounded-4"
            value={bathrooms}
            onChange={(e) => setBathrooms(e.target.value)}
          >
            <option value="">Any</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5+">5+</option>
          </select>
        </div>
        <div className="mb-5">
          <h4 className="mb-2">Price Range</h4>
          {/* {priceOptions.map((option) => (
            <div className="form-check" key={option.label}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={() => setPriceRange([option.min, option.max])}
              />
              <label className="form-check-label">{option.label}</label>
            </div>
          ))} */}
          <div style={rangeContainerStyle}>
            <Range
              values={priceRange}
              step={1000}
              min={1000}
              max={800000}
              onChange={(values) => setPriceRange(values)}
              renderTrack={({ props, children, values = priceRange }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...trackStyle,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      ...selectedTrackStyle,
                      position: "absolute",
                      left: `${((values[0] - 1000) / (800000 - 1000)) * 100}%`,
                      width: `${
                        ((values[1] - values[0]) / (800000 - 1000)) * 100
                      }%`,
                    }}
                  />
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...thumbStyle,
                  }}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-between text-muted mb-2 mt-2 gap-5">
            <span className="text-black">
              ${priceRange[0].toLocaleString()}
            </span>
            <span className="text-black">
              ${priceRange[1].toLocaleString()}
            </span>
          </div>
        </div>

        {/* Year Built */}
        <div className="mb-5">
          <h4 className="mb-2">Year Built</h4>
          {/* {yearOptions.map((option) => (
            <div className="form-check" key={option.label}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={() => setYearBuiltRange([option.min, option.max])}
              />
              <label className="form-check-label">{option.label}</label>
            </div>
          ))} */}
          <div style={rangeContainerStyle}>
            <Range
              values={yearBuiltRange}
              step={1}
              min={1900}
              max={currentYear}
              onChange={(values) => setYearBuiltRange(values)}
              renderTrack={({ props, children, values = yearBuiltRange }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...trackStyle,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      ...selectedTrackStyle,
                      position: "absolute",
                      left: `${
                        ((values[0] - 1900) / (currentYear - 1900)) * 100
                      }%`,
                      width: `${
                        ((values[1] - values[0]) / (currentYear - 1900)) * 100
                      }%`,
                    }}
                  />
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...thumbStyle,
                  }}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-between text-muted mb-2 mt-2 gap-5">
            <span className="text-black">{yearBuiltRange[0]}</span>
            <span className="text-black">{yearBuiltRange[1]}</span>
          </div>
        </div>

        {/* Area */}
        <div className="mb-5">
          <h4 className="mb-2">Area</h4>
          {/* {areaOptions.map((option) => (
            <div className="form-check" key={option.label}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={() => setAreaRange([option.min, option.max])}
              />
              <label className="form-check-label">{option.label}</label>
            </div>
          ))} */}
          <div style={rangeContainerStyle}>
            <Range
              values={areaRange}
              step={50}
              min={500}
              max={20000}
              onChange={(values) => setAreaRange(values)}
              renderTrack={({ props, children, values = areaRange }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...trackStyle,
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      ...selectedTrackStyle,
                      position: "absolute",
                      left: `${((values[0] - 500) / (20000 - 500)) * 100}%`,
                      width: `${
                        ((values[1] - values[0]) / (20000 - 500)) * 100
                      }%`,
                    }}
                  />
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    ...thumbStyle,
                  }}
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-between text-muted mb-2 mt-2 gap-5">
            <span className="text-black">{areaRange[0]} sq. ft</span>
            <span className="text-black">{areaRange[1]} sq. ft</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MLSTopFilters;
