import React from "react";

const Settings = ({ formData, handleChange, onSidebarClick }) => {
  return (
    <>
      <h2 className="text-primary">Setting</h2>

      {/* <div className="d-flex align-items-center">
        <div className="mb-3 w-100">
          <label>Listing URL</label>
          <div className="d-flex align-items-center">
            <div className="mt-2 w-25  form-control  round-left bg-gray-500">
              https://
            </div>
            <input
              type="text"
              
              className="form-control"
              placeholder="mls-number, address, city, postal-code, area, sub-area, community"
              name="listingUrl"
              style={{ height: "100%", marginBottom: 0 }}
              defaultValue={formData.setting.listingUrl}
              onChange={handleChange}
            />
            <div className="mt-2 w-25  form-control round-right bg-gray-500">.listing-id</div>
          </div>
        </div>
      </div> */}

      <div className="w-100 h-100">
        <div className="mb-3 w-100 h-100 d-flex flex-column justify-content-between">
          <label>Disclamier</label>
          <textarea
            name="disclaimer"
            placeholder="Disclamier"
            className="mt-2 form-control rounded-4"
            defaultValue={formData.setting.disclaimer}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="d-flex flex-column flex-md-row  justify-content-between gap-3">
        <div className="mb-3 w-100">
          <label>Labels</label>
          <select
            className="form-select rounded-5"
            name="labels"
            defaultValue={formData.setting.labels}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select or add labels
            </option>
            <option value="New-design" className="form-select">
              New-design
            </option>
            <option value="New-house" className="form-select">
              New-house
            </option>
            <option value="For Sale, New Listing" className="form-select">
              For Sale, New Listing
            </option>
            <option value="For rent, New Listing" className="form-select">
              For rent, New Listing
            </option>
            <option value="For rent lease, New Listing" className="form-select">
              For rent lease, New Listing
            </option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Settings;
