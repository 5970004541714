import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_LOGOUT,
  EMAIL_VERIFICATION_SUCCESS,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  CLEAR_PROFILE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from "../../config/actionTypes";

const initialState = {
  profile: null,
  token: localStorage.getItem("token") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  error: null,
};

// reducers/authReducer.js
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        refreshToken: null,
        error: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        token: null,
        refreshToken: null,
        error: null,
        profile: null,
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        error: null,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        error: null,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
