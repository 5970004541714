import { getCountryDataList } from "countries-list";
import { useEffect, useState } from "react";

const BasicInfo = ({ formData, currency, handleChange, onSidebarClick }) => {
  const countries = getCountryDataList();
  const [selectedCurrency, setSelectedCurrency] = useState(formData.currency || "");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  // Get today's date in the format 'YYYY-MM-DD'
  const today = new Date().toLocaleDateString("en-CA");

  useEffect(() => {
    if (!formData.listedDate) {
      handleChange({
        target: {
          name: "listedDate",
          value: today,
        },
      });
    }
  }, [formData.listedDate, handleChange, today]);

  const getSingleCurrency = (country) => {
    return Array.isArray(country.currency) ? country.currency[0] : country.currency;
  };

  // Handle country change to update both currency and selected country code
  const handleCountryChange = (countryCode) => {
    const selectedCountry = countries[countryCode];
    if (selectedCountry) {
      const singleCurrency = getSingleCurrency(selectedCountry);
      setSelectedCurrency(singleCurrency);
      setSelectedCountryCode(countryCode);

      // Update formData with the new currency
      handleChange({
        target: {
          name: "currency",
          value: singleCurrency,
        },
      });
    }
  };

  // Set country based on initial formData.currency
  useEffect(() => {
    const countryCode = Object.keys(countries).find(
      (code) => getSingleCurrency(countries[code]) === formData.currency
    );
    if (countryCode) {
      setSelectedCountryCode(countryCode);
    }
  }, [formData.currency]);
  return (
    <>
      <h2 className="text-primary">Basic Info</h2>
      <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
        <div className="mb-3 w-100">
          <label>
            Status*
          </label>
          <select
            className="form-select rounded-5"
            name="status"
            defaultValue={formData.status}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select Status
            </option>
            <option value="active" className="form-select">
              Active
            </option>
            <option value="cancelled" className="form-select">
              Cancelled
            </option>
            <option value="deleted" className="form-select">
              Deleted
            </option>
            <option value="expired" className="form-select">
              Expired
            </option>
            <option value="on hold" className="form-select">
              On Hold
            </option>
            <option value="pending" className="form-select">
              Pending
            </option>
            <option value="sold" className="form-select">
              Sold
            </option>
            <option value="terminated" className="form-select">
              Terminated
            </option>
            <option value="unknown" className="form-select">
              Unknown
            </option>
            <option value="wait approval" className="form-select">
              Wait Approval
            </option>
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>
            Property Type
          </label>
          <select
            className="form-select rounded-5"
            name="propertyType"
            defaultValue={formData.propertyType}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select Property Type
            </option>
            <option value="commercial" className="form-select">
              Commercial
            </option>
            <option value="condo" className="form-select">
              Condo
            </option>
            <option value="freehold" className="form-select">
              Freehold
            </option>
          </select>
        </div>
        <div className="mb-3 w-100">
          <label>
            Property Category
          </label>
          <select
            className="form-select rounded-5"
            name="propertyCategory"
            defaultValue={formData.propertyCategory}
            onChange={handleChange}
          >
            <option value="" className="form-select">
              Select Property Category
            </option>
            <option value="sale" className="form-select">
              Sale
            </option>
            <option value="lease" className="form-select">
              Lease
            </option>
            <option value="rent" className="form-select">
              Rent
            </option>
          </select>
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row  justify-content-between gap-3">
        <div className="w-100 ">
          <div className="mb-3">
            <label>
              Listing Title
            </label>
            <input
              type="text"
              name="title"
              placeholder="Listing Title"
              className="form-control form-control-rounded"
              defaultValue={formData.title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>
              MLS Number
            </label>
            <input
              type="text"
              name="mlsNumber"
              placeholder="MLS Number"
              className="form-control form-control-rounded"
              defaultValue={formData.mlsNumber}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex align-items-center ">
            <div className="mb-3 w-100">
              <label>
                Price
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="number"
                  name="price"
                  placeholder="Price"
                  className="form-control round-left"
                  defaultValue={formData.price}
                  onChange={handleChange}
                />
                {selectedCurrency && (
                  <div className="form-control border-end mt-2 w-25 bg-gray-500">
                    {selectedCurrency}
                  </div>
                )}
                <select
                className="form-select text-secondary"
                name="country"
                value={selectedCountryCode} 
                onChange={(e) => handleCountryChange(e.target.value)}
                style={styles.select}
              >
                <option value="">Select</option>
                {Object.keys(countries).map((code) => (
                  <option key={code} value={code}>
                    {countries[code].name}
                  </option>
                ))}
              </select>
                {/* 
                <ReactFlagsSelect
    selected={selected}
    onSelect={(code) => setSelected(code)}
  />; */}
              </div>
            </div>
            {/* <div className="currency-display">
              <CurrencyFlag currency={countries.currency} size="lg" />
              <span>{countries.currency}</span>
            </div> */}
            {/* <Select
              value={selectedCountry}
              onChange={handleCountryChange}
              options={countries}
              getOptionLabel={(option) => (
                <div className="d-flex align-items-center">
                  <CurrencyFlag currency={option.currency} size="sm" />
                  <span className="ms-2">{option.label}</span>
                </div>
              )}
              getOptionValue={(option) => option.value}
              className="country-select"
            /> */}
          </div>
          <div className="mb-3">
            <label>
              Listed Date
            </label>
            <input
              type="date"
              name="listedDate"
              className="form-control form-control-rounded"
              defaultValue={formData.listedDate || today}
              onChange={handleChange}
              max={today} // Restricts calendar to today and earlier
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>
        </div>
        <div className="w-100 h-100">
          <div className="mb-3 w-100 h-100 d-flex flex-column justify-content-between">
            <label>
              Description
            </label>
            <textarea
              name="description"
              placeholder="Description"
              className="mt-2 form-control rounded-4 flex-grow-1 textarea-input"
              defaultValue={formData.description}
              onChange={handleChange}
              style={{ height: "100%", resize: "none" }} // Set textarea to full height and prevent resizing
            />
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default BasicInfo;
