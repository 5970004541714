import React from "react";

const Other = ({ lead, listings }) => {
  return (
    <div>
      {listings.map((listing) => {
        if (listing.id === lead.listingId) {
          return <>
            <div className="d-flex mb-3 gap-2 text-capitalize">
            <span className="text-black">Listing Title - </span>
              <strong>
                {listing.title && listing.title}
              </strong>
            </div>
            <div className="d-flex mb-3 gap-2 text-capitalize">
            <span className="text-black">Listing Property Type - </span>
              <strong>
                 {listing.propertyType && listing.propertyType}
              </strong>
            </div>
            <div className="d-flex mb-3 gap-2 text-capitalize">
            <span className="text-black">Listing Property Category - </span>
              <strong>
                 {listing.propertyCategory && listing.propertyCategory}
              </strong>
            </div>
            <div className="d-flex mb-3 gap-2">
            <span className="text-black">Listing ID - </span>
              <strong>
                 {listing.id && listing.id}
              </strong>
            </div>
          </>;
        }else return null;

      })}

      {!lead.listingId && (
        <div className="fw-semibold">No Listing Available</div>
      )}
    </div>
  );
};

export default Other;
