import React, { useEffect, useRef, useState } from "react";
import FormContent from "./FormContent";
import FormSidebar from "./FormSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  createListingApi,
  fetchListings,
} from "../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";

const ListingForm = ({ isOpen, closeModal }) => {
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [activeSection, setActiveSection] = useState("basic-info");

  const dispatch = useDispatch();
  // const { loading, listing } = useSelector((state) => state.listing);
  const { listings, currentPage } = useSelector((state) => state.dashboard);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSidebarClick = (section) => {
    const sectionElement = document.getElementById(section);
    if (sectionElement) {
      const marginTop = 0;
      const scrollToY =
        sectionElement.getBoundingClientRect().top + window.scrollY - marginTop;
      window.scrollTo({ top: scrollToY, behavior: "smooth" });
      setActiveSection(section);
    }
  };

  const sectionsRef = useRef([]);
  const observerRef = useRef(null);

  useEffect(() => {
    if (!isModalOpen) return; // Skip setting up the observer if modal is closed
  
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );
  
    // Ensure only valid elements are added to the observer
    const validSections = sectionsRef.current.filter((section) => section instanceof Element);
  
    validSections.forEach((section) => observer.observe(section));
  
    return () => observer.disconnect();
  }, [sectionsRef, isModalOpen]);
  
  useEffect(() => {
    if (!isModalOpen) {
      sectionsRef.current = []; // Reset refs when the modal closes
    }
  }, [isModalOpen]);
  

  console.log("Sections Ref:", sectionsRef.current);
  // console.log("Clicked Section:", section);
console.log("Active Section:", activeSection);

  const [formData, setFormData] = useState({
    status: "",
    propertyType: "",
    propertyCategory: "",
    title: "",
    mlsNumber: "",
    price: "",
    currency: "",
    description: "",
    listedDate: "",
    videos: [],
    images: [],
    buildingInfo: {
      year: "",
      bedroom: "",
      bathroom: "",
      livingArea: "",
      lotArea: "",
      areaType: "SQ FT",
      externalUrl: "",
    },
    address: {
      number: "",
      streetNumber: "",
      streetName: "",
      streetType: "",
      streetDirection: "",
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
    },
    features: [],
    rooms: [],
    bathrooms: [],
    documents: [],
    floorPlans: [],
    externalLinks: {
      name: "",
      link: "",
    },
    openHouses: [],
    seo: {
      title: "",
      description: "",
    },
    section: [],
    setting: {
      listingUrl: "",
      disclaimer: "",
      labels: "",
    },
  });

  console.log(formData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (error) setError(""); // Clear error when typing
  };
  const handleChangeBuildingInfo = (e) => {
    setFormData({
      ...formData,
      buildingInfo: {
        ...formData.buildingInfo,
        [e.target.name]: e.target.value,
      },
    });
    if (error) setError(""); // Clear error when typing
  };
  const handleChangeAddress = (e) => {
    setFormData({
      ...formData,
      address: { ...formData.address, [e.target.name]: e.target.value },
    });
    if (error) setError(""); // Clear error when typing
  };
  const handleChangeSEO = (e) => {
    setFormData({
      ...formData,
      seo: { ...formData.seo, [e.target.name]: e.target.value },
    });
    if (error) setError(""); // Clear error when typing
  };
  const handleChangeSettings = (e) => {
    setFormData({
      ...formData,
      setting: { ...formData.setting, [e.target.name]: e.target.value },
    });
    if (error) setError(""); // Clear error when typing
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentYear = new Date().getFullYear();
    if (formData.status === "") {
      setError("Please select the status.");
      return;
    }

    // if (!formData.propertyType) {
    //   setError("Please select the property type.");
    //   return;
    // }
    // if (!formData.propertyCategory) {
    //   setError("Please select the property category.");
    //   return;
    // }
    // if (!formData.price) {
    //   setError("Please enter the price.");
    //   return;
    // }
    // if (!formData.description) {
    //   setError("Please provide a description.");
    //   return;
    // }
    // if (!formData.listedDate) {
    //   setError("Please specify the listed date.");
    //   return;
    // }
    // if (!formData.buildingInfo.year) {
    //   setError("Please enter the year built.");
    //   return;
    // }
    // if (!formData.buildingInfo.livingArea) {
    //   setError("Please provide the living area.");
    //   return;
    // }
    // if (!formData.buildingInfo.lotArea) {
    //   setError("Please specify the lot area.");
    //   return;
    // }
    if (
      formData.buildingInfo.year &&
      parseInt(formData.buildingInfo.year) > currentYear
    ) {
      setError(`The "Year Built" cannot be greater than ${currentYear}.`);
      return;
    }
    setError("");
    setIsSubmitting(true);
    const response = await dispatch(createListingApi(formData));
    console.log(response.data);
    if (response.data) {
      toast.success("Listing is created", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      // dispatch(fetchListings(currentPage));
      closeModal();
      setFormData({
        status: "",
        propertyType: "",
        propertyCategory: "",
        title: "",
        mlsNumber: "",
        price: "",
        currency: "",
        description: "",
        listedDate: "",
        videos: [],
        images: [],
        buildingInfo: {
          year: "",
          bedroom: "",
          bathroom: "",
          livingArea: "",
          lotArea: "",
          areaType: "SQ FT",
          externalUrl: "",
        },
        address: {
          number: "",
          streetNumber: "",
          streetName: "",
          streetType: "",
          streetDirection: "",
          fullAddress: "",
          city: "",
          country: "",
          state: "",
          postalCode: "",
        },
        features: [],
        rooms: [],
        bathrooms: [],
        documents: [],
        floorPlans: [],
        externalLinks: {
          name: "",
          link: "",
        },
        openHouses: [],
        seo: {
          title: "",
          description: "",
        },
        section: [],
        setting: {
          listingUrl: "",
          disclaimer: "",
          labels: "",
        },
      });
    } else {
      setError(error);
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="">
      {isModalOpen && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog custom-modal-dialog modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close mb-5 me-4 mt-2"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
                <div className="d-flex align-items-center gap-2">
                  <div className="h2 text-black text-xl">Add New Listing</div>
                  <div className="h5 bg-gray-900 rounded-4 p-2">Manual</div>
                </div>
              </div>

              <div className="modal-body-listing text-black">
                <div className="w-100">
                  <div className="d-flex flex-column flex-lg-row justify-content-between gap-2">
                    <div className="d-none d-lg-block listing-sidebar-fixed">
                      <FormSidebar
                        activeSection={activeSection}
                        handleSidebarClick={handleSidebarClick}
                      />
                    </div>
                    <div className="col-12 col-lg-10 ms-3 ms-10 listing-main-content">
                      <FormContent
                        formData={formData}
                        setFormData={setFormData}
                        handleChange={handleChange}
                        handleChangeBuildingInfo={handleChangeBuildingInfo}
                        handleChangeAddress={handleChangeAddress}
                        handleChangeSEO={handleChangeSEO}
                        handleChangeSettings={handleChangeSettings}
                        sectionsRef={sectionsRef}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3 me-4 gap-3">
                {/* {error && <div className="text-danger mt-3 text-center">{error}</div>} */}
                {error && (
                  <div className="text-danger mt-3 text-center">{error}</div>
                )}
                <button
                  type="submit"
                  className="btn btn-primary btn-pill"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingForm;
