import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { updateLeadApi } from "../../modules/dashboard/actions";
import { IconArrowLeft } from "@tabler/icons-react";

const UpdateLead = ({ lead, setIsEditing, closeModel }) => {
  const dispatch = useDispatch();
  //   const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    label: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };

  //   useEffect(() => {
  //     setIsModalOpen(isOpen);
  //     if (isModalOpen) {
  //       document.body.classList.add("modal-open");
  //     } else {
  //       document.body.classList.remove("modal-open");
  //     }
  //   }, [isOpen, isModalOpen]);
  useEffect(() => {
    if (lead) {
      setModalData(lead);
    }
  }, [lead]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !modalData.firstName ||
      !modalData.lastName ||
      !modalData.email ||
      !modalData.phone
    ) {
      setError("Please fill all the fields");
      return;
    }
    setIsSubmitting(true);
    const response = await dispatch(updateLeadApi(modalData.id, modalData));
    console.log(response);
    if (response) {
      toast.success("Leads is updated", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      closeModel();
      setError("");
      setIsEditing(false);
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };
  return (
    <div className="mt-2">
      <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
        <button
          type="button"
          className="btn btn-ghost border-0 btn-sm"
          onClick={() => setIsEditing(false)} // Go back to view mode
        >
          <IconArrowLeft size={16} />
          <span className="ms-1 text-black">Back</span>
        </button>
        <div className="d-flex justify-content-end gap-3">
          <button
            className="btn-next"
            type="submit"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <form>
        {error && <div className="text-danger mt-3 text-center">{error}</div>}
        <div className="mb-3">
          <label>
            <strong>First Name</strong>
          </label>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="form-control form-control-rounded"
            defaultValue={modalData.firstName}
            onChange={handleChange}
          />
          {/* <select
                      className="form-select rounded-5"
                      name="userType"
                      // value={modalData.userType}
                      // onChange={handleChange}
                    >
                      <option value="">Select Agent Type</option>
                      <option value="solo" className="form-select">
                        Solo
                      </option>
                      <option value="brokerage" className="form-select">
                        Brokerage
                      </option>
                    </select> */}
        </div>
        <div className="mb-3">
          <label>
            <strong>Last Name</strong>
          </label>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="form-control form-control-rounded"
            defaultValue={modalData.lastName}
            onChange={handleChange}
          />
        </div>

        <>
          <div className="mb-3">
            <label>
              <strong>Email</strong>
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-control form-control-rounded"
              defaultValue={modalData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>
              <strong>Phone Number</strong>
            </label>
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              className="form-control form-control-rounded"
              defaultValue={modalData.phone}
              onChange={handleChange}
            />
          </div>
          <label>
            <strong>Label</strong>
          </label>
          <input
            type="text"
            name="label"
            placeholder="Label"
            className="form-control form-control-rounded"
            value={modalData.label}
            onChange={handleChange}
          />
          {/* <div className="mb-3">
                      <label>
                        <strong>Business Phone Number</strong>
                      </label>
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={modalData.businessPhone}
                        onChange={(value) => setModalData({ ...modalData, businessPhone: value })}
                      />
                    </div> */}
        </>
      </form>
    </div>
  );
};

export default UpdateLead;
