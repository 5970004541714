import React, { useEffect, useRef, useState } from "react";
import FormContent from "./FormContent";
import FormSidebar from "./FormSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteListingApi,
  updateListingApi,
  fetchListings,
} from "../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";
import {
  IconArrowUpRight,
  IconChevronDown,
  IconChevronUp,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import ListingPreview from "./ListingPreview";

const UpdateListingForm = ({
  isOpen,
  closeModal,
  selectedListing,
  setSelectedListingId,
}) => {
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("basic-info");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMoreClick = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen); // Toggle menu visibility
  };

  const handleSidebarClick = (section) => {
    const sectionElement = document.getElementById(section);
    if (sectionElement) {
      const marginTop = 0;
      const scrollToY =
        sectionElement.getBoundingClientRect().top + window.scrollY - marginTop;
      window.scrollTo({ top: scrollToY, behavior: "smooth" });
      setActiveSection(section);
    }
  };

  const sectionsRef = useRef([]);
  const observerRef = useRef(null);

  useEffect(() => {
    if (!isModalOpen) return; // Skip setting up the observer if modal is closed

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    // Ensure only valid elements are added to the observer
    const validSections = sectionsRef.current.filter(
      (section) => section instanceof Element
    );

    validSections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, [sectionsRef, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      sectionsRef.current = []; // Reset refs when the modal closes
    }
  }, [isModalOpen]);

  const handleDelete = async () => {
    try {
      const response = await dispatch(deleteListingApi(selectedListing.id));
      console.log(response);

      if (response === selectedListing.id) {
        toast.success("Listing is deleted", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }

      closeListingPreviewModal();
      closeModal();
      setIsMoreMenuOpen(false);
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error("Failed to delete listing. Please try again.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      console.error("Error deleting listing:", error);
    }
  };

  // const handleDuplicate = async () => {};

  const dispatch = useDispatch();
  const { listings, currentPage } = useSelector((state) => state.dashboard);
  const profile = useSelector((state) => state.auth.profile);

  const [formData, setFormData] = useState({
    status: "",
    propertyType: "",
    propertyCategory: "",
    title: "",
    mlsNumber: "",
    price: "",
    currency: "",
    description: "",
    listedDate: "",
    videos: [],
    images: [],
    buildingInfo: {
      year: "",
      bedroom: "",
      bathroom: "",
      livingArea: "",
      lotArea: "",
      areaType: "SQ FT",
      externalUrl: "",
    },
    address: {
      number: "",
      streetNumber: "",
      streetName: "",
      streetType: "",
      streetDirection: "",
      fullAddress: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
    },
    features: [],
    rooms: [],
    bathrooms: [],
    documents: [],
    floorPlans: [],
    externalLinks: {
      name: "",
      link: "",
    },
    openHouses: [],
    seo: {
      title: "",
      description: "",
    },
    section: [],
    setting: {
      listingUrl: "",
      disclaimer: "",
      labels: "",
    },
  });

  console.log(formData);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (error) setError("");
  };
  const handleChangeBuildingInfo = (e) => {
    setFormData({
      ...formData,
      buildingInfo: {
        ...formData.buildingInfo,
        [e.target.name]: e.target.value,
      },
    });
    if (error) setError("");
  };
  const handleChangeAddress = (e) => {
    setFormData({
      ...formData,
      address: { ...formData.address, [e.target.name]: e.target.value },
    });
    if (error) setError("");
  };
  const handleChangeSEO = (e) => {
    setFormData({
      ...formData,
      seo: { ...formData.seo, [e.target.name]: e.target.value },
    });
    if (error) setError("");
  };
  const handleChangeSettings = (e) => {
    setFormData({
      ...formData,
      setting: { ...formData.setting, [e.target.name]: e.target.value },
    });
    if (error) setError("");
  };

  const openListingPreviewModal = async (listing) => {
    setIsPreviewModalOpen(true);
    setIsModalOpen(false);
  };
  const openDeleteModal = async (listing) => {
    setIsDeleteModalOpen(true);
    // closeModal();
    setIsModalOpen(false);
  };

  const closeListingPreviewModal = () => {
    setIsPreviewModalOpen(false);
    setIsModalOpen(true);
  };
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIsMoreMenuOpen(false);
    setIsModalOpen(isOpen);
  };

  useEffect(() => {
    if (selectedListing) {
      setFormData(selectedListing);
    }
    if (!isDeleteModalOpen) {
      setIsModalOpen(isOpen);
    }

    if (isModalOpen || isDeleteModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen, isModalOpen, selectedListing, isDeleteModalOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const response = await dispatch(
      updateListingApi(setSelectedListingId, formData)
    );
    // console.log(response);
    if (response) {
      toast.success("Listing is updated", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      dispatch(fetchListings(currentPage, profile.id));
      closeModal();
    } else {
      setError(error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="">
        {isModalOpen && (
          <>
            <div
              className="modal fade show d-block"
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog custom-modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="btn-close mb-5 me-4 mt-2"
                      onClick={closeModal}
                      aria-label="Close"
                    ></button>
                    <div className="d-flex align-items-center gap-2">
                      <div className="h2 text-black text-xl">
                        {selectedListing.title && selectedListing.title}
                      </div>
                      <div className="h5 bg-gray-900 rounded-4 p-2 me-3">
                        Manual
                      </div>
                    </div>
                  </div>
                  {error && (
                    <div className="text-danger mt-3 text-center">{error}</div>
                  )}
                  <div className="modal-body-listing-form text-black">
                    <div className="w-100">
                      <div className="d-flex flex-column flex-lg-row justify-content-between gap-2">
                        <div className="d-none d-lg-block listing-sidebar-fixed">
                          <FormSidebar
                            activeSection={activeSection}
                            handleSidebarClick={handleSidebarClick}
                          />
                        </div>
                        <div className="col-12 col-lg-10 ms-3 ms-10 listing-main-content">
                          <FormContent
                            formData={formData}
                            setFormData={setFormData}
                            handleChange={handleChange}
                            handleChangeBuildingInfo={handleChangeBuildingInfo}
                            handleChangeAddress={handleChangeAddress}
                            handleChangeSEO={handleChangeSEO}
                            handleChangeSettings={handleChangeSettings}
                            sectionsRef={sectionsRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-3 me-4 gap-3">
                    <div className="d-flex gap-2">
                      <div className="position-relative">
                        <button
                          className="btn btn-outline-danger btn-pill"
                          onClick={openDeleteModal}
                        >
                          Delete{" "}
                          {/* {isMoreMenuOpen ? (
                            <IconChevronUp className="" />
                          ) : (
                            <IconChevronDown className="" />
                          )} */}
                        </button>
                        {isMoreMenuOpen && (
                          <div className="dropdown-menu show position-absolute mb-2">
                            <button
                              className="dropdown-item"
                              onClick={openDeleteModal}
                            >
                              <IconTrash /> Delete
                            </button>
                            {/* <button
                            className="dropdown-item"
                            onClick={handleDuplicate}
                          >
                            <IconCopy /> Duplicate
                          </button> */}
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-outline-primary btn-pill"
                        onClick={openListingPreviewModal}
                      >
                        Preview <IconArrowUpRight className="" />
                      </button>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary btn-pill"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <ListingPreview
          isOpen={isPreviewModalOpen}
          closeModal={closeListingPreviewModal}
          selectedListing={setSelectedListingId}
          listings={listings}
          // spage={currentPage}
        />
      </div>
      {isDeleteModalOpen && (
        <div className="custom-modal">
          {/* <div
              className="modal-dialog custom-modal-dialog modal-dialog-centered"
              role="document"
            > */}
          <div className="custom-modal-content" style={{ zIndex: "1060" }}>
            <div className="d-flex justify-content-between">
              <h3>Are you sure you want to delete?</h3>
              <IconX
                onClick={closeDeleteModal}
                color="gray"
                className="cursor-pointer"
              />
            </div>
            <div className="custom-modal-body">
              <p>This action cannot be undone.</p>
              <div className="d-flex justify-content-between">
                <button className="btn btn-danger" onClick={handleDelete}>
                  Delete
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={closeDeleteModal}
                >
                  Cancel
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateListingForm;
