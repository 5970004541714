import {
  IconCheck,
  IconCirclePlus,
  IconFileText,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useRef, useState } from "react";

const Document = ({
  formData,
  setFormData,
}) => {
  const [document, setDocument] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(formData.documents|| []);
  const [temporaryDocument, setTemporaryDocument] = useState({
    name: "",
  });
  const fileInputRef = useRef(null);
  const [editingIndex, setEditingIndex] = useState(-1);
  // const [editedName, setEditedName] = useState("");

  // Function to handle the file input click
  const handleButtonClick = () => {
    fileInputRef.current.click(); // Programmatically click the hidden file input
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const newDocument = files.map((file) => ({
      name: file.name,
      documentId: Math.floor(Math.random() * 1000),
      documentUrl: URL.createObjectURL(file),
      file,
    }));
    //   onAddDocument(newDocument);
    setDocument((prevDocument) => [...prevDocument, ...newDocument]);
    e.target.value = null;
  };
  const handleInputChange = (index, field, value, isSelected) => {
    const targetArray = isSelected ? [...selectedDocument] : [...document]; // Create a copy of the array
    const updatedDocument = { ...targetArray[index], [field]: value }; // Create a new object for the modified document
    const newDocumentArray = [...targetArray.slice(0, index), updatedDocument, ...targetArray.slice(index + 1)]; // Construct a new array with the updated document
  
    if (isSelected) {
      setSelectedDocument(newDocumentArray);
      setFormData((prevData) => ({
        ...prevData,
        documents: newDocumentArray,
      }));
    } else {
      setDocument(newDocumentArray);
      setFormData((prevData) => ({
        ...prevData,
        documents: newDocumentArray,
      }));
    }
  };

  const handleRemoveDocument = (index, isSelected) => {
    const targetArray = isSelected ? selectedDocument : document;
    const newDocument = targetArray.filter((_, i) => i !== index);
    if (isSelected) {
      setSelectedDocument(newDocument);
      setFormData((prevData) => ({
        ...prevData,
        documents: newDocument,
      }));
    } else {
      setDocument(newDocument);
      setFormData((prevData) => ({
        ...prevData,
        documents: newDocument,
      }));
    }
  };

  const handleSelectDocument = (index) => {
    const documentToSelect = document[index];
    setSelectedDocument((prevSelected) => [...prevSelected, documentToSelect]);
    setFormData({
      ...formData,
      documents: [...formData.documents, documentToSelect],
    });
    setDocument([]);
    // setFormData({
    //   ...formData,
    //   features: [...formData.features, feature],
    // });
    // handleRemoveVideoClose(index, false);
  };

  const startEditing = (index) => {
    const documentToSelect = selectedDocument[index];
    setEditingIndex(index);
    setTemporaryDocument({
      name: documentToSelect.name,
    });
  };

  const stopEditing = () => {
    setEditingIndex(-1);
    setTemporaryDocument(null);
  };
  // Function to handle save after editing the document name
  //   const handleSaveClick = () => {
  //     if (editedName.trim()) {
  //       onEditDocument(editingIndex, editedName);
  //       setEditingIndex(null);
  //     } else {
  //       alert("Document name cannot be empty.");
  //     }
  //   };

  // Function to handle cancel editing
  const stopEditingCancel = (index) => {
    setEditingIndex(-1);
    selectedDocument[index].name = temporaryDocument.name;
    setTemporaryDocument(null);
  };

  console.log("selectedDocument", selectedDocument);
  return (
    <>
      <div className="video-uploader">
        <div className="d-flex justify-content-between">
          <div>
            <h2 className="text-primary">Documents</h2>
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-primary btn-pill"
              onClick={handleButtonClick}
              disabled={document.length > 0}
            >
              Add
              <IconCirclePlus className="ms-1" />
            </button>
            <input
              type="file"
              accept="application/pdf"
              multiple
              className="form-control d-none"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="video-player mt-3">
          {document.map((document, index) => (
            <div
              key={index}
              className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
            >
              <div className="d-flex align-items-center gap-2">
                <div>
                  <IconFileText />
                </div>
              </div>
              <div className="flex-grow-1">
                <div className="mb-2">
                  <label className="form-label">
                    Document Name
                  </label>
                  <input
                    type="text"
                    value={document.name}
                    className="form-control form-control-rounded"
                    onChange={(e) =>
                      handleInputChange(index, "name", e.target.value, false)
                    }
                  />
                  <p className="mt-2">PDF</p>
                </div>
              </div>
              <div className="d-flex flex-md-column flex-row justify-content-between">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={() => handleRemoveDocument(index, false)}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconX size={17} />
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                  onClick={() => handleSelectDocument(index)}
                  style={{ width: "40px", height: "40px" }}
                >
                  <IconCheck size={17} />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="video-player mt-3">
          {/* <h2 className="text-primary">Selected Videos</h2> */}
          {selectedDocument.map((selectDocument, index) => (
            <div
              key={index}
              className="d-flex align-items-center flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white"
            >
              <div className="d-flex align-items-center gap-2">
                <div>
                  <IconFileText />
                </div>
              </div>

              {editingIndex === index ? (
                <>
                  <div className="flex-grow-1">
                    <div className="mb-2">
                      <label className="form-label">
                        Document Name
                      </label>
                      <input
                        type="text"
                        //   value={video.name}
                        className="form-control form-control-rounded"
                        value={selectDocument.name}
                        onChange={(e) =>
                          handleInputChange(index, "name", e.target.value, true)
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-md-column flex-row justify-content-between gap-2">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                      onClick={() => stopEditingCancel(index)}
                      style={{ width: "40px", height: "40px" }}
                    >
                      <IconX size={17} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                      onClick={stopEditing}
                      style={{ width: "40px", height: "40px" }}
                    >
                      <IconCheck size={17} />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-grow-1">
                    <div className="mb-2">
                      <label className="form-label">
                        <p className="h3">{selectDocument.name}</p>
                      </label>
                    </div>
                    <div className="mb-2">PDF</div>
                  </div>
                  <div className="d-flex flex-md-column flex-row justify-content-between">
                    <button
                      type="button"
                      className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                      onClick={() => startEditing(index)}
                      style={{ width: "40px", height: "40px" }}
                    >
                      <IconPencilMinus size={17} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                      onClick={() => handleRemoveDocument(index, true)}
                      style={{ width: "40px", height: "40px" }}
                    >
                      <IconTrash size={17} />
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Document;
