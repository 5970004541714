import {
  IconCheck,
  IconClipboard,
  IconMapPin,
  IconMapPinFilled,
  IconPhotoScan,
  IconPrinter,
  IconShare,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import preImage1 from "../../../assets/images/pre1.png";
import preImage2 from "../../../assets/images/pre2.png";
import preImage3 from "../../../assets/images/pre3.png";
import preImage4 from "../../../assets/images/pre4.png";
import preImage5 from "../../../assets/images/pre5.png";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Schedule from "./Schedule";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactDOMServer from "react-dom/server";
import L from "leaflet";

// import Mortgage from "./Mortgage";
const customIcon = new L.DivIcon({
  className: "custom-tabler-icon",
  html: ReactDOMServer.renderToString(
    <IconMapPinFilled size={24} color="#1655FB" />
  ),
  iconSize: [24, 24],
  iconAnchor: [12, 24],
});

const ImagesSection = ({ selectedListing, listings, user }) => {
  const [listingLink, setListingLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [userId, setUserId] = useState(user ||'');
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]); // Default center
  const googleApiKey = "AIzaSyClmOljs5wfE6KmOauX_vC_dBdq06A8rpk";
  const mapRef = useRef(null);

  useEffect(() => {
    // Get the current URL from the browser
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const userIdParam = urlParams.get("userId");
      setUserId(userIdParam);
    }
  }, [user]);

  useEffect(() => {
    const selected = listings.find((listing) => listing.id === selectedListing);
    if (selected) {
      geocodeAddress(selected.address);
    }
  }, [selectedListing, listings]);

  useEffect(() => {
    // Update the map center if the map instance and mapCenter are available
    if (mapRef.current) {
      const map = mapRef.current;
      map.setView(mapCenter, 13); // Set zoom level here if needed
    }
  }, [mapCenter]);

  const geocodeAddress = async (address) => {
    const fullAddress = `${address.number || ""} ${
      address.streetNumber || ""
    } ${address.streetName || ""} ${address.streetType || ""} ${
      address.streetDirection || ""
    }, ${address.city || ""}, ${address.state || ""}, ${
      address.country || ""
    }, ${address.postalCode || ""}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address: fullAddress,
            key: googleApiKey,
          },
        }
      );
      if (response.data.results[0]) {
        const location = response.data.results[0].geometry.location;
        setMapCenter([location.lat, location.lng]);
        console.log(mapCenter)
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          response.data.status
        );
      }
    } catch (error) {
      console.error("Error fetching geocode:", error);
    }
  };

  const generateListingLink = (listingId) => {
    return `${window.location.origin}/listing/${listingId}?userId=${userId}`;
  };

  const handleShareListing = () => {
    const link = generateListingLink(selectedListing);
    setListingLink(link);
    setIsModalOpen(true);
  };

  const handleCopyLinkFallback = () => {
    const textArea = document.createElement("textarea");
    textArea.value = listingLink;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      setCopied(true);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };
  
  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(listingLink).then(
        () => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 3000);
        },
        (err) => {
          console.error("Failed to copy using Clipboard API: ", err);
          // Fallback to the manual method
          handleCopyLinkFallback();
        }
      );
    } else {
      // Fallback for older browsers
      handleCopyLinkFallback();
    }
  };
  const formatListedDate = (dateString) => {
    const listedDate = new Date(dateString);
    const currentDate = new Date();
  
    // Set both dates to midnight to ignore time differences
    listedDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
  
    // Calculate the days difference, ensuring no negative values
    const timeDifference = currentDate - listedDate;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days
  
    // Ensure no negative daysAgo by returning 0 if timeDifference is negative
    const daysAgoFinal = timeDifference < 0 ? 0 : daysAgo;
  
    return daysAgoFinal === 0
      ? "Listed today"
      : daysAgoFinal === 1
      ? "Listed 1 day ago"
      : `Listed ${daysAgoFinal} days ago`;
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {listings.map((listing) => (
        <div key={listing.id}>
          {listing.id === selectedListing && (
            <div className="d-flex flex-column flex-lg-row gap-4">
              <div className="flex-1">
                {/* Main Image */}
                {listing.images && listing.images.length > 0 ? (
                  <img
                    src={listing.images[0]?.imageUrl || preImage1}
                    alt="Main Property Image"
                    className="main-image mb-3"
                  />
                ) : (
                  <img
                    src={preImage1}
                    alt="Fallback Property Image"
                    className="main-image mb-3"
                  />
                )}

                {/* Thumbnails */}
                <div className="image-gallery d-flex flex-wrap gap-3">
                  {listing.images && listing.images.slice(1).map((image, index) => (
                    <img
                      key={index}
                      src={image.imageUrl || preImage1}
                      alt={`Thumbnail ${index + 1}`}
                      className="gallery-image"
                    />
                  ))}
                </div>
              </div>

              <div className="property-info">
                <h1 className="card-title h1">
                  <strong>{listing.title && listing.title}</strong>
                </h1>
                <p className="h3">
                  {listing.propertyType && listing.propertyType}
                </p>
                <p>
                  For {listing.propertyCategory}{" "}
                  <div className="ms-2 border-start p-2">
                  {listing.listedDate && formatListedDate(listing.listedDate)}
                  </div>
                </p>
                <p>
                  <IconMapPin className="me-2" />
                  {listing.address.fullAddress && listing.address.fullAddress}
                </p>
                <h3>
                <h3>
                      {listing.currency && listing.currency}{" "}
                      {listing.price && Number(listing.price).toLocaleString()}{" "}
                      / Per Sq Ft
                    </h3>
                </h3>
                <p>
                  <IconPhotoScan />
                  <Link
                    to={
                      listing.buildingInfo.externalUrl &&
                      listing.buildingInfo.externalUrl
                    }
                    className="ms-2 text-black"
                    target="_blank"
                  >
                    Virtual Tour
                  </Link>
                </p>
                <div className="d-flex align-items-center gap-2 mb-3">
                  <button
                    className="btn btn-black-outline w-100"
                    onClick={handleShareListing}
                  >
                    Share Listing <IconShare size={20} className="ms-1" />
                  </button>
                  <button className="btn btn-black w-100">Send Lsting</button>
                  <IconPrinter size={65} />
                </div>

                <div className="map-view">
                <MapContainer
                  center={mapCenter}
                  ref={mapRef}
                  zoom={13}
                  style={{
                    height: "150px",
                    width: "100%",
                    marginBottom: "3px",
                  }}
                  className="mb-4 rounded-3"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={mapCenter} icon={customIcon}>
                    <Popup>{listing.address.fullAddress}</Popup>
                  </Marker>
                </MapContainer>
                </div>

                {/* schedule */}
                <Schedule listingID={listing.id} userId={userId}/>

                {/* Calculate Mortgage */}
                {/* <Mortgage /> */}
              </div>
            </div>
          )}
        </div>
      ))}
      {isModalOpen && (
        <div className="custom-modal">
          <div>
            <div className="custom-modal-content">
              <div className="d-flex justify-content-between">
                <h3 className="">Share Listing Link</h3>
                <IconX
                  onClick={closeModal}
                  color="gray"
                  className="cursor-pointer"
                />
              </div>
              <div className="custom-modal-body">
                <div className="position-relative mb-3 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    value={listingLink}
                    readOnly
                  />
                  <div
                    className="position-absolute end-0 top-0 mt-2 me-2"
                    onClick={handleCopyLink}
                    style={{ cursor: "pointer", marginTop: "0.5rem" }}
                  >
                    {copied ? <IconCheck color="green" /> : <IconClipboard />}
                  </div>
                  {copied && (
                    <div
                      className="tooltip-text"
                      style={{
                        position: "absolute",
                        top: "-30px",
                        right: "0",
                        backgroundColor: "black",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      Copied!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImagesSection;
