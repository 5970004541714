import React from 'react'
import cover from "../assets/images/login-cover.png";
import { IconHelpCircle } from '@tabler/icons-react';


const AuthLayout = ({ children }) => {
  return (
    <div className="row g-0 flex-fill h-100">
      <div className="col-12 col-lg-6 col-xl-6 d-none d-lg-block">
        <div
          className="position-relative h-100 min-vh-100"
          style={{ backgroundImage: `url(${cover})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        >
          {/* <div className="position-absolute bottom-0 end-0 mb-3 me-3">
            <button type="button" className="btn btn-transparent btn-pill text-primary d-flex align-items-center">
              <i className="me-1"><IconHelpCircle stroke={1} /></i>Need help?
            </button>
          </div> */}
        </div>
      </div>
      <div className="col-12 col-lg-6 col-xl-6 border-top-wide border-primary d-flex flex-column justify-content-center justify-content-center min-vh-100 p-5 p-lg-0">
        {children}
      </div>
    </div>
  )
}

export default AuthLayout