import { IconPointFilled } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

const FormSidebar = ({activeSection,handleSidebarClick}) => {
  // const [activeSection, setActiveSection] = useState("basic-info");
  const [isScrolled, setIsScrolled] = useState(false);

  // const handleSidebarClick = (section) => {
  //   const sectionElement = document.getElementById(section);
  //   if (sectionElement) {
  //     const marginTop = 0;
  //     const scrollToY =
  //       sectionElement.getBoundingClientRect().top + window.scrollY - marginTop;
  //     window.scrollTo({ top: scrollToY, behavior: "smooth" });
  //     setActiveSection(section);
  //   }
  // };
  const links = [
    {
      id: "#basic-info",
      href: "basic-info",
      name: "Basic Info",
    },
    {
      id: "#videos",
      href: "videos",
      name: "Videos",
    },
    {
      id: "#images",
      href: "images",
      name: "Images",
    },
    {
      id: "#building-info",
      href: "building-info",
      name: "Building Info",
    },
    {
      id: "#address",
      href: "address",
      name: "Address",
    },
    {
      id: "#features",
      href: "features",
      name: "Features",
    },
    {
      id: "#bedroom-details",
      href: "bedroom-details",
      name: "Bedroom Details",
    },
    {
      id: "#bathroom-details",
      href: "bathroom-details",
      name: "Bathroom Details",
    },
    {
      id: "#document",
      href: "document",
      name: "Document",
    },
    {
      id: "#floor-plans",
      href: "floor-plans",
      name: "Floor Plans",
    },
    {
      id: "#external-links",
      href: "external-links",
      name: "External Links",
    },
    {
      id: "#open-house",
      href: "open-house",
      name: "Open House",
    },
    {
      id: "#seo",
      href: "seo",
      name: "SEO",
    },
    {
      id: "#section",
      href: "section",
      name: "Section",
    },
    {
      id: "#setting",
      href: "setting",
      name: "Setting",
    },
  ];


  // const determineActiveSection = () => {
  //   for (let i=links.length-1; i>=0; i--) {
  //     const sectionElement = document.getElementById(links[i].href);
  //     if (sectionElement) {
  //       const sectionTop = sectionElement.getBoundingClientRect();
  //       const sectionHeight = sectionElement.clientHeight;
  //       if (sectionTop.top <= 120 && sectionTop.bottom >= 120) {
  //         setActiveSection(links[i].href);
  //         break;
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if(window.scrollY > 300) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }

  //     determineActiveSection();
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <nav className="nav flex-column">
      <ul className="m-0 p-0">
        {links.map((link) => (
          <li className="nav-link timeline-event p-0" key={link.id}>
            <div className="timeline-icon-container">
              <IconPointFilled
                className={`timeline-icon ${
                  activeSection === link.href ? "text-primary" : "custom-text"
                }`}
              />
              <div
                className={`${link.href === "setting" ? "" : "timeline-line"} `}
              ></div>
            </div>
            <a
              className={`nav-link ${
                activeSection === link.href ? "active" : "text-black"
              }`}
              href={link.id}
              onClick={() => {
                handleSidebarClick(link.href);
              }}
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FormSidebar;
