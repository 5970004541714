import {
  IconCheck,
  IconCirclePlus,
  IconGripVertical,
  IconPencilMinus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

const Bathrooms = ({ formData, setFormData }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [bathroomData, setBathroomData] = useState({
    level: "",
    pieces: 0,
    ensuit: true,
    info: "",
  });
  const [editingIndex, setEditingIndex] = useState(null);
  const [pieces, setPieces] = useState(0);

  // Update bathroomData.pieces whenever pieces changes
  useEffect(() => {
    setBathroomData((prevData) => ({ ...prevData, pieces }));
  }, [pieces]);

  const incrementPieces = () => {
    setPieces((prevValue) => prevValue + 1);
  };

  const decrementPieces = () => {
    setPieces((prevValue) => (prevValue > 0 ? prevValue - 1 : 0));
  };

  const toggleFormNew = () => {
    setPieces(0);
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
    setBathroomData({
      level: "",
      pieces: 0,
      ensuit: true,
      info: "",
    });
    setEditingIndex(null);
  };
  const updateBathRoom = (index, updatedBathRoom) => {
    const updatedBathRooms = [...formData.bathrooms];
    updatedBathRooms[index] = updatedBathRoom;
    setFormData({
      ...formData,
      bathrooms: updatedBathRooms,
    });
  };

  const deleteBathRoom = (index) => {
    const updatedBathRooms = formData.bathrooms.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      bathrooms: updatedBathRooms,
    });
  };

  const addBathroom = (bathroom) => {
    setFormData({
      ...formData,
      bathrooms: [...formData.bathrooms, bathroom],
    });
  };

  const handleBathroomInputChange = (e) => {
    const { name, value } = e.target;
    setBathroomData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAddBathRoom = () => {
    if (editingIndex !== null) {
      updateBathRoom(editingIndex, bathroomData);
    } else {
      addBathroom(bathroomData);
    }
    closeForm();
  };

  const handleEditBathroom = (index) => {
    const bathroomToEdit = formData.bathrooms[index];
    setPieces(bathroomToEdit.pieces);
    setBathroomData(bathroomToEdit);
    setEditingIndex(index);
    setIsFormOpen(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="text-primary">Bathrooms</h2>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-primary btn-pill"
            onClick={toggleFormNew}
            disabled={isFormOpen}
          >
            Add
            <IconCirclePlus className="ms-1" />
          </button>
        </div>
      </div>
      <div className="video-player mt-3">
        {isFormOpen && (
          <div className="d-flex flex-column flex-md-row mb-3 border rounded-4 p-2 gap-2 bg-white">
            <div className="flex-grow-1">
              <div className="d-flex flex-column flex-lg-row gap-2">
                <div className="mb-2 w-100 w-lg-25">
                  <label className="form-label">
                    Room Level
                  </label>
                  <select
                    className="form-select rounded-5"
                    name="level"
                    value={bathroomData.level}
                    onChange={handleBathroomInputChange}
                  >
                    <option value="" className="form-select">
                      Select Level
                    </option>
                    <option value="Above" className="form-select">
                      Above
                    </option>
                    <option value="Main F" className="form-select">
                      Main F
                    </option>
                    <option value="Main" className="form-select">
                      Main
                    </option>
                    <option value="Bsmt" className="form-select">
                      Bsmt
                    </option>
                  </select>
                </div>
                <div className="mb-3 w-100">
                  <div style={styles.container}>
                    <label className="mb-2">
                      {" "}
                      Pieces
                    </label>
                    <div style={styles.inputContainer}>
                      <button
                        style={styles.button}
                        onClick={decrementPieces}
                        disabled={pieces === 0}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        value={pieces}
                        name="pieces"
                        onChange={(e) => {
                          const newValue = Math.max(
                            0,
                            parseInt(e.target.value, 10)
                          );
                          setPieces(isNaN(newValue) ? 0 : newValue);
                        }}
                        style={styles.input}
                      />
                      <button style={styles.button} onClick={incrementPieces}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <label className="form-label">
                  Other Info
                </label>
                <textarea
                  type="text"
                  placeholder="Other Info"
                  name="info"
                  value={bathroomData.info}
                  className="form-control rounded-4"
                  onChange={handleBathroomInputChange}
                />
              </div>
            </div>
            <div className="d-flex flex-md-column flex-row justify-content-between">
              <button
                type="button"
                className="btn btn-outline-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={closeForm}
                style={{ width: "40px", height: "40px" }}
              >
                <IconX size={17} />
              </button>
              <button
                type="button"
                className="btn btn-primary btn-circle rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleAddBathRoom}
                style={{ width: "40px", height: "40px" }}
              >
                <IconCheck size={17} />
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        {formData.bathrooms.length > 0
          ? formData.bathrooms.map((room, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center mb-2 p-2 bg-white border rounded-4"
              >
                <div className="d-flex flex-wrap align-items-center gap-2">
                  <div>
                    <IconGripVertical />
                  </div>
                  <div>
                    <p className="h3">{room.level}</p>
                    <div className="d-flex gap-2 align-items-center">
                      <p className="mb-0 border-end pe-2">
                        <strong>pieces: </strong>
                        {room.pieces}
                      </p>
                      <p className="mb-0 border-end pe-2">
                        
                          {room.ensuit ? (
                            <IconCheck className="me-2 mb-1" />
                          ) : (
                            <IconX className="me-2 mb-1" />
                          )}
                          Ensuite{" "}
                        
                      </p>
                      <p className="mb-0">
                      <strong>Other Info: </strong>
                        {room.info}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-row justify-content-between">
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => handleEditBathroom(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconPencilMinus size={17} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-ghost btn-circle border-0 d-flex align-items-center justify-content-center"
                    onClick={() => deleteBathRoom(index)}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <IconTrash size={17} />
                  </button>
                </div>
              </div>
            ))
          : ""}
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "8px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    borderRadius: "20px",
    overflow: "hidden",
    justifyContent: "space-between",
    backgroundColor: "white",
  },
  button: {
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "30px",
    padding: "6px 8px 6px 8px",
    cursor: "pointer",
    fontSize: "16px",
    width: "40px",
    textAlign: "center",
    margin: "3px",
  },
  input: {
    width: "40px",
    textAlign: "center",
    border: "none",
    outline: "none",
    fontSize: "13px",
    margin: "0 3px",
    backgroundColor: "white",
  },
  select: {
    width: "90px",
    border: "none",
    outline: "none",
    padding: "10px",
    borderRadius: "0 20px 20px 0",
    backgroundColor: "#f0f0f0",
    fontWeight: "bold",
  },
};

export default Bathrooms;
