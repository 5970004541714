import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { createAppointmentApi } from "../../../modules/dashboard/actions";
import { Bounce, toast } from "react-toastify";

const Schedule = ({ listingID, userId }) => {
  const dispatch = useDispatch();
  const [isBooked, setIsBooked] = useState(false);
  const [formData, setFormData] = useState({
    appointmentType: "inPerson",
    date: "",
    startTime: "",
    endTime: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    listingId: listingID,
    userId: userId,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      listingId: listingID,
      userId: userId,
    }));
  }, [listingID, userId]); // Runs whenever these props change
  
  console.log(userId)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  console.log(formData)
  console.log(listingID)
  // Get today's date in the format YYYY-MM-DD
  const today = new Date().toISOString().split("T")[0];

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate date and time inputs
    if (new Date(formData.date) < new Date(today)) {
      toast.error("Please select a valid date in the future.");
      return;
    }

    if (formData.startTime >= formData.endTime) {
      setError("End time must be later than start time.");
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await dispatch(createAppointmentApi(formData));

      if (response) {
        toast.success("Appointment successfully scheduled!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setFormData({
          appointmentType: "inPerson",
          date: "",
          startTime: "",
          endTime: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
          listingId: listingID,
          userId: userId,
        });
        setIsBooked(false);
      } else {
        console.error("Failed to schedule appointment.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
    setIsSubmitting(false);
  };

  return (
    <div className="card rounded-3 mb-3">
      <div className="card-body">
        <h4>Schedule a Viewing</h4>
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-column flex-sm-row gap-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="appointmentType"
                value="inPerson"
                checked={formData.appointmentType === "inPerson"}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="inPerson">
                In-Person
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="appointmentType"
                value="virtual"
                checked={formData.appointmentType === "virtual"}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="virtual">
                Virtual
              </label>
            </div>
          </div>

          <div className="form-group mt-3">
            <label>Date</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              min={today} // Prevent past dates
              className="form-control rounded-5"
              onKeyDown={(e) => e.preventDefault()}
            />
          </div>

          <div className="form-group mt-3">
            <label>Time</label>
            <div className="d-flex flex-column flex-sm-row gap-2">
              <input
                type="time"
                name="startTime"
                value={formData.startTime}
                onChange={handleChange}
                className="form-control rounded-5"
              />
              <input
                type="time"
                name="endTime"
                value={formData.endTime}
                onChange={handleChange}
                className="form-control rounded-5"
              />
            </div>
          </div>

          {!isBooked && (
            <button
              className="btn btn-black w-100 mt-3"
              onClick={() => setIsBooked(true)}
            >
              Book
            </button>
          )}

          {isBooked && (
            <>
              <div className="form-group mt-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-control rounded-5"
                  placeholder="First Name"
                />
              </div>
              <div className="form-group mt-3">
                <label>Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-control rounded-5"
                  placeholder="Last Name"
                />
              </div>
              <div className="form-group mt-3">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-control rounded-5"
                  placeholder="Email"
                />
              </div>
              <div className="form-group mt-3">
                <label>Phone Number</label>
                <PhoneInput
                  international
                  defaultCountry="US"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  className="form-control"
                />
              </div>
              <div className="form-group mt-3">
                <label>Subject</label>
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="form-control rounded-5"
                  placeholder="Subject"
                />
              </div>
              <div className="form-group mt-3">
                <label>Message</label>
                <input
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-control rounded-5"
                  placeholder="Message"
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
              <button
                type="submit"
                className="btn btn-black w-100 mt-3"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span
                    className="spinner-border spinner-border-sm me-2 text-black"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Book"
                )}
              </button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default Schedule;
